import React, { Component, Fragment } from 'react';
import { DownloadIcon, PlusIcon, SortAscendingIcon, SortDescendingIcon, StarIcon } from '@heroicons/react/solid'
import { Dialog, Transition, Menu } from '@headlessui/react'
import { UsersIcon, PhoneIcon, ChevronDownIcon, PlusCircleIcon, ChatIcon, TrashIcon, ExternalLinkIcon } from '@heroicons/react/outline'
import { apiRegister } from "../../services/apiRegister";
import { tokenRegister } from '../../services/tokenRegister';
import { userRegister } from '../../services/userRegister';
import { mapGoogleBranding, MyMapWrapper } from "../../components/mapGoogleBranding";

import CreateUser from '../../components/createUser';
import WarningModal from '../../components/warningModal';
import { versionRegister } from '../../services/versionRegister';
import SingleSelection from '../../components/singleSelection';
import moment from 'moment';
import CreateClient from '../../components/createClient';
import CreateLead from '../../components/createLead';
import TextArea from '../../components/textArea';
import Dropdown from '../../components/dropdown';
import { Bar } from 'react-chartjs-2';
import CreateLeadNew from '../../components/createLeadNew';

const MyMapComponentBranding = mapGoogleBranding.map();

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

class ListLeadsd extends Component {

    constructor(props) {
        super(props);
        this.state = {
            account: {},
            loading: true,
            open: false,
            tab: { id: 6, name: "All qualified", value: 'relevant', count: 0 },
            ranking: { id: 1, name: "All ratings", value: 'all', count: 0 },
            follow_up: { id: 1, name: "All follow-ups", value: 'all', count: 0 },
            users: [],
            user: {},
            listing: {},
            client: {},
            agent: {},
            sort: { id: 2, name: "Latest action", value: 'latestAction' },
            order: { id: 1, name: "Descending", value: 'descending' },
            page: 1,
            page_count: 10,
            total: 0,
            campaigns: null
        }
    };

    componentDidMount() {
        this.functions.getAccount();
    }

    functions = {
        getAccount: async () => {
            let account = userRegister.get();
            if (account.access && account.access.id === 1) {
                account.manager = true;
            }
            await this.promisedSetState({
                account: account ? account : {}
            });
            if (this.state.account && this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value === "enabled") {
                await this.promisedSetState({
                    tab: { id: 7, name: "All qualifications", value: 'all', count: this.state.all },
                    sort: { id: 1, name: "Created", value: 'createdAt' }
                });
            }
            this.functions.getLeads();
        },
        getLeads: async () => {
            let queries = window.location.search.replace("?", "").split("&");
            let query = { listing: null, client: null, agent: null, lead: null, campaigns: null };
            for (let i = 0; i < queries.length; i++) {
                if (queries[i].indexOf("lead=") !== -1) {
                    query.lead = queries[i].replace("lead=", "");
                }
                if (queries[i].indexOf("campaigns=") !== -1) {
                    query.campaigns = queries[i].replace("campaigns=", "");
                    await this.promisedSetState({
                        campaigns: queries[i].replace("campaigns=", "")
                    })
                }
                if (queries[i].indexOf("listing=") !== -1) {
                    query.listing = queries[i].replace("listing=", "");
                    await this.promisedSetState({
                        listing: { id: query.listing }
                    })
                }
                if (queries[i].indexOf("client=") !== -1) {
                    query.client = queries[i].replace("client=", "");
                    await this.promisedSetState({
                        client: { id: query.client }
                    })
                }
                if (queries[i].indexOf("agent=") !== -1) {
                    query.agent = queries[i].replace("agent=", "");
                    await this.promisedSetState({
                        agent: { id: query.agent }
                    })
                }
                if (queries[i].indexOf("ranking=unrated") !== -1) {
                    await this.promisedSetState({
                        ranking: { id: 6, name: "Unrated", value: 'unrated' }
                    })
                }
                if (queries[i].indexOf("qualification=relevant") !== -1) {
                    await this.promisedSetState({
                        tab: { id: 6, name: "All qualified", value: 'relevant', count: this.state.relevant }
                    })
                }
                if (queries[i].indexOf("qualification=double_qualified") !== -1) {
                    await this.promisedSetState({
                        tab: { id: 5, name: "Post-qualified ", value: 'double-qualified', count: this.state.relevant }
                    })
                }
            }

            window.history.pushState({}, document.title, window.location.pathname);

            try {
                await this.promisedSetState({ loading_users: true });
                let response = await this.calls.getLeads(query);
                response.data = response.data.map((item) => {
                    if (item.archived && item.archived.id) {
                        item.listing = JSON.parse(JSON.stringify(item.archived));
                        item.listing.archived = true;
                        delete item.archived;
                    }
                    return item;
                });
                await this.promisedSetState({
                    "users": response.data,
                    "total": response.meta[this.state.tab.value] ? response.meta[this.state.tab.value] : 0,

                    //QUALIFICATION
                    "all": response.meta ? response.meta.all : 0,
                    "qualified": response.meta ? response.meta.qualified : 0,
                    "unqualified": response.meta ? response.meta.unqualified : 0,
                    'pre-qualified': response.meta ? response.meta['pre-qualified'] : 0,
                    'double-qualified': response.meta ? response.meta['double-qualified'] : 0,
                    "relevant": response.meta ? response.meta['relevant'] : 0,

                    //RATING
                    "all_ratings": response.meta ? response.meta['all_ratings'] : 0,
                    "signed_deal": response.meta ? response.meta['signed_deal'] : 0,
                    "high": response.meta ? response.meta['high'] : 0,
                    "medium": response.meta ? response.meta['medium'] : 0,
                    "low": response.meta ? response.meta['low'] : 0,
                    "block": response.meta ? response.meta['block'] : 0,
                    "unrated": response.meta ? response.meta['unrated'] : 0,

                    //FOLLOW UP
                    "all_followup": response.meta ? response.meta['all_followup'] : 0,
                    "followed_up": response.meta ? response.meta['followed_up'] : 0,
                    "not_followed_up": response.meta ? response.meta['not_followed_up'] : 0,
                    "pending_follow_up": response.meta ? response.meta['pending_follow_up'] : 0,

                    "loading_users": false,
                    "loading": false
                });
                this.functions.sideScroll();
            } catch (error) {
                this.setState({
                    error: true,
                    message: error.body ? error.body.message : "Something went wrong",
                    loading_users: false,
                    loading: false
                })
            }
        },
        getLead: async (lead) => {
            try {

            } catch (error) { }
        },
        updateLead: async (lead, { type }) => {
            lead.loading = true;
            await this.promisedSetState({
                users: this.state.users
            });
            try {
                if (lead.rating && type === 'rating') {
                    await this.calls.updateLead({ id: lead.id, rating: lead.rating });
                }
                if (lead.followUp && type === 'followUp') {
                    await this.calls.updateLead({ id: lead.id, followUp: lead.followUp });
                }
                if (lead.note && type === 'note') {
                    await this.calls.updateLead({ id: lead.id, note: lead.note });
                }
                let response = await this.calls.getLeads(lead);
                this.state.users = this.state.users.map((item) => {
                    response.data.map((inner_item) => {
                        if (inner_item.id == item.id) {
                            item = inner_item;
                        }
                    });
                    return item;
                });
            } catch (error) { }
            lead.loading = false;
            await this.promisedSetState({
                users: this.state.users
            });
        },
        updateClient: async (client, { type }, lead) => {
            client.loading = true;
            await this.promisedSetState({
                users: this.state.users
            });
            try {

                if (client.rating && type === 'rating') {
                    await this.calls.updateClient({ id: client.id, rating: client.rating, lead: lead });
                }

                if (client.followUp && type === 'followUp') {
                    await this.calls.updateClient({ id: client.id, followUp: client.followUp, lead: lead });
                }

                //await this.calls.updateClient({ id: client.id, rating: client.rating, followUp: client.followUp, lead: lead });
            } catch (error) { }
            client.loading = false;
            await this.promisedSetState({
                users: this.state.users
            });
        },
        getActions: async (lead) => {
            if (!Array.isArray(lead.list_actions)) {
                lead.loading = true;
                await this.promisedSetState({
                    users: this.state.users
                });
                try {
                    let response = await this.calls.getActions((lead.id ? lead.id : lead._id));
                    lead.list_actions = response.data;
                    await this.promisedSetState({
                        users: this.state.users
                    });
                } catch (error) {
                    console.log(error);
                }
                lead.loading = false;
                await this.promisedSetState({
                    users: this.state.users
                });
            }
        },
        getClientLeads: async (lead) => {
            if (!Array.isArray(lead.list_leads)) {
                lead.loading = true;
                await this.promisedSetState({
                    users: this.state.users
                });
                try {
                    let response = await this.calls.getClientLeads((lead.client ? lead.client.id : ""));
                    response.data = response.data.map((item) => {
                        if (!item.listing.id && item.archived.id) {
                            item.listing = JSON.parse(JSON.stringify(item.archived));
                            item.listing.archived = true;
                            delete item.archived;
                        }
                        return item;
                    });
                    lead.list_leads = response.data;
                    await this.promisedSetState({
                        users: this.state.users
                    });
                } catch (error) {
                    console.log(error);
                }
                lead.loading = false;
                await this.promisedSetState({
                    users: this.state.users
                });
            }
        },
        barChartPriceOverTime: (client) => {
            let graph_data = {
                labels: [],
                datasets: [
                    {
                        label: 'Price',
                        data: [],
                        borderColor: 'rgb(69, 63, 241)',
                        backgroundColor: 'rgba(69, 63, 241, 0.7)'
                    }
                ]
            };
            if (Array.isArray(client.list_leads)) {
                client.list_leads.sort(function (a, b) {
                    return new Date(a.createdAt) - new Date(b.createdAt);
                }).map((lead) => {
                    if (lead.listing) {
                        graph_data.labels.push([lead.listing.name, lead.listing.reference, moment(lead.createdAt).format("YYYY-MM-DD hh:mm A")]);
                        graph_data.datasets[0].data.push(lead.listing.price);
                    }
                });
            }
            return graph_data;
        },
        exportToCsv: () => {

            var headers = {
                listing: "Listing",
                created: 'Created',
                name: 'Name',
                phone: 'Phone',
                email: "Email",
                rating: "Rating",
                follow_up: 'Follow-up',
                qualifed: "Got Qualified",
                status: "Status",
                latest_action: "Latest action",
                actions: "Total actions"
            };

            var itemsFormatted = [];

            this.state.users.forEach((item) => {
                let new_item = {
                    listing: (item.listing && item.listing.reference) ? item.listing.reference.replace(/,/g, '') : "-",
                    created: moment(item.createdAt).format("YYYY-MM-DD hh:mm a").replace(/,/g, ''),
                    name: (item.client.name && item.client.name !== "") ? item.client.name.replace(/,/g, '') : "-",
                    phone: (item.client.phone && item.client.phone !== "") ? item.client.phone.replace(/,/g, '').replace(/\s/g, '') : '-',
                    email: (item.client.email && item.client.email !== "") ? item.client.email.replace(/,/g, '').replace(/\s/g, '') : '-',
                    rating: item.client && item.client.rating ? item.client.rating.name : "-",
                    follow_up: item.client && item.client.followUp ? item.client.followUp.name : "-",
                    qualifed: item.qualifiedStatus ? moment(item.qualifiedStatus).format("YYYY-MM-DD hh:mm a") : '-',
                    status: item.type && item.type.name ? item.type.name : "-",
                    latest_action: moment(item.latestAction).format("YYYY-MM-DD hh:mm a").replace(/,/g, ''),
                    actions: item.totalActions
                };

                try {

                    if (this.state.account && this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value === "enabled") {
                        if (item.type && item.type.name && (item.type.name === "Qualified" || item.type.name === "Double-qualified")) {
                            new_item.qualifed = item.latestAction ? moment(item.latestAction).format("YYYY-MM-DD hh:mm a") : '-';
                        }
                    }

                } catch (error) {
                }

                /*
                if (item.postQualifiedStatus) {
                    new_item.status = 'Double-qualified';
                }
                if (item.qualifiedStatus && !item.postQualifiedStatus) {
                    new_item.status = 'Qualified';
                }
                if (!item.qualifiedStatus && !item.postQualifiedStatus) {
                    new_item.status = 'Pre-qualified';
                }
                */
                itemsFormatted.push(new_item);
            });

            var fileTitle = 'leads';

            exportCSVFile(headers, itemsFormatted, fileTitle);

            function exportCSVFile(headers, items, fileTitle) {
                if (headers) {
                    items.unshift(headers);
                }
                // Convert Object to JSON
                var jsonObject = JSON.stringify(items);
                var csv = convertToCSV(jsonObject);
                var exportedFilenmae = fileTitle + '.csv' || 'export.csv';
                var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                if (navigator.msSaveBlob) { // IE 10+
                    navigator.msSaveBlob(blob, exportedFilenmae);
                } else {
                    var link = document.createElement("a");
                    if (link.download !== undefined) { // feature detection
                        // Browsers that support HTML5 download attribute
                        var url = URL.createObjectURL(blob);
                        link.setAttribute("href", url);
                        link.setAttribute("download", exportedFilenmae);
                        link.style.visibility = 'hidden';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                }
            }

            function convertToCSV(objArray) {
                var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
                var str = '';
                for (var i = 0; i < array.length; i++) {
                    var line = '';
                    for (var index in array[i]) {
                        if (line != '') line += ',';
                        line += array[i][index];
                    }
                    str += line + '\r\n';
                }
                return str;
            }
        },
        getWhatsappRedirect: async (lead) => {
            await this.promisedSetState({ loading_whatsapp: true });
            try {
                let response = await this.calls.getWhatsappRedirect(lead.id);
                window.open(response.data.message, "_blank");
            } catch (error) {
                this.promisedSetState({
                    message: error.body ? error.body.message : "",
                    loading_whatsapp: false
                })
            }
        },
        removeLead: async () => {
            let selected_lead = JSON.parse(JSON.stringify(this.state.remove));
            await this.promisedSetState({
                remove: null,
                users: this.state.users.map((item) => {
                    if (item.id == selected_lead.id) {
                        item.loading_remove = true;
                    }
                    return item;
                })
            });
            try {
                await this.calls.removeLead(selected_lead.id);
                await this.promisedSetState({
                    users: this.state.users.filter((item) => { return selected_lead.id != item.id }),
                    remove: null,
                    remove_name: "",
                })
            } catch (error) {
                await this.promisedSetState({
                    error: true,
                    message: error.body ? error.body.message : "Something went wrong",
                    remove: null,
                    remove_name: "",
                    users: this.state.users.map((item) => {
                        if (item.id == selected_lead.id) {
                            item.loading_remove = false;
                        }
                        return item;
                    })
                })
            }
        },
        sideScroll: () => {
            try {
                const slider = document.querySelector('#table_container');
                let isDown = false;
                let startX;
                let scrollLeft;
                slider.addEventListener('mousedown', (e) => {
                    isDown = true;
                    slider.classList.add('active');
                    startX = e.pageX - slider.offsetLeft;
                    scrollLeft = slider.scrollLeft;
                });
                slider.addEventListener('mouseleave', () => {
                    isDown = false;
                    slider.classList.remove('active');
                });
                slider.addEventListener('mouseup', () => {
                    isDown = false;
                    slider.classList.remove('active');
                });
                slider.addEventListener('mousemove', (e) => {
                    if (!isDown) return;
                    e.preventDefault();
                    const x = e.pageX - slider.offsetLeft;
                    const walk = (x - startX) * 1;
                    slider.scrollLeft = scrollLeft - walk;
                });
            } catch (error) { }
        }
    };

    calls = {
        getLeads: (query, lead) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let user = userRegister.get();
            let url = "";
            if (user && user.enterprise && user.enterprise.legacy && user.enterprise.legacy.value == "enabled") {
                if (this.props.proptree) {
                    url = apiRegister.url.proptree + "/getProptreeLeads?page=" + this.state.page + "&limit=" + this.state.page_count + "&orderBy=" + this.state.order.value + "&sortBy=" + this.state.sort.value + "&type=" + this.state.tab.value + "&ranking=" + this.state.ranking.value + (this.state.listing.id ? ("&listing=" + this.state.listing.id) : "") + (this.state.client.id ? ("&client=" + this.state.client.id) : "") + (this.state.agent.id ? ("&agent=" + this.state.agent.id) : "") + (this.state.follow_up.value ? ("&followup=" + this.state.follow_up.value) : "") + (lead ? ("&lead=" + lead) : "") + (query && query.lead ? ("&lead=" + query.lead) : "") + (this.state.campaigns ? ("&campaigns=" + this.state.campaigns) : "");
                } else {
                    url = apiRegister.url.api + "/lead/listLeadsLight?page=" + this.state.page + "&limit=" + this.state.page_count + "&orderBy=" + this.state.order.value + "&sortBy=" + this.state.sort.value + "&type=" + this.state.tab.value + "&ranking=" + this.state.ranking.value + (this.state.listing.id ? ("&listing=" + this.state.listing.id) : "") + (this.state.client.id ? ("&client=" + this.state.client.id) : "") + (this.state.agent.id ? ("&agent=" + this.state.agent.id) : "") + (this.state.follow_up.value ? ("&followup=" + this.state.follow_up.value) : "") + (lead ? ("&lead=" + lead) : "") + (query && query.lead ? ("&lead=" + query.lead) : "") + (this.state.campaigns ? ("&campaigns=" + this.state.campaigns) : "");
                }
            } else {
                url = apiRegister.url.api + "/lead/listLeads?page=" + this.state.page + "&limit=" + this.state.page_count + "&orderBy=" + this.state.order.value + "&sortBy=" + this.state.sort.value + "&type=" + this.state.tab.value + "&ranking=" + this.state.ranking.value + (this.state.listing.id ? ("&listing=" + this.state.listing.id) : "") + (this.state.client.id ? ("&client=" + this.state.client.id) : "") + (this.state.agent.id ? ("&agent=" + this.state.agent.id) : "") + (this.state.follow_up.value ? ("&followup=" + this.state.follow_up.value) : "") + (lead ? ("&lead=" + lead) : "") + (query && query.lead ? ("&lead=" + query.lead) : "");
            }
            return apiRegister.call(options, url);
        },
        getClientLeads: (client) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/lead/listClientLeads?orderBy=descending&sortBy=createdAt" + (client ? ("&client=" + client) : "");
            return apiRegister.call(options, url);
        },
        updateLead: (lead) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', lead);
            let url = apiRegister.url.api + "/lead/updateLead?id=" + lead.id;
            return apiRegister.call(options, url);
        },
        removeLead: (lead) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
            let url = apiRegister.url.api + "/lead/removeLead?id=" + lead;
            return apiRegister.call(options, url);
        },
        updateClient: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/client/updateClient?id=" + data.id;
            return apiRegister.call(options, url);
        },
        getActions: (lead) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/action/listActions?orderBy=createdAt&sortBy=descending&lead=" + lead;
            return apiRegister.call(options, url);
        },
        getWhatsappRedirect: (lead) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/lead/getWhatsAppRedirect?lead=" + lead;
            return apiRegister.call(options, url);
        }
    };

    renders = {
        client: (lead) => {
            if (lead.client) {
                let info = "...";
                if (lead.client.name) {
                    info = lead.client.name;
                } else if (lead.client.phone) {
                    info = lead.client.phone;
                } else if (lead.client.email) {
                    info = lead.client.email;
                }
                return info;
            } else {
                return "...";
            }
        },
        getListingUrl: (listing) => {
            if (listing.enterprise) {
                if (listing.enterprise.adboosterType && listing.enterprise.adboosterType.value === 'boostabostad') {
                    if (listing.enterprise.version && (listing.enterprise.version.id === 1 || listing.enterprise.version.value === "live")) {
                        return "https://lp.boostabostad.se/" + listing.adboosterId;
                    } else {
                        return "https://lp.devboostabostad.se/" + listing.adboosterId;
                    }
                } else {
                    if (listing.enterprise.version && (listing.enterprise.version.id === 1 || listing.enterprise.version.value === "live")) {
                        return "https://lp.propertybooster.io/" + listing.adboosterId;
                    } else {
                        return "https://lp.devpropertybooster.com/" + listing.adboosterId;
                    }
                }
            } else {
                return "-";
            }
        },
        openWhatsApp: (agent) => {
            let message = null;
            if (this.state.account && this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value === "enabled") {
                //LIGHT
                if (this.state.account && this.state.account.enterprise && this.state.account.enterprise.parserType && this.state.account.enterprise.parserType.value.indexOf("project") !== -1) {
                    try {
                        message = "https://api.whatsapp.com/send?phone=" + agent.client.phone + "&text=Hi ! Thanks for your inquiry regarding _" + agent.listing.projectName + ", " + agent.listing.area + ", " + agent.listing.city + ', from ' + agent.listing.currency + " " + agent.listing.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                        if (agent.listing.forRent && agent.listing.rentalPeriod) {
                            message = message + ' ' + (agent.listing.rentalPeriod.charAt(0).toUpperCase() + agent.listing.rentalPeriod.slice(1));
                        }
                        message = message + '_ - how can I assist ?%0a%0a' + 'Ref: ' + agent.listing.externalId;
                    } catch (error) {

                    }
                } else {
                    try {
                        message = "https://api.whatsapp.com/send?phone=" + agent.client.phone + "&text=Hi ! Thanks for your inquiry regarding _" + agent.listing.adboosterIndustry.name + ", " + agent.listing.area + ", " + agent.listing.city + ', ' + agent.listing.currency + " " + agent.listing.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                        if (agent.listing.forRent && agent.listing.rentalPeriod) {
                            message = message + ' ' + (agent.listing.rentalPeriod.charAt(0).toUpperCase() + agent.listing.rentalPeriod.slice(1));
                        }
                        message = message + '_ - how can I assist ?%0a%0a' + 'Ref: ' + agent.listing.externalId;
                    } catch (error) {

                    }
                }

            } else {
                //LEGACY
                try {
                    message = "https://api.whatsapp.com/send?phone=" + agent.client.phone + "&text=Hi ! Thanks for your inquiry regarding _" + agent.listing.adboosterIndustry.name + ", " + agent.listing.area + ", " + agent.listing.city + ', ' + agent.listing.currency + " " + agent.listing.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                    if (agent.listing.forRent && agent.listing.rentalPeriod) {
                        message = message + ' ' + (agent.listing.rentalPeriod.charAt(0).toUpperCase() + agent.listing.rentalPeriod.slice(1));
                    }
                    message = message + '_ - how can I assist ?%0a%0a';

                    //ADD WEBSITE (ONLY FOR LEGACY)
                    let website = this.renders.getListingUrl(agent.listing);
                    website = website + "?remove_media_lock=true";
                    message = message + agent.listing.externalId + ' | ' + website;
                } catch (error) {

                }

            }
            return message;
        },
        clientExtraInformation: (leads, qualifion, lead) => {
            let extraInformation = null;
            try {
                leads.filter((item) => {
                    if (lead) {
                        return item.id == lead.id;
                    } else {
                        return true;
                    }
                }).map((lead) => {
                    if (Array.isArray(lead.actions)) {
                        lead.actions.map((action) => {
                            if (action.type && action.type.value === qualifion && action.extraInformation !== '') {
                                extraInformation = action.extraInformation;
                            }
                        });
                    }
                });
            } catch (error) { }
            return extraInformation;
        },
        clientLatestFailedExtraInformation: (leads, qualifion, lead) => {
            let extraInformation = null;
            try {
                leads.filter((item) => {
                    if (lead) {
                        return item.id == lead.id;
                    } else {
                        return true;
                    }
                }).map((lead) => {
                    if (Array.isArray(lead.actions)) {
                        lead.actions.map((action) => {
                            if (action.type && action.type.value === qualifion && action.extraInformation !== '' && action.otherInformation.indexOf("did not pass double") !== -1) {
                                extraInformation = action.extraInformation;
                            }
                        });
                    }
                });
            } catch (error) { }
            return extraInformation;
        },
        clientLatestPreQualifiedExtraInformation: (leads, qualifion, lead) => {
            let extraInformation = null;
            try {
                leads.filter((item) => {
                    if (lead) {
                        return item.id == lead.id;
                    } else {
                        return true;
                    }
                }).map((lead) => {
                    if (Array.isArray(lead.actions)) {
                        lead.actions.map((action) => {
                            if (action.type && action.type.value === qualifion && action.extraInformation !== '' && (action.extraInformation.indexOf("buyer") !== -1) || action.extraInformation.indexOf("renter") !== -1) {
                                extraInformation = action.extraInformation;
                            }
                        });
                    }
                });
            } catch (error) { }
            return extraInformation;
        },
        failedDoubleQualify: (agent, reason, checkAgent) => {
            let failed = false;
            let result = "";
            try {
                if (Array.isArray(agent.conversations)) {
                    agent.conversations.map((item) => {
                        let listing = null;
                        agent.listings.map((inner_listing) => {
                            if (inner_listing.id == item.listing) {
                                listing = inner_listing;
                            }
                        });
                        if (Array.isArray(item.messages)) {
                            item.messages.filter((message) => {
                                return message.reply
                            }).map((message) => {

                                if (checkAgent) {
                                    if (message.reply_template === "post_qualify_question_agent" && message.text == "Yes") {
                                        failed = true;
                                        result = "agent"
                                    }
                                } else {
                                    if (message.reply_template === "post_qualify_crossroad" && message.text == "No") {
                                        failed = true;
                                    }
                                    if (message.reply_template === "post_qualify_checkpoint" && message.text == "No") {
                                        failed = true;
                                    }
                                    if (message.reply_template === "post_qualify_question_agent" && message.text == "Yes") {
                                        failed = true;
                                    }
                                    if (message.reply_template === "post_qualify_question_inquiry" && message.text == "I wish to list a property") {
                                        failed = true;
                                        result = "wish to list a property"
                                    }
                                    if (message.reply_template === "post_qualify_question_inquiry" && message.text == "Something else (job, etc)") {
                                        failed = true;
                                        result = "looking for something else (job, etc)"
                                    }
                                    if (message.reply_template === "post_qualify_question_budget_below" && message.text == "Yes") {
                                        failed = true;

                                        if (listing && listing.forSale && (+listing.price / 1500000) < 1) {
                                            failed = false;
                                        } else if (listing && listing.forRent && (+listing.price / 75000) < 1) {
                                            failed = false;
                                        } else {
                                            failed = true;
                                        }

                                        if (failed) {
                                            result = "low budget"
                                        }

                                    }
                                }

                            });
                        }
                    });
                }
            } catch (error) { }
            return reason ? result : failed;
        },
        completedWaFlow1: (agent) => {
            let success = agent.flow_one;
            return success;
        },
        completedWaFlow2: (agent) => {
            let success = false;
            try {
                if (Array.isArray(agent.conversations)) {
                    agent.conversations.map((item) => {
                        let listing = null;
                        agent.listings.map((inner_listing) => {
                            if (inner_listing.id == item.listing) {
                                listing = inner_listing;
                            }
                        });
                        if (Array.isArray(item.messages)) {
                            item.messages.filter((message) => {
                                return message.reply
                            }).map((message) => {
                                if (message.reply_template === "post_qualify_fetch_unqualified_agent" && message.text == "Yes") {
                                    success = true;
                                }
                            });
                        }
                    });
                }
            } catch (error) { }
            return success;
        },
        answersLength: (agent) => {
            let answers = 0;
            if (agent.answeredQuestions && agent.answeredQuestions[agent.formId]) {
                delete agent.answeredQuestions[agent.formId].completed;
                delete agent.answeredQuestions[agent.formId].started;
                answers = Object.keys(agent.answeredQuestions[agent.formId]).length;
            }
            return answers;
        },
        answersFBLength: (agent) => {
            let answers = 0;
            if (agent.answeredQuestions && agent.answeredQuestions['facebook']) {
                delete agent.answeredQuestions['facebook'].completed;
                delete agent.answeredQuestions['facebook'].started;
                answers = Object.keys(agent.answeredQuestions['facebook']).length;
            }
            return answers;
        },
        answers: (agent) => {
            let answers = [];
            if (!(agent.tab && agent.tab.value == 'facebook')) {
                if (agent.answeredQuestions && agent.answeredQuestions[agent.formId]) {
                    delete agent.answeredQuestions[agent.formId].completed;
                    delete agent.answeredQuestions[agent.formId].started;
                    for (let key in agent.answeredQuestions[agent.formId]) {
                        answers.push(agent.answeredQuestions[agent.formId][key]);
                    }
                }
            } else {
                if (agent.answeredQuestions && agent.answeredQuestions['facebook']) {
                    delete agent.answeredQuestions['facebook'].completed;
                    delete agent.answeredQuestions['facebook'].started;
                    for (let key in agent.answeredQuestions['facebook']) {
                        let item = agent.answeredQuestions['facebook'][key];
                        if (item.answer) {
                            try {
                                let parsedAnswer = JSON.parse(item.answer);
                                item.answer = Array.isArray(parsedAnswer) ? parsedAnswer.join('') : parsedAnswer;
                            } catch (e) {
                            }
                        }
                        answers.push(item);
                    }
                }
            }
            return answers;
        },
        formActions: (agent) => {
            let answers = [];
            if (Array.isArray(agent.list_actions)) {
                answers = agent.list_actions
                    .filter((item) => {
                        return item.information === "facebook questions" ||
                            item.information === "question answered" ||
                            item.information === "reminder send" ||
                            item.information === "whatsapp verified" ||
                            item.information === "form started" ||
                            item.information === "form completed" ||
                            item.information === "qualified" ||
                            item.information === "double-qualified";
                    })
                    .map((item) => {
                        if (item.information === "question answered") {
                            return { ...item, information: "form answers" };
                        }
                        return item;
                    })
                    .map((item) => {
                        if (item.information === "reminder send") {
                            return { ...item, information: "reminder sent" };
                        }
                        return item;
                    })
                    .map((item) => {
                        if (item.information === "facebook questions") {
                            return { ...item, information: "meta answers" };
                        }
                        return item;
                    })
                    .sort((a, b) => {
                        if (a.information === "meta answers" && b.information !== "meta answers") {
                            return -1;
                        }
                        if (a.information !== "meta answers" && b.information === "meta answers") {
                            return 1;
                        }
                        return new Date(a.createdAt) - new Date(b.createdAt);
                    });
            }
            if (answers.filter((item) => { return (item.information == "double-qualified") || (item.information == "qualified") }).length < 1) {
                answers.push({
                    information: "pending qualification"
                });
            }

            //GET INDEX OF 2FA
            let wa_index = null;
            answers.map((item, index) => {
                if (item.information == "whatsapp verified") {
                    wa_index = { index: index, item: item };
                }
            });

            if (wa_index) {
                if (agent.whatsAppMessageStatus == "read" || agent.whatsAppMessageStatus == "delivered" || agent.whatsAppMessageStatus == "clicked link" || agent.whatsAppMessageStatus == "failed") {
                    let new_answers = [
                        ...answers.slice(0, wa_index.index + 1),
                        {
                            lead: wa_index.item.lead,
                            client: wa_index.item.client,
                            information: "whatsapp response",
                            extraInformation: agent.whatsAppMessageStatus == "failed" ? "failed" : "verified",
                            enterprise: wa_index.item.enterprise,
                            createdAt: agent.whatsAppMessageDate ? agent.whatsAppMessageDate : wa_index.item.createdAt,
                            lastUpdated: agent.whatsAppMessageDate ? agent.whatsAppMessageDate : wa_index.item.lastUpdated,
                        },
                        ...answers.slice(wa_index.index + 1)
                    ];
                    answers = new_answers;
                }
            }

            return answers;
        },
        source: (agent) => {
            let source = "";

            if (agent.channel === "facebook" || agent.channel === 'meta') {
                source += "Channel\tMeta\n";
                let details = "";
                if (agent.facebookCampaignId) {
                    details += "Campaign\t" + agent.facebookCampaignId + "\n";
                }
                if (agent.facebookAdsetId) {
                    details += "Ad set\t" + agent.facebookAdsetId + "\n";
                }
                if (agent.facebookAdId) {
                    details += "Ad\t" + agent.facebookAdId + "\n";
                }
                if (agent.trackingData && agent.trackingData.deviceIp) {
                    details += "IP\t" + agent.trackingData.deviceIp + "\n";
                }
                if (agent.trackingData && agent.trackingData.deviceOS) {
                    details += "OS\t" + agent.trackingData.deviceOS + "\n";
                }
                if (agent.trackingData && agent.trackingData.deviceBrowser) {
                    details += "Browser\t" + agent.trackingData.deviceBrowser + "\n";
                }
                if (agent.trackingData && agent.trackingData.deviceCountry) {
                    details += "Country\t" + agent.trackingData.deviceCountry + "\n";
                }
                if (agent.trackingData && agent.trackingData.deviceCity) {
                    details += "City\t" + agent.trackingData.deviceCity + "\n";
                }
                source += details;
            } else if (agent.channel === "proptree") {
                source += "Channel\tProptree\n";
                let details = "";
                if (agent.proptreeId) {
                    details += "User\t" + agent.proptreeId + "\n";
                }
                source += details;
            } else if (agent.channel === "whatsapp") {
                source += "Channel\tWhatsApp Catalog\n";
            } else {
                source += "Channel\tadBooster\n";
            }

            const trimmedSource = source.trim();
            //console.log('Source content:', trimmedSource, 'Length after split and filter:', trimmedSource.split('\n').filter(line => line.trim() !== '').length);

            return trimmedSource;
        },
        whatsapp: (agent) => {
            let whatsapp = false;
            if (agent.has_whatsapp || agent.whatsapp) {
                whatsapp = true;
            } else if (agent.client && agent.client.whatsapp) {
                whatsapp = true;
            } else if (agent.whatsAppMessageStatus == "delivered" || agent.whatsAppMessageStatus == "read" || agent.whatsAppMessageStatus == "clicked link") {
                whatsapp = true;
            }
            return whatsapp;
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    };

    render() {
        return (
            <div className="grid grid-cols-12 sm:p-0 p-6 pt-0 h-full min-h-full bg-gray-50">

                {/*LOADING*/}
                {
                    this.state.loading &&
                    <div className="col-span-12 relative min-h-screen flex justify-center items-start">
                        <div className="w-full h-full flex justify-center items-center flex-col pb-32">
                            <div style={{ borderTopColor: "transparent" }}
                                class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                            <div className="font-semibold text-sm mt-4">Loading leads ...</div>
                        </div>
                    </div>
                }

                {/*REMOVE MODAL*/}
                <WarningModal
                    open={this.state.remove ? true : false}
                    title={"Delete Lead"}
                    description={"Are you sure you want to delete this lead? This action cannot be undone"}
                    cancelButtonText={"cancel"}
                    submitButtonText={"remove"}
                    disableSubmitButton={this.state.remove && this.state.remove_name != this.state.remove.listing.name}
                    showInput={true}
                    copyInput={this.state.remove ? this.state.remove.listing.name : ""}
                    inputTitle={'Type "Name" to remove'}
                    inputError={(this.state.remove && this.state.remove_name && this.state.remove_name !== "" && this.state.remove_name != this.state.remove.listing.name) ? "Names not matching" : false}
                    onClose={(value) => {
                        this.setState({ remove: null })
                    }}
                    onSubmit={(value) => {
                        if (this.state.remove && this.state.remove_name == this.state.remove.listing.name) {
                            this.functions.removeLead();
                        }
                    }}
                    onInput={(value) => {
                        this.setState({ remove_name: value })
                    }}
                />

                {/*OPTION*/}
                {
                    !this.state.loading &&
                    <div className="col-span-6 md:col-span-2 mb-4 md:mb-6 pr-2">
                        <div className="bg-white border rounded-md">
                            <Dropdown
                                selected={this.state.tab}
                                options={[
                                    { id: 7, name: "All qualifications", value: 'all', count: this.state.all },
                                    { id: 6, name: "All qualified", value: 'relevant', count: this.state.relevant },
                                    { id: 5, name: "Post-qualified ", value: 'double-qualified', count: this.state['double-qualified'] },
                                    { id: 1, name: "Qualified ", value: 'qualified', count: this.state.qualified },
                                    { id: 2, name: "Pre-qualified ", value: 'pre-qualified', count: this.state['pre-qualified'] },
                                    { id: 4, name: "Unqualified", value: 'unqualified', count: this.state.unqualified }
                                ].map((item) => {
                                    if (this.state.account && this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value === "enabled") {
                                        if (item.value == "relevant") {
                                            item.name = "All qualified"
                                        }
                                    }
                                    return item;
                                })}
                                searchInput={false}
                                onChange={async (option) => {
                                    await this.promisedSetState({
                                        tab: option,
                                        page: 1
                                    });
                                    this.functions.getLeads();
                                }}
                            />
                        </div>
                    </div>
                }

                {/*RANKED*/}
                {
                    !this.state.loading &&
                    <div className="col-span-6 md:col-span-2 mb-4 md:mb-6 pl-2 md:pr-2">
                        <div className="bg-white border rounded-md">
                            <Dropdown
                                selected={this.state.ranking}
                                options={[
                                    { id: 1, name: "All ratings", value: 'all', count: this.state.all_ratings },
                                    { id: 7, name: "Signed deal", value: 'signed_deal', count: this.state.signed_deal },
                                    { id: 2, name: "High intent", value: 'high', count: this.state.high },
                                    { id: 3, name: "Medium intent", value: 'medium', count: this.state.medium },
                                    { id: 4, name: "Low intent", value: 'low', count: this.state.low },
                                    { id: 5, name: "Spam", value: 'block', count: this.state.block },
                                    { id: 6, name: "Unrated", value: 'unrated', count: this.state.unrated }
                                ].map((item) => {
                                    if (!(this.state.account && this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value === "enabled")) {
                                        delete item.count;
                                    }
                                    return item;
                                })}
                                searchInput={false}
                                onChange={async (option) => {
                                    await this.promisedSetState({
                                        ranking: option,
                                        page: 1
                                    });
                                    this.functions.getLeads();
                                }}
                            />
                        </div>
                    </div>
                }

                {/*FOLLOW UP*/}
                {
                    !this.state.loading &&
                    <div className="col-span-6 md:col-span-2 mb-4 pr-2 md:pr-0 md:mb-6 md:pl-2">
                        <div className="bg-white border rounded-md">
                            <Dropdown
                                selected={this.state.follow_up}
                                options={[
                                    { id: 1, name: "All follow-ups", value: 'all', count: this.state.all_followup },
                                    { id: 2, name: "Followed up", value: 'followed_up', count: this.state.followed_up },
                                    { id: 3, name: "Not followed up", value: 'not_followed_up', count: this.state.not_followed_up },
                                    { id: 4, name: "Pending follow-up", value: 'pending_follow_up', count: this.state.pending_follow_up }
                                ].map((item) => {
                                    if (!(this.state.account && this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value === "enabled")) {
                                        delete item.count;
                                    }
                                    return item;
                                })}
                                searchInput={false}
                                onChange={async (option) => {
                                    await this.promisedSetState({
                                        follow_up: option,
                                        page: 1
                                    });
                                    this.functions.getLeads();
                                }}
                            />
                        </div>
                    </div>
                }

                {
                    <div className="col-span-0 hidden md:block md:col-span-2"></div>
                }

                {/*SORT*/}
                {
                    !this.state.loading &&
                    <div className={this.state.account.manager ? "col-span-6 md:col-span-2 mb-4 md:mb-6 pl-2 md:pl-0 md:pr-2" : "col-span-6 md:col-span-2 mb-4 md:mb-6 pl-2 md:pl-0 md:pr-2"}>
                        <div className="bg-white border rounded-md">
                            <Dropdown
                                selected={this.state.sort}
                                options={[
                                    { id: 1, name: "Created", value: 'createdAt' },
                                    { id: 2, name: "Latest action", value: 'latestAction' },
                                    { id: 6, name: "Total actions", value: 'totalActions' },
                                    //{ id: 5, name: "Got post-qualified", value: 'postQualifiedStatus' },
                                    { id: 3, name: "Name", value: 'name' },
                                    { id: 4, name: "Phone", value: 'phone' },
                                    { id: 5, name: "Email", value: 'email' },
                                    //{ id: 4, name: "Actions", value: 'totalActions' }
                                ].filter((item) => {
                                    if (this.state.account && this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value === "enabled") {
                                        return item.value !== "latestAction" && item.value !== "totalActions";
                                    } else {
                                        return true;
                                    }
                                })}
                                searchInput={false}
                                onChange={async (option) => {
                                    await this.promisedSetState({
                                        sort: option,
                                        page: 1
                                    });
                                    this.functions.getLeads();
                                }}
                            />
                        </div>
                    </div>
                }

                {/*ORDER*/}
                {
                    !this.state.loading &&
                    <div className={this.state.account.manager ? "col-span-12 md:col-span-2 mb-4 md:mb-6 md:pl-2" : "col-span-12 md:col-span-2 mb-4 md:mb-6 md:pl-2"}>
                        <div className="bg-white border rounded-md">
                            <Dropdown
                                selected={this.state.order}
                                options={[
                                    { id: 1, name: "Descending", value: 'descending' },
                                    { id: 2, name: "Ascending", value: 'ascending' }
                                ]}
                                searchInput={false}
                                onChange={async (option) => {
                                    await this.promisedSetState({
                                        order: option,
                                        page: 1
                                    });
                                    this.functions.getLeads();
                                }}
                            />
                        </div>
                    </div>
                }

                {/*LIST*/}
                {
                    !this.state.loading &&
                    <div className="col-span-12">
                        <div className="">
                            {
                                <section className="bg-white shadow-lg border rounded-lg">
                                    <div className="flex p-4 lg:px-6 border-b border-gray-100">
                                        <div className="font-sm flex-1 flex items-center">
                                            <div>
                                                <div className="font-semibold">
                                                    {this.state.tab.name}
                                                </div>
                                                {
                                                    this.state.campaigns &&
                                                    <div className="text-xs">
                                                        <span className="">{this.state.campaigns}</span>
                                                        <span onClick={async () => {
                                                            await this.promisedSetState({
                                                                campaigns: null,
                                                                page: 1,
                                                                page_count: 10,
                                                                total: 0
                                                            });
                                                            this.functions.getLeads();
                                                        }} className="ml-1 underline cursor-pointer">remove campaigns filter</span>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        {
                                            this.state.account.access && this.state.account.access.id === 1 &&
                                            <div onClick={() => {
                                                this.functions.exportToCsv();
                                            }} className="text-sm  font-medium cursor-pointer items-center h-10  px-4  ml-4 rounded-md bg-purple-100 border-purple-500 flex flex-row text-purple-500 hover:text-purple-700">
                                                <DownloadIcon className="w-5 h-5 mr-2" />
                                                Export
                                            </div>
                                        }
                                    </div>

                                    {
                                        this.state.users.length < 1 &&
                                        <div className="h-64 flex items-center justify-center border-b border-gray-100 relative">
                                            {
                                                !this.state.loading_users &&
                                                <div className="font-medium text-sm">No <span className="text-purple-500">Leads</span> available</div>
                                            }
                                            {
                                                this.state.loading_users &&
                                                <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-white bg-opacity-50">
                                                    <div style={{ borderTopColor: "transparent" }}
                                                        class="w-12 h-12 border-2 border-indigo-500 absolute border-solid rounded-full animate-spin"></div>
                                                </div>
                                            }
                                        </div>
                                    }

                                    {
                                        this.state.users.length > 0 &&
                                        <div className="md:hidden lg:hidden relative">
                                            {
                                                this.state.users.map((agent, index) => {
                                                    return (
                                                        <div className="p-4 border-b">
                                                            <div className="flex flex-row items-center">
                                                                <span className="inline-block h-10 w-10 relative rounded-full overflow-hidden bg-gray-100" style={{ minWidth: "2.5rem" }}>
                                                                    <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                                                    </svg>
                                                                    {
                                                                        agent.client && agent.client.image !== '' &&
                                                                        <div className="bg-cover z-5 top-0 left-0 right-0 bottom-0 absolute bg-center inline-block h-10 w-10 rounded-full overflow-hidden" style={{ backgroundImage: 'url(' + agent.client.image + ')' }}>

                                                                        </div>
                                                                    }
                                                                </span>
                                                                <div className="ml-4 whitespace-no-wrap">
                                                                    <div style={window.innerWidth < 640 ? { maxWidth: "115px" } : {}} className="text-sm text-gray-900 whitespace-no-wrap truncate">{agent.listing ? (this.state.account.enterprise && this.state.account.enterprise.parserType && this.state.account.enterprise.parserType.value.indexOf("project") !== -1 ? agent.listing.projectName : agent.listing.name) : "-"}</div>
                                                                    {
                                                                        !(this.state.account && this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value === "enabled") &&
                                                                        <div className="text-sm text-gray-500 cursor-pointer whitespace-no-wrap hover:text-purple-500 hover:underline">
                                                                            <a href={this.renders.getListingUrl(agent.listing)} target="_blank" >#{agent.listing ? agent.listing.reference : "-"}</a>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        this.state.account && this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value === "enabled" &&
                                                                        <div className="text-sm text-gray-500 whitespace-no-wrap">
                                                                            <span>#{agent.listing ? agent.listing.reference : "-"}</span>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <div className="flex flex-1"></div>
                                                                <div className={(agent.type.value === 'double-qualified' ? "bg-green-100 text-green-500" : "bg-purple-100 text-purple-500") + " px-3 font-medium text-xs h-7 flex justify-center items-center rounded-full"}>
                                                                    {agent.type.value === 'double-qualified' ? "Double qualified" : agent.type.name}
                                                                </div>
                                                            </div>
                                                            <div className="mt-4">
                                                                <div className="text-xs font-medium mb-1">
                                                                    Name
                                                                </div>
                                                                {
                                                                    <div className="text-sm text-gray-900">
                                                                        {agent.client && agent.client.name && agent.client.name !== "" && agent.client.name !== " " ? agent.client.name : agent.client && agent.client.company && agent.client.company !== "" && agent.client.company !== " " ? agent.client.company : '...'}
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div className="mt-4">
                                                                <div className="text-xs font-medium mb-1">
                                                                    Phone
                                                                </div>
                                                                <div className="text-sm text-gray-900">
                                                                    <div className="relative inline-flex">
                                                                        {agent.client.phone ? agent.client.phone : "..."}
                                                                        {
                                                                            this.renders.whatsapp(agent) &&
                                                                            //(agent.has_whatsapp || (agent.client && agent.client.whatsapp)) &&
                                                                            <img style={{ right: "-17px", top: "4px" }} className="absolute w-3" src={require("../../assets/images/whatsapp_icon.svg")} />
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="mt-4">
                                                                <div className="text-xs font-medium mb-1">
                                                                    Email
                                                                </div>
                                                                <div className="text-sm text-gray-900">
                                                                    {agent.client.email ? agent.client.email : "..."}
                                                                </div>
                                                            </div>
                                                            {
                                                                !(this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value == 'enabled') &&
                                                                this.renders.completedWaFlow1(agent) && !this.renders.completedWaFlow2(agent) &&
                                                                <div className="border-yellow-500 border mt-2 sm:col-span-3 text-xs text-yellow-500 font-medium p-4">
                                                                    have some questions
                                                                </div>
                                                            }
                                                            {
                                                                !(this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value == 'enabled') &&
                                                                this.renders.completedWaFlow2(agent) && !this.renders.completedWaFlow1(agent) &&
                                                                <div className="border-yellow-500 border mt-2 sm:col-span-3 text-xs text-yellow-500 font-medium p-4">
                                                                    looking for similar listings and deals
                                                                </div>
                                                            }
                                                            {
                                                                !(this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value == 'enabled') &&
                                                                this.renders.completedWaFlow1(agent) && this.renders.completedWaFlow2(agent) &&
                                                                <div className="border-yellow-500 border mt-2 sm:col-span-3 text-xs text-yellow-500 font-medium p-4">
                                                                    looking for similar listings and deals
                                                                </div>
                                                            }
                                                            {
                                                                !(this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value == 'enabled') &&
                                                                this.renders.failedDoubleQualify(agent) && !agent.postQualifiedStatus &&
                                                                <div className="border-red-500 border mt-2 sm:col-span-3 text-xs text-red-500 font-medium p-4">
                                                                    failed double qualification
                                                                </div>
                                                            }
                                                            {
                                                                //legacy mobile – rating mode enabled (manager and agent can rate lead)
                                                                //TODO: only update ratingDate when updateLead is called (currently both ratingDate and followUpDate dates are updated)
                                                                //TODO: if rating mode enabled update client rating with latest lead rating every time lead rating is updated
                                                                (this.state.account.enterprise && this.state.account.enterprise.ratingModeLegacy && this.state.account.enterprise.ratingModeLegacy.value == 'enabled') &&
                                                                <div className="mt-4">
                                                                    <div className="text-xs font-medium mb-1">
                                                                        <div className="flex flex-row items-center">
                                                                            Rate lead
                                                                        </div>
                                                                    </div>
                                                                    <SingleSelection
                                                                        select={async (item) => {
                                                                            agent.rating = item;
                                                                            await this.promisedSetState({
                                                                                users: this.state.users
                                                                            });
                                                                            this.functions.updateLead(agent, { type: 'rating' });
                                                                        }}
                                                                        selected={agent.rating ? agent.rating : { id: 0, name: 'Select rating', value: null }}
                                                                        options={[
                                                                            { id: 5, name: 'Signed deal', value: 'signed_deal' },
                                                                            { id: 1, name: 'High intent', value: 'high' },
                                                                            { id: 2, name: 'Medium intent', value: 'medium' },
                                                                            { id: 3, name: 'Low intent', value: 'low' },
                                                                            { id: 4, name: 'Spam', value: 'block' }
                                                                        ]}
                                                                    />
                                                                    {
                                                                        !agent.ratingFromApi && (agent.ratingAgent && agent.ratingAgent.id) &&
                                                                        <div className="text-purple-500 mt-2 pl-1 rounded-md font-medium text-xs">
                                                                            By: {agent.ratingAgent.name}
                                                                            {
                                                                                agent.ratingDate &&
                                                                                <div className="text-xxxs" >{agent.ratingDate ? moment(agent.ratingDate).format("YYYY-MM-DD hh:mm a") : ""}</div>
                                                                            }
                                                                        </div>
                                                                    }
                                                                    {
                                                                        agent.ratingFromApi &&
                                                                        <div className="text-purple-500 mt-2 pl-1 rounded-md font-medium text-xs">
                                                                            By: API user
                                                                            {
                                                                                agent.ratingDate &&
                                                                                <div className="text-xxxs" >{agent.ratingDate ? moment(agent.ratingDate).format("YYYY-MM-DD hh:mm a") : ""}</div>
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                            }
                                                            {
                                                                //legacy mobile – rating mode disabled (manager and agent can rate client)
                                                                //TODO: only update ratingDate when updateClient is called (currently both ratingDate and followUpDate dates are updated)
                                                                !(this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value == 'enabled') &&
                                                                !(this.state.account.enterprise && this.state.account.enterprise.ratingModeLegacy && this.state.account.enterprise.ratingModeLegacy.value == 'enabled') &&
                                                                <div className="mt-4">
                                                                    <div className="text-xs font-medium mb-1">
                                                                        <div className="flex flex-row items-center">
                                                                            Rate client
                                                                        </div>
                                                                    </div>
                                                                    <SingleSelection
                                                                        select={async (item) => {
                                                                            agent.client.rating = item;
                                                                            await this.promisedSetState({
                                                                                users: this.state.users
                                                                            });
                                                                            this.functions.updateClient(agent.client, { type: 'rating' }, agent.id);
                                                                        }}
                                                                        selected={agent.client && agent.client.rating ? agent.client.rating : { id: 0, name: 'Select rating', value: null }}
                                                                        options={[
                                                                            { id: 5, name: 'Signed deal', value: 'signed_deal' },
                                                                            { id: 1, name: 'High intent', value: 'high' },
                                                                            { id: 2, name: 'Medium intent', value: 'medium' },
                                                                            { id: 3, name: 'Low intent', value: 'low' },
                                                                            { id: 4, name: 'Spam', value: 'block' }
                                                                        ]}
                                                                    />
                                                                    {
                                                                        agent.client && !agent.client.ratingFromApi && agent.client.ratingAgent && agent.client.ratingAgent.id &&
                                                                        <div className="text-purple-500 mt-2 pl-1 rounded-md font-medium text-xs">
                                                                            By: {agent.client.ratingAgent.name}
                                                                            {
                                                                                agent.client.ratingDate &&
                                                                                <div className="text-xxxs" >{agent.client.ratingDate ? moment(agent.client.ratingDate).format("YYYY-MM-DD hh:mm a") : ""}</div>
                                                                            }
                                                                        </div>
                                                                    }
                                                                    {
                                                                        agent.client && agent.client.ratingFromApi &&
                                                                        <div className="text-purple-500 mt-2 pl-1 rounded-md font-medium text-xs">
                                                                            By: API user
                                                                            {
                                                                                agent.client.ratingDate &&
                                                                                <div className="text-xxxs" >{agent.client.ratingDate ? moment(agent.client.ratingDate).format("YYYY-MM-DD hh:mm a") : ""}</div>
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                            }
                                                            {
                                                                //light mobile (manager and agent can rate lead)
                                                                //TODO: only update ratingDate when updateLead is called (currently both ratingDate and followUpDate dates are updated)
                                                                //TODO: update client rating with latest lead rating every time lead rating is updated
                                                                (this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value == 'enabled') &&
                                                                <div className="mt-4">
                                                                    <div className="text-xs font-medium mb-1">
                                                                        <div className="flex flex-row items-center">
                                                                            Rate lead
                                                                        </div>
                                                                    </div>
                                                                    <SingleSelection
                                                                        select={async (item) => {
                                                                            agent.rating = item;
                                                                            await this.promisedSetState({
                                                                                users: this.state.users
                                                                            });
                                                                            this.functions.updateLead(agent, { type: 'rating' });
                                                                        }}
                                                                        selected={agent.rating ? agent.rating : { id: 0, name: 'Select rating', value: null }}
                                                                        options={[
                                                                            { id: 5, name: 'Signed deal', value: 'signed_deal' },
                                                                            { id: 1, name: 'High intent', value: 'high' },
                                                                            { id: 2, name: 'Medium intent', value: 'medium' },
                                                                            { id: 3, name: 'Low intent', value: 'low' },
                                                                            { id: 4, name: 'Spam', value: 'block' }
                                                                        ]}
                                                                    />
                                                                    {
                                                                        !agent.ratingFromApi && agent.ratingAgent && agent.ratingAgent.id &&
                                                                        <div className="text-purple-500 mt-2 pl-1 rounded-md font-medium text-xs">
                                                                            By: {agent.ratingAgent.name}
                                                                            {
                                                                                agent.ratingDate &&
                                                                                <div className="text-xxxs" >{agent.ratingDate ? moment(agent.ratingDate).format("YYYY-MM-DD hh:mm a") : ""}</div>
                                                                            }
                                                                        </div>
                                                                    }
                                                                    {
                                                                        agent.ratingFromApi &&
                                                                        <div className="text-purple-500 mt-2 pl-1 rounded-md font-medium text-xs">
                                                                            By: API user
                                                                            {
                                                                                agent.ratingDate &&
                                                                                <div className="text-xxxs" >{agent.ratingDate ? moment(agent.ratingDate).format("YYYY-MM-DD hh:mm a") : ""}</div>
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                            }
                                                            {
                                                                //legacy mobile – rating mode enabled (anyone can follow-up lead)
                                                                //TODO: only update ratingDate when updateLead is called (currently both ratingDate and followUpDate dates are updated)
                                                                //TODO: if rating mode enabled update client follow-up with latest lead follow-up every time lead follow-up is updated
                                                                (this.state.account.enterprise && this.state.account.enterprise.ratingModeLegacy && this.state.account.enterprise.ratingModeLegacy.value == 'enabled') &&
                                                                <div className="mt-4">
                                                                    <div className="text-xs font-medium mb-1">
                                                                        <div className="flex flex-row items-center">
                                                                            Follow-up lead
                                                                        </div>
                                                                    </div>
                                                                    <SingleSelection
                                                                        select={async (item) => {
                                                                            agent.followUp = item;
                                                                            await this.promisedSetState({
                                                                                users: this.state.users
                                                                            });
                                                                            this.functions.updateLead(agent, { type: 'followUp' });
                                                                        }}
                                                                        selected={agent.followUp ? agent.followUp : { id: 0, name: 'Select follow-up', value: null }}
                                                                        options={[
                                                                            { id: 5, name: 'Followed up', value: 'followed_up' },
                                                                            { id: 1, name: 'Not followed up', value: 'not_followed_up' },
                                                                            { id: 2, name: 'Pending follow-up', value: 'pending_follow_up' }
                                                                        ]}
                                                                    />
                                                                    {
                                                                        !agent.followUpFromApi && (agent.followUpAgent && agent.followUpAgent.id) &&
                                                                        <div className="text-purple-500 mt-2 pl-1 rounded-md font-medium text-xs">
                                                                            By: {agent.followUpAgent.name}
                                                                            {
                                                                                agent.followUpDate &&
                                                                                <div className="text-xxxs" >{agent.followUpDate ? moment(agent.followUpDate).format("YYYY-MM-DD hh:mm a") : ""}</div>
                                                                            }
                                                                        </div>
                                                                    }
                                                                    {
                                                                        agent.followUpFromApi &&
                                                                        <div className="text-purple-500 mt-2 pl-1 rounded-md font-medium text-xs">
                                                                            By: API user
                                                                            {
                                                                                agent.followUpDate &&
                                                                                <div className="text-xxxs" >{agent.followUpDate ? moment(agent.followUpDate).format("YYYY-MM-DD hh:mm a") : ""}</div>
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                            }
                                                            {
                                                                //legacy mobile – rating mode disabled (anyone can follow-up client)
                                                                //TODO: only update followUpDate when updateClient is called (currently both ratingDate and followUpDate dates are updated)
                                                                !(this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value == 'enabled') &&
                                                                !(this.state.account.enterprise && this.state.account.enterprise.ratingModeLegacy && this.state.account.enterprise.ratingModeLegacy.value == 'enabled') &&
                                                                <div className="mt-4">
                                                                    <div className="text-xs font-medium mb-1">
                                                                        <div className="flex flex-row items-center">
                                                                            Follow-up client
                                                                        </div>
                                                                    </div>
                                                                    <SingleSelection
                                                                        select={async (item) => {
                                                                            agent.client.followUp = item;
                                                                            await this.promisedSetState({
                                                                                users: this.state.users
                                                                            });
                                                                            this.functions.updateClient(agent.client, { type: 'followUp' });
                                                                        }}
                                                                        selected={agent.client.followUp ? agent.client.followUp : { id: 0, name: 'Select follow-up', value: null }}
                                                                        options={[
                                                                            { id: 5, name: 'Followed up', value: 'followed_up' },
                                                                            { id: 1, name: 'Not followed up', value: 'not_followed_up' },
                                                                            { id: 2, name: 'Pending follow-up', value: 'pending_follow_up' }
                                                                        ]}
                                                                    />
                                                                    {
                                                                        agent.client && !agent.client.followUpFromApi && agent.client.followUpAgent && agent.client.followUpAgent.id &&
                                                                        <div className="text-purple-500 mt-2 pl-1 rounded-md font-medium text-xs">
                                                                            By: {agent.client.followUpAgent.name}
                                                                            {
                                                                                agent.client.followUpDate &&
                                                                                <div className="text-xxxs" >{agent.client.followUpDate ? moment(agent.client.followUpDate).format("YYYY-MM-DD hh:mm a") : ""}</div>
                                                                            }
                                                                        </div>
                                                                    }
                                                                    {
                                                                        agent.client && agent.client.followUpFromApi &&
                                                                        <div className="text-purple-500 mt-2 pl-1 rounded-md font-medium text-xs">
                                                                            By: API user
                                                                            {
                                                                                agent.client.followUpDate &&
                                                                                <div className="text-xxxs" >{agent.client.followUpDate ? moment(agent.client.followUpDate).format("YYYY-MM-DD hh:mm a") : ""}</div>
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                            }
                                                            {
                                                                //light mobile (anyone can follow-up lead)
                                                                //TODO: only update followUpDate when updateLead is called (currently both ratingDate and followUpDate dates are updated)
                                                                //TODO: update client follow-up with latest lead follow-up every time lead follow-up is updated
                                                                (this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value == 'enabled') &&
                                                                <div className="mt-4">
                                                                    <div className="text-xs font-medium mb-1">
                                                                        <div className="flex flex-row items-center">
                                                                            Follow-up lead
                                                                        </div>
                                                                    </div>
                                                                    <SingleSelection
                                                                        select={async (item) => {
                                                                            agent.followUp = item;
                                                                            await this.promisedSetState({
                                                                                users: this.state.users
                                                                            });
                                                                            this.functions.updateLead(agent, { type: 'followUp' });
                                                                        }}
                                                                        selected={agent.followUp ? agent.followUp : { id: 0, name: 'Select follow-up', value: null }}
                                                                        options={[
                                                                            { id: 5, name: 'Followed up', value: 'followed_up' },
                                                                            { id: 1, name: 'Not followed up', value: 'not_followed_up' },
                                                                            { id: 2, name: 'Pending follow-up', value: 'pending_follow_up' }
                                                                        ]}
                                                                    />
                                                                    {
                                                                        !agent.followUpFromApi && agent.followUpAgent && agent.followUpAgent.id &&
                                                                        <div className="text-purple-500 mt-2 pl-1 rounded-md font-medium text-xs">
                                                                            By: {agent.followUpAgent.name}
                                                                            {
                                                                                agent.followUpDate &&
                                                                                <div className="text-xxxs" >{agent.followUpDate ? moment(agent.followUpDate).format("YYYY-MM-DD hh:mm a") : ""}</div>
                                                                            }
                                                                        </div>
                                                                    }
                                                                    {
                                                                        agent.followUpFromApi &&
                                                                        <div className="text-purple-500 mt-2 pl-1 rounded-md font-medium text-xs">
                                                                            By: API user
                                                                            {
                                                                                agent.followUpDate &&
                                                                                <div className="text-xxxs" >{agent.followUpDate ? moment(agent.followUpDate).format("YYYY-MM-DD hh:mm a") : ""}</div>
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                            }
                                                            {
                                                                <div className="mt-4">
                                                                    <div className="text-xs font-medium mb-1">
                                                                        Note
                                                                    </div>
                                                                    <TextArea
                                                                        rows={2}
                                                                        onChange={async (value) => {
                                                                            agent.note = value;
                                                                            await this.promisedSetState({
                                                                                users: this.state.users
                                                                            });
                                                                        }}
                                                                        onBlur={() => {
                                                                            this.functions.updateLead(agent, { type: 'note' });
                                                                        }}
                                                                        value={agent.note}
                                                                        type={"text"}
                                                                    />
                                                                </div>
                                                            }
                                                            {
                                                                //failed post-qualification with answers
                                                                !(this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value == 'enabled') &&
                                                                Array.isArray(agent.list_leads) &&
                                                                this.renders.clientExtraInformation(agent.list_leads, 'pre-qualified', agent) &&
                                                                this.renders.failedDoubleQualify(agent) &&
                                                                !agent.postQualifiedStatus &&
                                                                <div className="mt-4">
                                                                    <div className="text-xs font-medium mb-1">
                                                                        Post-qualification results
                                                                    </div>
                                                                    <div className="text-sm text-red-500">
                                                                        {
                                                                            //if only answers exist from pre-qualification client rejected to answer
                                                                            !this.renders.clientLatestFailedExtraInformation(agent.list_leads, 'pre-qualified', agent) &&
                                                                            <span>
                                                                                I prefer not to answer questions over WhatsApp, contact me about my requirements.
                                                                            </span>
                                                                        }
                                                                        {
                                                                            //if answers exist from post-qualification display answers (note this action got pre-qualification status why need to filter out any pre-qualification answers)
                                                                            this.renders.clientLatestFailedExtraInformation(agent.list_leads, 'pre-qualified', agent) &&
                                                                            <span>
                                                                                {
                                                                                    this.renders.clientLatestFailedExtraInformation(agent.list_leads, 'pre-qualified', agent)
                                                                                }
                                                                            </span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            }
                                                            {
                                                                //failed post-qualification without answers
                                                                !(this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value == 'enabled') &&
                                                                Array.isArray(agent.list_leads) &&
                                                                !this.renders.clientExtraInformation(agent.list_leads, 'pre-qualified', agent) &&
                                                                this.renders.failedDoubleQualify(agent) &&
                                                                !agent.postQualifiedStatus &&
                                                                <div className="mt-4">
                                                                    <div className="text-xs font-medium mb-1">
                                                                        Post-qualification results
                                                                    </div>
                                                                    <div className="text-sm text-red-500">
                                                                        <span>I prefer not to answer questions over WhatsApp, contact me about my requirements.</span>
                                                                    </div>
                                                                </div>
                                                            }
                                                            {
                                                                //Passed pre-qualification via questionnaire
                                                                !(this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value == 'enabled') &&
                                                                Array.isArray(agent.list_leads) &&
                                                                this.renders.clientLatestPreQualifiedExtraInformation(agent.list_leads, 'pre-qualified', agent) &&
                                                                <div className="mt-4">
                                                                    <div className="text-xs font-medium mb-1">
                                                                        Pre-qualification results
                                                                    </div>
                                                                    <div className="text-sm text-purple-500">
                                                                        <span>
                                                                            {
                                                                                this.renders.clientExtraInformation(agent.list_leads, 'pre-qualified', agent)
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            }
                                                            {
                                                                //Passed pre-qualification via chatbot
                                                                //TODO: add request retrieved from chatbot
                                                            }
                                                            {
                                                                //passed post-qualification
                                                                !(this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value == 'enabled') &&
                                                                Array.isArray(agent.list_leads) &&
                                                                this.renders.clientExtraInformation(agent.list_leads, 'double-qualified', agent) &&
                                                                !this.renders.failedDoubleQualify(agent) &&
                                                                <div className="mt-4">
                                                                    <div className="text-xs font-medium mb-1">
                                                                        Post-qualification results
                                                                    </div>
                                                                    <div className="text-sm text-green-500">
                                                                        <span>
                                                                            {
                                                                                this.renders.clientExtraInformation(agent.list_leads, 'double-qualified', agent)
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            }
                                                            {
                                                                //passed post-qualification but failed after
                                                                !(this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value == 'enabled') &&
                                                                Array.isArray(agent.list_leads) &&
                                                                this.renders.clientExtraInformation(agent.list_leads, 'double-qualified', agent) &&
                                                                this.renders.failedDoubleQualify(agent) &&
                                                                agent.postQualifiedStatus &&
                                                                <div className="mt-4">
                                                                    <div className="text-xs font-medium mb-1">
                                                                        Post-qualification results
                                                                    </div>
                                                                    <div className="text-sm text-green-500">
                                                                        <span>
                                                                            {
                                                                                this.renders.clientExtraInformation(agent.list_leads, 'double-qualified', agent)
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            }
                                                            {
                                                                this.renders.formActions(agent).length > 0 && agent.open &&
                                                                (this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value == 'enabled') &&
                                                                <div key={agent.email} style={{ transform: "scale(0.90)" }} className={(agent.open ? "min-h-24" : "h-0 overflow-hidden") + " overflow-x-scroll transition-height duration-500 ease-in-out relative mt-6 flex flex-row rounded-md px-10"}>
                                                                    {
                                                                        this.renders.formActions(agent).map((item, index) => {
                                                                            const isFormAnswers = item.information === "form answers";
                                                                            const isFirst = index === 0;
                                                                            const isLast = index === this.renders.formActions(agent).length - 1;

                                                                            let startSequence = null;
                                                                            let endSequence = null;
                                                                            let sequenceLength = 0;

                                                                            // Identify the sequence of "form answers"
                                                                            if (isFormAnswers) {
                                                                                startSequence = index;
                                                                                endSequence = index;

                                                                                // Find the start of the sequence
                                                                                while (startSequence > 0 && this.renders.formActions(agent)[startSequence - 1].information === "form answers") {
                                                                                    startSequence--;
                                                                                }

                                                                                // Find the end of the sequence
                                                                                while (endSequence < this.renders.formActions(agent).length - 1 && this.renders.formActions(agent)[endSequence + 1].information === "form answers") {
                                                                                    endSequence++;
                                                                                }

                                                                                sequenceLength = endSequence - startSequence + 1;
                                                                            }

                                                                            return (
                                                                                <Fragment key={item.id}>
                                                                                    {/* Render line if not the first item and not part of overlapping form answers */}
                                                                                    {
                                                                                        !isFirst && (!isFormAnswers || (isFormAnswers && index === startSequence)) &&
                                                                                        <div className="flex flex-1">
                                                                                            <div className={(isLast && item.information === "pending qualification" ? "bg-gray-300" : "bg-purple-400") + " w-full mt-3.5 h-1 px-10"} />
                                                                                        </div>
                                                                                    }

                                                                                    {/* Render question circle */}
                                                                                    <div className={(isFormAnswers && index > startSequence ? "-ml-4" : "") + " relative"}>
                                                                                        <div className="items-center justify-center flex flex-col">
                                                                                            <div className={(item.information === "pending qualification" ? "bg-gray-300" : "bg-purple-400") + " w-8 h-8 mb-0 items-center justify-center flex rounded-full"}>
                                                                                                <div className="w-5 h-5 bg-white rounded-full" />
                                                                                            </div>

                                                                                            {/* Render caption for single "form answers" or start of sequence */}
                                                                                            {
                                                                                                (!isFormAnswers || (isFormAnswers && sequenceLength === 1)) &&
                                                                                                <div className="absolute mt-0 text-center">
                                                                                                    <div className={(item.information === "pending qualification" ? "text-gray-300 mt-21 pt-3" : "flex justify-center items-center text-gray-700 mt-20") + " text-xs px-10 whitespace-no-wrap font-medium"}>
                                                                                                        {(item.information === "whatsapp verified" || item.information === "whatsapp response") ? (
                                                                                                            <>
                                                                                                                {
                                                                                                                    item.information === "whatsapp response" &&
                                                                                                                    item.extraInformation === "verified" &&
                                                                                                                    <img
                                                                                                                        className="w-3 mr-1"
                                                                                                                        src={require("../../assets/images/whatsapp_icon.svg")}
                                                                                                                        alt="WhatsApp Icon"
                                                                                                                    />
                                                                                                                }
                                                                                                                2FA
                                                                                                                {item.information === "whatsapp verified" && <span className="ml-1">sent</span>}
                                                                                                                {item.information === "whatsapp response" && <span className="ml-1">{item.extraInformation}</span>}
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            item.information && item.information === "form answers" && sequenceLength === 1 ? "form answer" : item.information
                                                                                                        )}
                                                                                                        {item.information === "double-qualified" && item.extraInformation && item.extraInformation !== "" && <span className="ml-1">({item.extraInformation && item.extraInformation === "auto" ? "starred" : item.extraInformation})</span>}
                                                                                                        {item.information === "whatsapp verified" && item.extraInformation && item.extraInformation !== "" && <span className="ml-1">({item.extraInformation && item.extraInformation === "facebook" ? "meta" : item.extraInformation})</span>}
                                                                                                        {item.information === "reminder sent" && item.extraInformation === "failed" && <span className="ml-1">{"failed"}</span>}
                                                                                                    </div>

                                                                                                    {/* Display empty element with opacity for alignment */}
                                                                                                    {item.information === "pending qualification" &&
                                                                                                        <div className="text-xxs text-gray-500 whitespace-no-wrap opacity-0">pending</div>
                                                                                                    }
                                                                                                </div>
                                                                                            }

                                                                                            {/* Render bundled caption for sequences of 2 or more "form answers" */}
                                                                                            {
                                                                                                isFormAnswers && index === endSequence && sequenceLength > 1 &&
                                                                                                <div className={"absolute pr-4 mt-0 text-center"} style={{ paddingRight: ((sequenceLength - 1) * 1) + 'rem' }}>
                                                                                                    <div className={"text-gray-700 text-xs mt-20 px-10 whitespace-no-wrap font-medium"}>
                                                                                                        form answers
                                                                                                    </div>
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                    </div>

                                                                                    {/* Render line if not the last item and not part of overlapping form answers */}
                                                                                    {
                                                                                        !isLast && (!isFormAnswers || (isFormAnswers && index === endSequence)) &&
                                                                                        <div className="flex flex-1">
                                                                                            <div className={(isLast && item.information === "pending qualification" ? "bg-gray-300" : "bg-purple-400") + " w-full mt-3.5 h-1"} />
                                                                                        </div>
                                                                                    }
                                                                                </Fragment>
                                                                            );
                                                                        })
                                                                    }
                                                                </div>
                                                            }
                                                            {
                                                                (this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value == 'enabled') && agent.answeredQuestions && Object.keys(agent.answeredQuestions).length > 0 &&
                                                                <div key={agent.email} className={(agent.open ? "h-64 mb-12" : "h-0 overflow-hidden") + " transition-height duration-500 ease-in-out border-gray-100 relative"}>
                                                                    <nav style={{ marginTop: '31px' }} className="flex px-6 mt-4 mb-4" aria-label="Tabs">
                                                                        {(() => {
                                                                            const hasMetaAnswers = agent.answeredQuestions && 'facebook' in agent.answeredQuestions && Object.keys(agent.answeredQuestions.facebook).length > 0;
                                                                            const hasFormAnswers = agent.answeredQuestions && Object.keys(agent.answeredQuestions).some(key => key !== 'facebook' && Object.keys(agent.answeredQuestions[key]).length > 0);
                                                                            if (hasMetaAnswers && hasFormAnswers) {
                                                                                return [
                                                                                    { name: "Meta answers", value: "facebook" },
                                                                                    { name: "Form answers", value: "form" }
                                                                                ];
                                                                            } else if (hasMetaAnswers) {
                                                                                return [{ name: "Meta answers", value: "facebook" }];
                                                                            } else if (hasFormAnswers) {
                                                                                return [{ name: "Form answers", value: "form" }];
                                                                            } else {
                                                                                return [];
                                                                            }
                                                                        })().map((tab) => (
                                                                            <div
                                                                                key={tab.name}
                                                                                className={classNames(
                                                                                    agent.tab && agent.tab.value == tab.value ? 'bg-indigo-100 text-indigo-700' : 'text-gray-500 hover:text-gray-700',
                                                                                    'rounded-md px-3 py-2 text-sm font-medium cursor-pointer mr-4'
                                                                                )}
                                                                                onClick={() => {
                                                                                    agent.tab = tab;
                                                                                    this.setState({
                                                                                        users: this.state.users
                                                                                    });
                                                                                }}
                                                                                aria-current={tab.current ? 'page' : undefined}
                                                                            >
                                                                                {tab.name}
                                                                            </div>
                                                                        ))}
                                                                    </nav>
                                                                    <div className="h-full w-full p-6 absolute overflow-y-auto">
                                                                        {
                                                                            this.renders.answers(agent).map((item, index, array) => {
                                                                                const isLastItem = index === array.length - 1;
                                                                                return (
                                                                                    <div className={isLastItem ? "mb-4 bg-blue-100 p-4 rounded-md text-blue-500 relative" : "mb-4 bg-blue-100 p-4 rounded-md text-blue-500 relative"} key={item.id}>
                                                                                        {
                                                                                            item.correct_option &&
                                                                                            <StarIcon style={{ right: "15px", bottom: "15px" }} className="w-5 absolute" />
                                                                                        }
                                                                                        <div className="text-xs flex font-medium text-gray-500">
                                                                                            <div>Question</div>
                                                                                            <div className="flex flex-1 justify-end">{moment(item.date).format("YYYY-MM-DD hh:mm a")}</div>
                                                                                        </div>
                                                                                        <div className="text-sm font-medium mb-2">{item.question}</div>
                                                                                        <div className="text-xs font-medium text-gray-500">Answer</div>
                                                                                        <div className="text-sm font-medium">{item.answer}</div>
                                                                                    </div>
                                                                                );
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            }
                                                            {
                                                                !(this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value == 'enabled') &&
                                                                <div key={agent.email} className={(agent.open ? "h-64" : "h-0 overflow-hidden") + " transition-height duration-500 ease-in-out border-gray-100 relative"}>
                                                                    {
                                                                        agent.loading &&
                                                                        <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-white bg-opacity-50">
                                                                            <div style={{ borderTopColor: "transparent" }}
                                                                                class="w-5 h-5 border-2 border-indigo-500 absolute border-solid rounded-full animate-spin"></div>
                                                                        </div>
                                                                    }
                                                                    <div className="relative h-full w-full pt-6">
                                                                        <Bar
                                                                            options={{
                                                                                maintainAspectRatio: false,
                                                                                interaction: {
                                                                                    intersect: false,
                                                                                },
                                                                                legend: {
                                                                                    display: false
                                                                                },
                                                                                scales: {
                                                                                    x: {
                                                                                        stacked: false,
                                                                                    },
                                                                                    y: {
                                                                                        stacked: false,
                                                                                    },
                                                                                    xAxes: [{
                                                                                        ticks: {
                                                                                            fontSize: 8
                                                                                        }
                                                                                    }],
                                                                                    yAxes: [{
                                                                                        ticks: {
                                                                                            fontSize: 8,
                                                                                            beginAtZero: true,
                                                                                            callback: function (value, index, values) {
                                                                                                return nFormatter(parseInt(value));
                                                                                                function nFormatter(num) {
                                                                                                    if (num >= 1000000000) {
                                                                                                        num = (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
                                                                                                    }
                                                                                                    if (num >= 1000000) {
                                                                                                        num = (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
                                                                                                    }
                                                                                                    if (num >= 1000) {
                                                                                                        num = (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
                                                                                                    }
                                                                                                    return "AED" + " " + num;
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }],
                                                                                },
                                                                            }}
                                                                            data={this.functions.barChartPriceOverTime(agent)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            }
                                                            <div className="pt-4 flex flex-row justify-center items-start relative">
                                                                {
                                                                    this.props.proptree &&
                                                                    <div className="absolute z-10 left-0 right-0 top-0 bottom-0 bg-white opacity-50"></div>
                                                                }
                                                                <div
                                                                    onClick={async () => {
                                                                        agent.open = !agent.open;
                                                                        if (agent.open) {
                                                                            if (agent.answeredQuestions && 'facebook' in agent.answeredQuestions) {
                                                                                agent.tab = { name: "Meta answers", value: "facebook" };
                                                                            } else {
                                                                                agent.tab = { name: "Form answers", value: "form" };
                                                                            }
                                                                            this.functions.getActions(agent);
                                                                            this.functions.getClientLeads(agent);
                                                                        }
                                                                        //console.log(agent);
                                                                        this.setState({
                                                                            users: this.state.users
                                                                        });
                                                                    }}
                                                                    className={"cursor-pointer whitespace-no-wrap flex-1 justify-center relative flex items-center py-2 border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                                                >
                                                                    {!agent.open && <span className="">Show more</span>}{agent.open && <span className="">Hide</span>}
                                                                </div>
                                                                {
                                                                    this.renders.openWhatsApp(agent) &&
                                                                    agent.client.phone &&
                                                                    <div className="w-2"></div>
                                                                }
                                                                {
                                                                    this.renders.openWhatsApp(agent) &&
                                                                    agent.client.phone &&
                                                                    <a href={this.renders.openWhatsApp(agent)} target="_blank" className="flex flex-1">
                                                                        < div className={"cursor-pointer items-center justify-center relative flex flex-1 px-4 py-2 border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                                                        >
                                                                            WhatsApp client
                                                                        </div>
                                                                    </a>
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                            {
                                                this.state.loading_users &&
                                                <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-white bg-opacity-50">
                                                    <div style={{ borderTopColor: "transparent" }}
                                                        class="w-12 h-12 border-2 border-indigo-500 absolute border-solid rounded-full animate-spin"></div>
                                                </div>
                                            }
                                        </div>
                                    }

                                    {
                                        this.state.users.length > 0 &&
                                        <div id={"table_container"} className="relative overflow-x-scroll hidden md:block min-h-110">
                                            <table className="min-w-full">
                                                <thead className="">
                                                    <tr>

                                                    </tr>
                                                </thead>
                                                <tbody className="">
                                                    {
                                                        this.state.users.map((agent, index) => {
                                                            return (
                                                                <Fragment>
                                                                    <tr key={agent.email} className="border-b border-gray-100">
                                                                        <td className="text-left pl-6">
                                                                            <div className="flex flex-row justify-left items-center">

                                                                                {
                                                                                    (((this.state.account.enterprise && this.state.account.enterprise.managerUIremoveLead && this.state.account.enterprise.managerUIremoveLead.value == 'enabled')) || (this.state.account.enterprise.dev && (this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value == 'enabled') && agent.formId)) &&
                                                                                    <Menu as="div" className="relative inline-block mr-2 text-left">
                                                                                        <div>
                                                                                            <Menu.Button className="inline-flex relative hover:bg-gray-50 items-center justify-center rounded-md bg-white border w-10 h-10 text-sm font-medium text-gray-900">
                                                                                                {
                                                                                                    !agent.loading_remove &&
                                                                                                    <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                                                                                                }
                                                                                                {
                                                                                                    agent.loading_remove &&
                                                                                                    <div className="top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-red-50 bg-opacity-50">
                                                                                                        <div style={{ borderTopColor: "transparent" }}
                                                                                                            class="w-4 h-4 border-2 border-red-500 absolute border-solid rounded-full animate-spin"></div>
                                                                                                    </div>
                                                                                                }
                                                                                            </Menu.Button>
                                                                                        </div>
                                                                                        <Transition
                                                                                            as={Fragment}
                                                                                            enter="transition ease-out duration-100"
                                                                                            enterFrom="transform opacity-0 scale-95"
                                                                                            enterTo="transform opacity-100 scale-100"
                                                                                            leave="transition ease-in duration-75"
                                                                                            leaveFrom="transform opacity-100 scale-100"
                                                                                            leaveTo="transform opacity-0 scale-95"
                                                                                        >
                                                                                            <Menu.Items className="absolute border left-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-lg">
                                                                                                <div className="py-1">
                                                                                                    {
                                                                                                        [
                                                                                                            { name: "Open form", value: "open_form", icon: ExternalLinkIcon },
                                                                                                            { name: "Remove", value: "remove", icon: TrashIcon }
                                                                                                        ].filter((item) => {
                                                                                                            if (item.value === "remove") {
                                                                                                                return (this.state.account.enterprise && this.state.account.enterprise.managerUIremoveLead && this.state.account.enterprise.managerUIremoveLead.value == 'enabled')
                                                                                                            } else if (item.value === "open_form") {
                                                                                                                return this.state.account.enterprise.dev && (this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value == 'enabled') && agent.formId
                                                                                                            } else {
                                                                                                                return true;
                                                                                                            }
                                                                                                        }).map((item) => {
                                                                                                            return (
                                                                                                                <Menu.Item>
                                                                                                                    {({ active }) => (
                                                                                                                        <div
                                                                                                                            onClick={() => {
                                                                                                                                if (item.value == "open_form") {
                                                                                                                                    //TODO: append utms
                                                                                                                                    if (window.location.hostname === 'localhost') {
                                                                                                                                        window.open("http://localhost:5001/form/" + agent.formId + "/" + agent.id);
                                                                                                                                    } else if (window.location.hostname === 'app.adbooster.se') {
                                                                                                                                        window.open("https://form.adbooster.se/" + agent.formId + "/" + agent.id);
                                                                                                                                    } else if (window.location.hostname === 'app.adbooster.ai') {
                                                                                                                                        window.open("https://form.adbooster.ai/" + agent.formId + "/" + agent.id);
                                                                                                                                    }
                                                                                                                                } else if (item.value == 'remove') {
                                                                                                                                    if (!agent.loading) {
                                                                                                                                        this.setState({
                                                                                                                                            remove: agent
                                                                                                                                        })
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }}
                                                                                                                            className={classNames(
                                                                                                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                                                'flex items-center px-4 py-2 font-medium text-sm cursor-pointer',
                                                                                                                                (item.value == "disable" || item.value == "remove") ? "text-red-500 bg-red-100" : ""
                                                                                                                            )}
                                                                                                                        >
                                                                                                                            <div className="flex flex-1">{item.name}</div>
                                                                                                                            <item.icon className="w-5" />
                                                                                                                        </div>
                                                                                                                    )}
                                                                                                                </Menu.Item>
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                </div>
                                                                                            </Menu.Items>
                                                                                        </Transition>
                                                                                    </Menu>
                                                                                }
                                                                                <div className="relative">
                                                                                    {
                                                                                        this.props.proptree &&
                                                                                        <div className="absolute z-10 left-0 z-10 right-0 top-0 bottom-0 bg-white opacity-50"></div>
                                                                                    }
                                                                                    <div
                                                                                        onClick={async () => {
                                                                                            agent.open = !agent.open;
                                                                                            if (agent.open) {
                                                                                                if (agent.answeredQuestions && 'facebook' in agent.answeredQuestions) {
                                                                                                    agent.tab = { name: "Meta answers", value: "facebook" };
                                                                                                } else {
                                                                                                    agent.tab = { name: "Form answers", value: "form" };
                                                                                                }
                                                                                                this.functions.getActions(agent);
                                                                                                this.functions.getClientLeads(agent);
                                                                                            }
                                                                                            this.setState({
                                                                                                users: this.state.users
                                                                                            });
                                                                                        }} className="inline-flex relative whitespace-no-wrap cursor-pointer hover:bg-gray-50 items-center justify-center rounded-md bg-white border px-4 h-10 text-sm font-medium text-gray-900">
                                                                                        {!agent.open && <span className="mr-1">Show more</span>}{agent.open && <span className="mr-1">Hide</span>}
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    false &&
                                                                                    <div
                                                                                        onClick={async () => {
                                                                                            agent.open = !agent.open;
                                                                                            if (agent.open) {
                                                                                                this.functions.getActions(agent);
                                                                                                this.functions.getClientLeads(agent);
                                                                                            }
                                                                                            this.setState({
                                                                                                users: this.state.users
                                                                                            });
                                                                                        }}
                                                                                        className={"cursor-pointer whitespace-no-wrap mr-2 relative inline-flex items-center px-4 py-2 border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                                                                    >
                                                                                        {!agent.open && <span className="mr-1">Show more</span>}{agent.open && <span className="mr-1">Hide</span>}
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    false &&
                                                                                    this.state.account.enterprise.dev &&
                                                                                    (this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value == 'enabled') &&
                                                                                    agent.formId &&
                                                                                    <div
                                                                                        onClick={async () => {
                                                                                            //TODO: append utms
                                                                                            if (window.location.hostname === 'localhost') {
                                                                                                window.open("http://localhost:5001/form/" + agent.formId + "/" + agent.id);
                                                                                            } else if (window.location.hostname === 'app.adbooster.se') {
                                                                                                window.open("https://form.adbooster.se/" + agent.formId + "/" + agent.id);
                                                                                            } else if (window.location.hostname === 'app.adbooster.ai') {
                                                                                                window.open("https://form.adbooster.ai/" + agent.formId + "/" + agent.id);
                                                                                            }
                                                                                        }}
                                                                                        className={"cursor-pointer whitespace-no-wrap mr-2 relative inline-flex items-center px-4 py-2 border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                                                                    >
                                                                                        <span className="mr-1">Open form</span>
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    false &&
                                                                                    (this.state.account.enterprise && this.state.account.enterprise.managerUIremoveLead && this.state.account.enterprise.managerUIremoveLead.value == 'enabled') &&
                                                                                    <div
                                                                                        onClick={async () => {
                                                                                            if (!agent.loading) {
                                                                                                this.setState({
                                                                                                    remove: agent
                                                                                                })
                                                                                            }
                                                                                        }}
                                                                                        className={"cursor-pointer relative inline-flex items-center px-4 py-2 border-red-300 text-sm font-medium rounded-md text-red-700 bg-white hover:bg-red-50"}
                                                                                    >
                                                                                        <div>Remove</div>
                                                                                        {
                                                                                            agent.loading &&
                                                                                            <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-red-50 bg-opacity-50">
                                                                                                <div style={{ borderTopColor: "transparent" }}
                                                                                                    class="w-4 h-4 border-2 border-red-500 absolute border-solid rounded-full animate-spin"></div>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </td>
                                                                        {
                                                                            this.renders.failedDoubleQualify(agent) && !agent.postQualifiedStatus &&
                                                                            <div style={{ marginTop: '12px' }}></div>
                                                                        }
                                                                        {
                                                                            (this.renders.completedWaFlow1(agent) || this.renders.completedWaFlow2(agent)) &&
                                                                            <div style={{ marginTop: '12px' }}></div>
                                                                        }
                                                                        <td className="flex items-center px-6 py-4">
                                                                            <span className="inline-block h-10 w-10 relative rounded-full overflow-hidden bg-gray-100">
                                                                                <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                                                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                                                                </svg>
                                                                                {
                                                                                    false &&
                                                                                    !(agent.client && agent.client.image !== "") &&
                                                                                    agent.listing && agent.listing.image &&
                                                                                    <div className="bg-cover z-5 top-0 left-0 right-0 bottom-0 absolute bg-center inline-block h-10 w-10 rounded-full overflow-hidden" style={{ backgroundImage: 'url(' + agent.listing.image + ')' }}>

                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    agent.client && agent.client.image !== '' &&
                                                                                    <div className="bg-cover z-5 top-0 left-0 right-0 bottom-0 absolute bg-center inline-block h-10 w-10 rounded-full overflow-hidden" style={{ backgroundImage: 'url(' + agent.client.image + ')' }}>

                                                                                    </div>
                                                                                }
                                                                            </span>
                                                                            <div className="ml-4 whitespace-no-wrap">
                                                                                <div style={window.innerWidth < 640 ? { maxWidth: "115px" } : {}} className="text-sm text-gray-900 whitespace-no-wrap truncate">{agent.listing ? (this.state.account.enterprise && this.state.account.enterprise.parserType && this.state.account.enterprise.parserType.value.indexOf("project") !== -1 ? agent.listing.projectName : agent.listing.name) : "-"}</div>
                                                                                {
                                                                                    !(this.state.account && this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value === "enabled") &&
                                                                                    <div className="text-sm text-gray-500 cursor-pointer whitespace-no-wrap hover:text-purple-500 hover:underline">
                                                                                        <a href={this.renders.getListingUrl(agent.listing)} target="_blank" >#{agent.listing ? agent.listing.reference : "-"}</a>
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    this.state.account && this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value === "enabled" &&
                                                                                    <div className="text-sm text-gray-500 whitespace-no-wrap">
                                                                                        <span>#{agent.listing ? agent.listing.reference : "-"}</span>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </td>
                                                                        {
                                                                            this.state.account && this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value === "enabled" &&
                                                                            <td className="text-center px-6 py-4">
                                                                                <div className={
                                                                                    (agent.channel === "internal" || agent.channel === '' || !agent.channel)
                                                                                        ? "text-sm text-gray-900 truncate whitespace-no-wrap"
                                                                                        : "text-sm text-gray-900 truncate whitespace-no-wrap capitalize"
                                                                                }>
                                                                                    {agent.channel
                                                                                        ? (agent.channel === "facebook" || agent.channel === 'meta'
                                                                                            ? "meta"
                                                                                            : (agent.channel === 'internal' || agent.channel === ''
                                                                                                ? "adBooster"
                                                                                                : agent.channel)
                                                                                        )
                                                                                        : "adBooster"}
                                                                                </div>
                                                                                <div className="justify-center text-sm text-gray-500 relative flex flex-row">Channel
                                                                                    <div style={{ padding: "0.2rem", marginTop: "2px" }} className="flex items-center justify-center rounded-full bg-purple-500 border border-white h-4 w-4 ml-1">
                                                                                        {
                                                                                            (agent.channel === 'facebook' || agent.channel === 'meta') &&
                                                                                            <img className="w-3" src={require("../../assets/images/AB_Social_meta.png")} />
                                                                                        }
                                                                                        {
                                                                                            agent.channel === 'google' &&
                                                                                            <img className="w-3" src={require("../../assets/images/AB_Social_google.png")} />
                                                                                        }
                                                                                        {
                                                                                            agent.channel === 'tiktok' &&
                                                                                            <img className="w-3" src={require("../../assets/images/AB_Social_tiktok.png")} />
                                                                                        }
                                                                                        {
                                                                                            agent.channel === 'snapchat' &&
                                                                                            <img className="w-3" src={require("../../assets/images/AB_Social_snapchat.png")} />
                                                                                        }
                                                                                        {
                                                                                            agent.channel === 'proptree' &&
                                                                                            <img className="w-3" src={require("../../assets/images/AB_Social_proptree.png")} />
                                                                                        }
                                                                                        {
                                                                                            agent.channel === 'internal' &&
                                                                                            <img className="w-3" src={require("../../assets/images/AB_Social_internal.png")} />
                                                                                        }
                                                                                        {
                                                                                            agent.channel === '' &&
                                                                                            <img className="w-3" src={require("../../assets/images/AB_Social_internal.png")} />
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        }
                                                                        <td className="text-center px-6 py-4">
                                                                            {
                                                                                this.renders.failedDoubleQualify(agent, true, true) && !agent.postQualifiedStatus &&
                                                                                <div style={{ marginTop: '12px' }}></div>
                                                                            }
                                                                            {
                                                                                <div className="text-sm text-gray-900 truncate whitespace-no-wrap">{agent.client && agent.client.name && agent.client.name !== "" && agent.client.name !== " " ? agent.client.name : agent.client && agent.client.company && agent.client.company !== "" && agent.client.company !== " " ? agent.client.company : '-'}</div>
                                                                            }
                                                                            <div className="text-sm text-gray-500 whitespace-no-wrap">Name</div>
                                                                            {
                                                                                this.renders.failedDoubleQualify(agent, true, true) && !agent.postQualifiedStatus &&
                                                                                <div className="text-xxs font-semibold text-red-500 whitespace-no-wrap">{this.renders.failedDoubleQualify(agent, true, true)}</div>
                                                                            }
                                                                        </td>
                                                                        <td className="text-center px-6 py-4">
                                                                            <div className="flex flex-col items-center">
                                                                                <div className="text-sm truncate whitespace-no-wrap text-gray-900">{agent.client && agent.client.phone ? agent.client.phone : '-'}</div>
                                                                                <div className="text-sm text-gray-500 relative flex flex-row">Phone
                                                                                    {
                                                                                        this.renders.whatsapp(agent) &&
                                                                                        //agent.has_whatsapp &&
                                                                                        <img style={{ right: "-17px", top: "4px" }} className="absolute w-3" src={require("../../assets/images/whatsapp_icon.svg")} />
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className="text-center px-6 py-4">
                                                                            <div className="text-sm text-gray-900 truncate whitespace-no-wrap">{agent.client && agent.client.email ? agent.client.email : '-'}</div>
                                                                            <div className="text-sm text-gray-500 whitespace-no-wrap">Email</div>
                                                                        </td>
                                                                        {
                                                                            //legacy – rating mode disabled (display client rating)
                                                                            !(this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value == 'enabled') &&
                                                                            !(this.state.account.enterprise && this.state.account.enterprise.ratingModeLegacy && this.state.account.enterprise.ratingModeLegacy.value == 'enabled') &&
                                                                            <td className="text-center px-6 py-4">
                                                                                <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.client && agent.client.rating ? agent.client.rating.name : '-'}</div>
                                                                                <div className="text-sm text-gray-500 whitespace-no-wrap">Rating</div>
                                                                            </td>
                                                                        }
                                                                        {
                                                                            //legacy – rating mode enabled (display lead rating)
                                                                            !(this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value == 'enabled') &&
                                                                            (this.state.account.enterprise && this.state.account.enterprise.ratingModeLegacy && this.state.account.enterprise.ratingModeLegacy.value == 'enabled') &&
                                                                            <td className="text-center px-6 py-4">
                                                                                <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.rating ? agent.rating.name : '-'}</div>
                                                                                <div className="text-sm text-gray-500 whitespace-no-wrap">Rating</div>
                                                                            </td>
                                                                        }
                                                                        {
                                                                            //light (display lead rating)
                                                                            (this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value == 'enabled') &&
                                                                            <td className="text-center px-6 py-4">
                                                                                <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.rating ? agent.rating.name : '-'}</div>
                                                                                <div className="text-sm text-gray-500 whitespace-no-wrap">Rating</div>
                                                                            </td>
                                                                        }
                                                                        {
                                                                            //legacy – rating mode disabled (display client follow-up, only visible to anyone)
                                                                            !(this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value == 'enabled') &&
                                                                            !(this.state.account.enterprise && this.state.account.enterprise.ratingModeLegacy && this.state.account.enterprise.ratingModeLegacy.value == 'enabled') &&
                                                                            <td className="text-center px-6 py-4">
                                                                                {
                                                                                    this.renders.failedDoubleQualify(agent) && this.renders.failedDoubleQualify(agent, true) !== "" && !agent.postQualifiedStatus &&
                                                                                    <div style={{ marginTop: '12px' }}></div>
                                                                                }
                                                                                <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.client && agent.client.followUp ? agent.client.followUp.name : '-'}</div>
                                                                                <div className="text-sm text-gray-500 whitespace-no-wrap">Follow-up</div>
                                                                                {
                                                                                    this.renders.failedDoubleQualify(agent) && this.renders.failedDoubleQualify(agent, true) !== "" && !agent.postQualifiedStatus &&
                                                                                    <div className="text-xxs font-semibold text-red-500 whitespace-no-wrap">{this.renders.failedDoubleQualify(agent, true)}</div>
                                                                                }
                                                                            </td>
                                                                        }
                                                                        {
                                                                            //legacy – rating mode enabled (display lead follow-up, only visible to anyone)
                                                                            !(this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value == 'enabled') &&
                                                                            (this.state.account.enterprise && this.state.account.enterprise.ratingModeLegacy && this.state.account.enterprise.ratingModeLegacy.value == 'enabled') &&
                                                                            <td className="text-center px-6 py-4">
                                                                                {
                                                                                    this.renders.failedDoubleQualify(agent) && this.renders.failedDoubleQualify(agent, true) !== "" && !agent.postQualifiedStatus &&
                                                                                    <div style={{ marginTop: '12px' }}></div>
                                                                                }
                                                                                <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.followUp ? agent.followUp.name : '-'}</div>
                                                                                <div className="text-sm text-gray-500 whitespace-no-wrap">Follow-up</div>
                                                                                {
                                                                                    this.renders.failedDoubleQualify(agent) && this.renders.failedDoubleQualify(agent, true) !== "" && !agent.postQualifiedStatus &&
                                                                                    <div className="text-xxs font-semibold text-red-500 whitespace-no-wrap">{this.renders.failedDoubleQualify(agent, true)}</div>
                                                                                }
                                                                            </td>
                                                                        }
                                                                        {
                                                                            //light (display lead follow-up, only visible to anyone)
                                                                            (this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value == 'enabled') &&
                                                                            <td className="text-center px-6 py-4">
                                                                                <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.followUp ? agent.followUp.name : '-'}</div>
                                                                                <div className="text-sm text-gray-500 whitespace-no-wrap">Follow-up</div>
                                                                            </td>
                                                                        }
                                                                        {
                                                                            false &&
                                                                            (this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value == 'enabled') &&
                                                                            <td className="text-center px-6 py-4">
                                                                                <div className="text-sm text-gray-900 whitespace-no-wrap capitalize">{agent.whatsAppMessageStatus ? agent.whatsAppMessageStatus : '-'}</div>
                                                                                <div className="text-sm text-gray-500 whitespace-no-wrap">Message</div>
                                                                            </td>
                                                                        }
                                                                        {
                                                                            (this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value == 'enabled') &&
                                                                            <td className="text-center px-6 py-4">
                                                                                {
                                                                                    this.renders.answersFBLength(agent) > 0 &&
                                                                                    <div className="text-sm text-gray-900 whitespace-no-wrap">{this.renders.answersFBLength(agent) !== 0 ? this.renders.answersFBLength(agent) + '/' + this.renders.answersFBLength(agent) : "-"}</div>
                                                                                }
                                                                                {
                                                                                    this.renders.answersFBLength(agent) < 1 &&
                                                                                    <div className="text-sm text-gray-900 whitespace-no-wrap">-</div>
                                                                                }
                                                                                <div className="text-sm text-gray-500 whitespace-no-wrap">Meta Q{"&"}A</div>
                                                                            </td>
                                                                        }
                                                                        {
                                                                            (this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value == 'enabled') &&
                                                                            <td className="text-center px-6 py-4">
                                                                                {
                                                                                    !(agent.formVersion && agent.formVersion.value == "no_questions") &&
                                                                                    <div className="text-sm text-gray-900 whitespace-no-wrap">{this.renders.answersLength(agent) !== 0 ? this.renders.answersLength(agent) + '/' + agent.questionSize : "-"}</div>
                                                                                }
                                                                                {
                                                                                    (agent.formVersion && agent.formVersion.value == "no_questions") &&
                                                                                    <div className="text-sm text-gray-900 whitespace-no-wrap">-</div>
                                                                                }
                                                                                <div className="text-sm text-gray-500 whitespace-no-wrap">Form Q{"&"}A</div>
                                                                            </td>
                                                                        }
                                                                        {
                                                                            (this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value == 'enabled') &&
                                                                            <td className="text-center px-6 py-4">
                                                                                {
                                                                                    agent.type.value == "double-qualified" &&
                                                                                    <div className="text-sm text-green-500 whitespace-no-wrap">{agent.type ? agent.type.name : "-"}</div>
                                                                                }
                                                                                {
                                                                                    agent.type.value == "qualified" &&
                                                                                    <div className="text-sm text-purple-500 whitespace-no-wrap">{agent.type ? agent.type.name : "-"}</div>
                                                                                }
                                                                                {
                                                                                    agent.type.value == "pre-qualified" &&
                                                                                    <div className="text-sm text-orange-500 whitespace-no-wrap">{agent.type ? agent.type.name : "-"}</div>
                                                                                }
                                                                                {
                                                                                    agent.type.value == "unqualified" &&
                                                                                    <div className="text-sm text-orange-500 whitespace-no-wrap">{agent.type ? agent.type.name : "-"}</div>
                                                                                }
                                                                                <div className="text-sm text-gray-500 whitespace-no-wrap">Status</div>
                                                                            </td>
                                                                        }
                                                                        {
                                                                            !(this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value == 'enabled') &&
                                                                            <td className="text-center px-6 py-4">
                                                                                {
                                                                                    this.renders.failedDoubleQualify(agent) && !agent.postQualifiedStatus &&
                                                                                    <div style={{ marginTop: '12px' }}></div>
                                                                                }
                                                                                {
                                                                                    agent.postQualifiedStatus &&
                                                                                    <div className="text-sm text-green-500 whitespace-no-wrap">{'Double-qualified'}</div>
                                                                                }
                                                                                {
                                                                                    agent.qualifiedStatus && !agent.postQualifiedStatus &&
                                                                                    <div className="text-sm text-purple-500 whitespace-no-wrap">{"Qualified"}</div>
                                                                                }
                                                                                {
                                                                                    !agent.qualifiedStatus && !agent.postQualifiedStatus &&
                                                                                    <div className="text-sm text-purple-500 whitespace-no-wrap">{agent.type ? agent.type.name : '-'}</div>
                                                                                }
                                                                                <div className="text-sm text-gray-500 whitespace-no-wrap">Status</div>
                                                                                {
                                                                                    this.renders.failedDoubleQualify(agent) && !agent.postQualifiedStatus &&
                                                                                    <div className="text-xxs font-semibold text-red-500 whitespace-no-wrap">failed double qualification</div>
                                                                                }
                                                                            </td>
                                                                        }
                                                                        {
                                                                            !(this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value == 'enabled') &&
                                                                            <td className="text-center px-6 py-4">
                                                                                {
                                                                                    (this.renders.completedWaFlow1(agent) || this.renders.completedWaFlow2(agent)) &&
                                                                                    <div style={{ marginTop: '12px' }}></div>
                                                                                }
                                                                                <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.latestAction ? moment(agent.latestAction).format("YYYY-MM-DD hh:mm a") : "-"}</div>
                                                                                <div className="text-sm text-gray-500 whitespace-no-wrap">Latest action</div>
                                                                                {
                                                                                    this.renders.completedWaFlow2(agent) && !this.renders.completedWaFlow1(agent) &&
                                                                                    <div className="text-xxs font-semibold text-yellow-500 whitespace-no-wrap">looking for similar listings and deals</div>
                                                                                }
                                                                                {
                                                                                    this.renders.completedWaFlow1(agent) && !this.renders.completedWaFlow2(agent) &&
                                                                                    <div className="text-xxs font-semibold text-yellow-500 whitespace-no-wrap">have some questions</div>
                                                                                }
                                                                                {
                                                                                    this.renders.completedWaFlow1(agent) && this.renders.completedWaFlow2(agent) &&
                                                                                    <div className="text-xxs font-semibold text-yellow-500 whitespace-no-wrap">looking for similar listings and deals</div>
                                                                                }
                                                                            </td>
                                                                        }
                                                                        {
                                                                            !(this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value == 'enabled') &&
                                                                            <td className="text-center px-6 py-4">
                                                                                <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.totalActions ? agent.totalActions : "-"}</div>
                                                                                <div className="text-sm text-gray-500 whitespace-no-wrap">Total actions</div>
                                                                            </td>
                                                                        }
                                                                    </tr>
                                                                    <tr key={agent.email} style={{
                                                                        height: (() => {
                                                                            const baseHeight = 50;
                                                                            const rows = this.renders.source(agent).split('\n').filter(line => line.trim() !== '').length;
                                                                            const mapHeight = (agent.trackingData && agent.trackingData.deviceLat) && (agent.trackingData && agent.trackingData.deviceLon) ? 18 : 0;
                                                                            const dynamicHeight = baseHeight + (rows * 5.15) + mapHeight;

                                                                            return !(this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value === 'enabled')
                                                                                ? (agent.open ? '' : '')
                                                                                : (agent.open ? dynamicHeight + 'rem' : '0rem');
                                                                        })()
                                                                    }} className={
                                                                        (() => {
                                                                            const baseHeight = 140;
                                                                            const rows = this.renders.source(agent).split('\n').filter(line => line.trim() !== '').length;
                                                                            const dynamicHeight = baseHeight + (rows * 5);

                                                                            return (!(this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value == 'enabled')
                                                                                ? (agent.open ? "h-160 sm:h-160 border-b" : "h-0 overflow-hidden")
                                                                                : (agent.open ? "border-b" : "h-0 overflow-hidden"))
                                                                                + " transition-height duration-500 ease-in-out border-gray-100 relative";
                                                                        })()
                                                                    }>
                                                                        {
                                                                            agent.open &&
                                                                            <div style={{
                                                                                height: (() => {
                                                                                    const baseHeight = 50;
                                                                                    const rows = this.renders.source(agent).split('\n').filter(line => line.trim() !== '').length;
                                                                                    const mapHeight = (agent.trackingData && agent.trackingData.deviceLat) && (agent.trackingData && agent.trackingData.deviceLon) ? 18 : 0;
                                                                                    const dynamicHeight = baseHeight + (rows * 5.15) + mapHeight;

                                                                                    return !(this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value === 'enabled')
                                                                                        ? ''  // Static height if condition is not met
                                                                                        : dynamicHeight + 'rem';  // Dynamic height based on rows
                                                                                })()
                                                                            }} className={
                                                                                (() => {
                                                                                    const baseHeight = 140;
                                                                                    const rows = this.renders.source(agent).split('\n').filter(line => line.trim() !== '').length;
                                                                                    const dynamicHeight = baseHeight + (rows * 5);

                                                                                    return (!(this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value == 'enabled')
                                                                                        ? "h-160 sm:h-160" : "")
                                                                                        + " absolute w-full bg-gray-50";
                                                                                })()
                                                                            }>
                                                                                <div className="w-full h-full p-6 flex flex-col">

                                                                                    {
                                                                                        this.renders.formActions(agent).length > 0 &&
                                                                                        (this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value == 'enabled') &&
                                                                                        <div style={{ minHeight: "110px" }} className="mb-6 bg-white border border-gray-100 flex flex-row rounded-md px-20 py-5 sm:px-20">
                                                                                            {
                                                                                                this.renders.formActions(agent).map((item, index, arr) => {
                                                                                                    const isFormAnswers = item.information === "form answers";
                                                                                                    const isFirst = index === 0;
                                                                                                    const isLast = index === arr.length - 1;

                                                                                                    let startSequence = null;
                                                                                                    let endSequence = null;
                                                                                                    let sequenceLength = 0;

                                                                                                    // Identify the sequence of "form answers"
                                                                                                    if (isFormAnswers) {
                                                                                                        startSequence = index;
                                                                                                        endSequence = index;

                                                                                                        // Find the start of the sequence
                                                                                                        while (startSequence > 0 && arr[startSequence - 1].information === "form answers") {
                                                                                                            startSequence--;
                                                                                                        }

                                                                                                        // Find the end of the sequence
                                                                                                        while (endSequence < arr.length - 1 && arr[endSequence + 1].information === "form answers") {
                                                                                                            endSequence++;
                                                                                                        }

                                                                                                        sequenceLength = endSequence - startSequence + 1;
                                                                                                    }

                                                                                                    return (
                                                                                                        <Fragment key={item.id}>
                                                                                                            {/* Render line if not the first item and not part of overlapping form answers */}
                                                                                                            {
                                                                                                                !isFirst && (!isFormAnswers || (isFormAnswers && index === startSequence)) &&
                                                                                                                <div className="flex flex-1">
                                                                                                                    <div className={(isLast && item.information === "pending qualification" ? "bg-gray-300" : "bg-purple-400") + " w-full mt-3.5 h-1"} />
                                                                                                                </div>
                                                                                                            }

                                                                                                            {/* Render question circle */}
                                                                                                            <div className={(isFormAnswers && index > startSequence ? "-ml-4" : "") + " relative"}>
                                                                                                                <div className="items-center justify-center flex flex-col">
                                                                                                                    <div className={(item.information === "pending qualification" ? "bg-gray-300" : "bg-purple-400") + " w-8 h-8 mb-0 items-center justify-center flex rounded-full"}>
                                                                                                                        <div className="w-5 h-5 bg-white rounded-full" />
                                                                                                                    </div>

                                                                                                                    {/* Render caption for single "form answers" or start of sequence */}
                                                                                                                    {
                                                                                                                        (!isFormAnswers || (isFormAnswers && sequenceLength === 1)) &&
                                                                                                                        <div className="absolute mt-0 text-center">
                                                                                                                            <div className={(item.information === "pending qualification" ? "text-gray-300" : "flex justify-center items-center text-gray-700") + " text-xs mt-20 whitespace-no-wrap font-medium"}>
                                                                                                                                {(item.information === "whatsapp verified" || item.information === "whatsapp response") ? (
                                                                                                                                    <>
                                                                                                                                        {
                                                                                                                                            item.information === "whatsapp response" &&
                                                                                                                                            item.extraInformation === "verified" &&
                                                                                                                                            <img
                                                                                                                                                className="w-3 mr-1"
                                                                                                                                                src={require("../../assets/images/whatsapp_icon.svg")}
                                                                                                                                                alt="WhatsApp Icon"
                                                                                                                                            />
                                                                                                                                        }
                                                                                                                                        2FA
                                                                                                                                        {item.information === "whatsapp verified" && <span className="ml-1">sent</span>}
                                                                                                                                        {item.information === "whatsapp response" && <span className="ml-1">{item.extraInformation}</span>}
                                                                                                                                    </>
                                                                                                                                ) : (
                                                                                                                                    item.information && item.information === "form answers" && sequenceLength === 1 ? "form answer" : item.information
                                                                                                                                )}
                                                                                                                                {item.information === "double-qualified" && item.extraInformation && item.extraInformation !== "" && <span className="ml-1">({item.extraInformation && item.extraInformation === "auto" ? "starred" : item.extraInformation})</span>}
                                                                                                                                {item.information === "whatsapp verified" && item.extraInformation && item.extraInformation !== "" && <span className="ml-1">({item.extraInformation && item.extraInformation === "facebook" ? "meta" : item.extraInformation})</span>}
                                                                                                                                {item.information === "reminder sent" && item.extraInformation === "failed" && <span className="ml-1">{"failed"}</span>}
                                                                                                                            </div>

                                                                                                                            {/* Only display date/time if not "pending qualification" */}
                                                                                                                            {item.information !== "pending qualification" &&
                                                                                                                                <div className="text-xxs text-gray-500 whitespace-no-wrap">
                                                                                                                                    {moment(item.createdAt).format("YYYY-MM-DD hh:mm a")}
                                                                                                                                </div>
                                                                                                                            }

                                                                                                                            {/* Display empty element with opacity for alignment */}
                                                                                                                            {item.information === "pending qualification" &&
                                                                                                                                <div className="text-xxs text-gray-500 whitespace-no-wrap opacity-0">pending</div>
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    }

                                                                                                                    {/* Render bundled caption for sequences of 2 or more "form answers" */}
                                                                                                                    {
                                                                                                                        isFormAnswers && index === endSequence && sequenceLength > 1 &&
                                                                                                                        <div className="absolute mt-0 pr-4 text-center" style={{ paddingRight: ((sequenceLength - 1) * 1) + 'rem' }}>
                                                                                                                            <div className="text-gray-700 text-xs mt-20 whitespace-no-wrap font-medium">
                                                                                                                                form answers
                                                                                                                            </div>
                                                                                                                            <div className="text-xxs text-gray-500 whitespace-no-wrap">
                                                                                                                                {moment(arr[startSequence].createdAt).format("YYYY-MM-DD hh:mm a")} - {moment(arr[endSequence].createdAt).format("YYYY-MM-DD hh:mm a")}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </div>

                                                                                                            {/* Render line if not the last item and not part of overlapping form answers */}
                                                                                                            {!isLast && (!isFormAnswers || (isFormAnswers && index === endSequence)) &&
                                                                                                                <div className="flex flex-1">
                                                                                                                    <div className={(isLast && item.information === "pending qualification" ? "bg-gray-300" : "bg-purple-400") + " w-full mt-3.5 h-1"} />
                                                                                                                </div>
                                                                                                            }
                                                                                                        </Fragment>
                                                                                                    );
                                                                                                })
                                                                                            }
                                                                                        </div>
                                                                                    }
                                                                                    <div className="flex flex-1 overflow-hidden">
                                                                                        <div className="bg-white rounded-md border border-gray-100 w-full h-full flex flex-col">
                                                                                            {
                                                                                                false &&
                                                                                                <div className="px-4 py-5 sm:px-6">
                                                                                                    <h2 className="text-sm font-medium leading-6 text-gray-900">Lead Information</h2>
                                                                                                    <p className="mt-1 max-w-2xl text-sm text-gray-500">Personal details and actions</p>
                                                                                                </div>
                                                                                            }
                                                                                            {
                                                                                                //failed post-qualification with answers
                                                                                                !(this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value == 'enabled') &&
                                                                                                Array.isArray(agent.list_leads) &&
                                                                                                this.renders.clientExtraInformation(agent.list_leads, 'pre-qualified', agent) &&
                                                                                                this.renders.failedDoubleQualify(agent) &&
                                                                                                !agent.postQualifiedStatus &&
                                                                                                <div className="h-21 py-0 sm:grid sm:grid-cols-12 border-t border-gray-200 sm:gap-4 sm:py-5 sm:px-6 flex flex-col sm:flex-row items-start sm:items-center">
                                                                                                    <dt className="text-sm font-medium text-gray-500 relative sm:col-span-2">
                                                                                                        Post-qualification results
                                                                                                    </dt>
                                                                                                    <dd className="text-sm text-red-500 sm:col-span-2 sm:mt-0 w-full sm:col-span-10">
                                                                                                        {
                                                                                                            //if only answers exist from pre-qualification client rejected to answer
                                                                                                            !this.renders.clientLatestFailedExtraInformation(agent.list_leads, 'pre-qualified', agent) &&
                                                                                                            <span>
                                                                                                                I prefer not to answer questions over WhatsApp, contact me about my requirements.
                                                                                                            </span>
                                                                                                        }
                                                                                                        {
                                                                                                            //if answers exist from post-qualification display answers (note this action got pre-qualification status why need to filter out any pre-qualification answers)
                                                                                                            this.renders.clientLatestFailedExtraInformation(agent.list_leads, 'pre-qualified', agent) &&
                                                                                                            <span>
                                                                                                                {
                                                                                                                    this.renders.clientLatestFailedExtraInformation(agent.list_leads, 'pre-qualified', agent)
                                                                                                                }
                                                                                                            </span>
                                                                                                        }
                                                                                                    </dd>
                                                                                                </div>
                                                                                            }
                                                                                            {
                                                                                                //failed post-qualification without answers
                                                                                                !(this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value == 'enabled') &&
                                                                                                Array.isArray(agent.list_leads) &&
                                                                                                !this.renders.clientExtraInformation(agent.list_leads, 'pre-qualified', agent) &&
                                                                                                this.renders.failedDoubleQualify(agent) &&
                                                                                                !agent.postQualifiedStatus &&
                                                                                                <div className="h-21 py-0 sm:grid sm:grid-cols-12 border-t border-gray-200 sm:gap-4 sm:py-5 sm:px-6 flex flex-col sm:flex-row items-start sm:items-center">
                                                                                                    <dt className="text-sm font-medium text-gray-500 relative sm:col-span-2">
                                                                                                        Post-qualification results
                                                                                                    </dt>
                                                                                                    <dd className="text-sm text-red-500 sm:col-span-2 sm:mt-0 w-full sm:col-span-10">
                                                                                                        {
                                                                                                            <span>I prefer not to answer questions over WhatsApp, contact me about my requirements.</span>
                                                                                                        }
                                                                                                    </dd>
                                                                                                </div>
                                                                                            }
                                                                                            {
                                                                                                //Passed pre-qualification via questionnaire
                                                                                                !(this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value == 'enabled') &&
                                                                                                Array.isArray(agent.list_leads) &&
                                                                                                this.renders.clientLatestPreQualifiedExtraInformation(agent.list_leads, 'pre-qualified', agent) &&
                                                                                                <div className="h-21 py-0 sm:grid sm:grid-cols-12 border-t border-gray-200 sm:gap-4 sm:py-5 sm:px-6 flex flex-col sm:flex-row items-start sm:items-center">
                                                                                                    <dt className="text-sm font-medium text-gray-500 relative sm:col-span-2">
                                                                                                        Pre-qualification results
                                                                                                    </dt>
                                                                                                    <dd className="text-sm text-purple-500 sm:col-span-2 sm:mt-0 w-full sm:col-span-10">
                                                                                                        {
                                                                                                            this.renders.clientExtraInformation(agent.list_leads, 'pre-qualified', agent)
                                                                                                        }
                                                                                                    </dd>
                                                                                                </div>
                                                                                            }
                                                                                            {
                                                                                                //passed post-qualification
                                                                                                !(this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value == 'enabled') &&
                                                                                                Array.isArray(agent.list_leads) &&
                                                                                                this.renders.clientExtraInformation(agent.list_leads, 'double-qualified', agent) &&
                                                                                                !this.renders.failedDoubleQualify(agent) &&
                                                                                                <div className="h-21 py-0 sm:grid sm:grid-cols-12 border-t border-gray-200 sm:gap-4 sm:py-5 sm:px-6 flex flex-col sm:flex-row items-start sm:items-center">
                                                                                                    <dt className="text-sm font-medium text-gray-500 relative sm:col-span-2">
                                                                                                        Post-qualification results
                                                                                                    </dt>
                                                                                                    <dd className="text-sm text-green-500 sm:col-span-2 sm:mt-0 w-full sm:col-span-10">
                                                                                                        {
                                                                                                            this.renders.clientExtraInformation(agent.list_leads, 'double-qualified', agent)
                                                                                                        }
                                                                                                    </dd>
                                                                                                </div>
                                                                                            }
                                                                                            {
                                                                                                //passed post-qualification but failed after
                                                                                                !(this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value == 'enabled') &&
                                                                                                Array.isArray(agent.list_leads) &&
                                                                                                this.renders.clientExtraInformation(agent.list_leads, 'double-qualified', agent) &&
                                                                                                this.renders.failedDoubleQualify(agent) &&
                                                                                                agent.postQualifiedStatus &&
                                                                                                <div className="h-21 py-0 sm:grid sm:grid-cols-12 border-t border-gray-200 sm:gap-4 sm:py-5 sm:px-6 flex flex-col sm:flex-row items-start sm:items-center">
                                                                                                    <dt className="text-sm font-medium text-gray-500 relative sm:col-span-2">
                                                                                                        Post-qualification results
                                                                                                    </dt>
                                                                                                    <dd className="text-sm text-green-500 sm:col-span-2 sm:mt-0 w-full sm:col-span-10">
                                                                                                        {
                                                                                                            this.renders.clientExtraInformation(agent.list_leads, 'double-qualified', agent)
                                                                                                        }
                                                                                                    </dd>
                                                                                                </div>
                                                                                            }
                                                                                            <div className="grid grid-cols-12">
                                                                                                <div className="col-span-12 sm:col-span-6">
                                                                                                    <div className=" border-gray-100 px-4 py-5 sm:p-0">
                                                                                                        <dl className="mb-2 sm:divide-y sm:divide-gray-100">
                                                                                                            {
                                                                                                                //legacy desktop – rating mode enabled (manager and agent can rate lead)
                                                                                                                //TODO: only update ratingDate when updateLead is called (currently both ratingDate and followUpDate dates are updated)
                                                                                                                //TODO: if rating mode enabled update client rating with latest lead rating every time lead rating is updated
                                                                                                                (this.state.account.enterprise && this.state.account.enterprise.ratingModeLegacy && this.state.account.enterprise.ratingModeLegacy.value == 'enabled') &&
                                                                                                                <div className="h-24 py-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6 flex flex-col sm:flex-row items-start sm:items-center">
                                                                                                                    <dt className="text-sm font-medium text-gray-500 relative">
                                                                                                                        <div className="flex flex-row items-center">
                                                                                                                            Rate lead
                                                                                                                        </div>
                                                                                                                        {
                                                                                                                            !agent.ratingFromApi && agent.ratingAgent && agent.ratingAgent.id &&
                                                                                                                            <div className="text-purple-500 absolute rounded-md font-medium text-xs">
                                                                                                                                By: {agent.ratingAgent.name}
                                                                                                                                {
                                                                                                                                    agent.ratingDate &&
                                                                                                                                    <div className="text-xxxs" >{agent.ratingDate ? moment(agent.ratingDate).format("YYYY-MM-DD hh:mm a") : ""}</div>
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                        }
                                                                                                                        {
                                                                                                                            agent.ratingFromApi &&
                                                                                                                            <div className="text-purple-500 absolute rounded-md font-medium text-xs">
                                                                                                                                By: API user
                                                                                                                                {
                                                                                                                                    agent.ratingDate &&
                                                                                                                                    <div className="text-xxxs" >{agent.ratingDate ? moment(agent.ratingDate).format("YYYY-MM-DD hh:mm a") : ""}</div>
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                        }
                                                                                                                    </dt>
                                                                                                                    <dd className="text-sm text-gray-900 sm:col-span-2 sm:mt-0 w-full">
                                                                                                                        <SingleSelection
                                                                                                                            select={async (item) => {
                                                                                                                                agent.rating = item;
                                                                                                                                await this.promisedSetState({
                                                                                                                                    users: this.state.users
                                                                                                                                });
                                                                                                                                this.functions.updateLead(agent, { type: 'rating' });
                                                                                                                            }}
                                                                                                                            selected={agent.rating ? agent.rating : { id: 0, name: 'Select rating', value: null }}
                                                                                                                            options={[{ id: 5, name: 'Signed deal', value: 'signed_deal' }, { id: 1, name: 'High intent', value: 'high' }, { id: 2, name: 'Medium intent', value: 'medium' }, { id: 3, name: 'Low intent', value: 'low' }, { id: 4, name: 'Spam', value: 'block' }]}
                                                                                                                        />
                                                                                                                    </dd>
                                                                                                                </div>
                                                                                                            }
                                                                                                            {
                                                                                                                //legacy desktop – rating mode disabled (manager and agent can rate client)
                                                                                                                //TODO: only update ratingDate when updateClient is called (currently both ratingDate and followUpDate dates are updated)
                                                                                                                !(this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value == 'enabled') &&
                                                                                                                !(this.state.account.enterprise && this.state.account.enterprise.ratingModeLegacy && this.state.account.enterprise.ratingModeLegacy.value == 'enabled') &&
                                                                                                                <div className="h-24 py-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6 flex flex-col sm:flex-row items-start sm:items-center">
                                                                                                                    <dt className="text-sm font-medium text-gray-500 relative">
                                                                                                                        <div className="flex flex-row items-center">
                                                                                                                            Rate client
                                                                                                                        </div>
                                                                                                                        {
                                                                                                                            agent.client && !agent.client.ratingFromApi && agent.client.ratingAgent && agent.client.ratingAgent.id &&
                                                                                                                            <div className="text-purple-500 absolute rounded-md font-medium text-xs">
                                                                                                                                By: {agent.client.ratingAgent.name}
                                                                                                                                {
                                                                                                                                    agent.client && agent.client.ratingDate &&
                                                                                                                                    <div className="text-xxxs" >{agent.client.ratingDate ? moment(agent.client.ratingDate).format("YYYY-MM-DD hh:mm a") : ""}</div>
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                        }
                                                                                                                        {
                                                                                                                            agent.client && agent.client.ratingFromApi &&
                                                                                                                            <div className="text-purple-500 absolute rounded-md font-medium text-xs">
                                                                                                                                By: API user
                                                                                                                                {
                                                                                                                                    agent.client && agent.client.ratingDate &&
                                                                                                                                    <div className="text-xxxs" >{agent.client.ratingDate ? moment(agent.client.ratingDate).format("YYYY-MM-DD hh:mm a") : ""}</div>
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                        }
                                                                                                                    </dt>
                                                                                                                    <dd className="text-sm text-gray-900 sm:col-span-2 sm:mt-0 w-full">
                                                                                                                        <SingleSelection
                                                                                                                            select={async (item) => {
                                                                                                                                agent.client.rating = item;
                                                                                                                                await this.promisedSetState({
                                                                                                                                    users: this.state.users
                                                                                                                                });
                                                                                                                                this.functions.updateClient(agent.client, { type: 'rating' }, agent.id);
                                                                                                                            }}
                                                                                                                            selected={agent.client && agent.client.rating ? agent.client.rating : { id: 0, name: 'Select rating', value: null }}
                                                                                                                            options={[{ id: 5, name: 'Signed deal', value: 'signed_deal' }, { id: 1, name: 'High intent', value: 'high' }, { id: 2, name: 'Medium intent', value: 'medium' }, { id: 3, name: 'Low intent', value: 'low' }, { id: 4, name: 'Spam', value: 'block' }]}
                                                                                                                        />
                                                                                                                    </dd>
                                                                                                                </div>
                                                                                                            }
                                                                                                            {
                                                                                                                //light desktop (manager and agent can rate lead)
                                                                                                                //TODO: only update ratingDate when updateLead is called (currently both ratingDate and followUpDate dates are updated)
                                                                                                                //TODO: update client rating with latest lead rating every time lead rating is updated
                                                                                                                (this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value == 'enabled') &&
                                                                                                                <div className="h-24 py-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6 flex flex-col sm:flex-row items-start sm:items-center">
                                                                                                                    <dt className="text-sm font-medium text-gray-500 relative">
                                                                                                                        <div className="flex flex-row items-center">
                                                                                                                            Rate lead
                                                                                                                        </div>
                                                                                                                        {
                                                                                                                            !agent.ratingFromApi && agent.ratingAgent && agent.ratingAgent.id &&
                                                                                                                            <div className="text-purple-500 absolute rounded-md font-medium text-xs">
                                                                                                                                By: {agent.ratingAgent.name}
                                                                                                                                {
                                                                                                                                    agent.ratingDate &&
                                                                                                                                    <div className="text-xxxs" >{agent.ratingDate ? moment(agent.ratingDate).format("YYYY-MM-DD hh:mm a") : ""}</div>
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                        }
                                                                                                                        {
                                                                                                                            agent.ratingFromApi &&
                                                                                                                            <div className="text-purple-500 absolute rounded-md font-medium text-xs">
                                                                                                                                By: API user
                                                                                                                                {
                                                                                                                                    agent.ratingDate &&
                                                                                                                                    <div className="text-xxxs" >{agent.ratingDate ? moment(agent.ratingDate).format("YYYY-MM-DD hh:mm a") : ""}</div>
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                        }
                                                                                                                    </dt>
                                                                                                                    <dd className="text-sm text-gray-900 sm:col-span-2 sm:mt-0 w-full">
                                                                                                                        <SingleSelection
                                                                                                                            select={async (item) => {
                                                                                                                                agent.rating = item;
                                                                                                                                await this.promisedSetState({
                                                                                                                                    users: this.state.users
                                                                                                                                });
                                                                                                                                this.functions.updateLead(agent, { type: 'rating' });
                                                                                                                            }}
                                                                                                                            selected={agent.rating ? agent.rating : { id: 0, name: 'Select rating', value: null }}
                                                                                                                            options={[{ id: 5, name: 'Signed deal', value: 'signed_deal' }, { id: 1, name: 'High intent', value: 'high' }, { id: 2, name: 'Medium intent', value: 'medium' }, { id: 3, name: 'Low intent', value: 'low' }, { id: 4, name: 'Spam', value: 'block' }]}
                                                                                                                        />
                                                                                                                    </dd>
                                                                                                                </div>
                                                                                                            }
                                                                                                            {
                                                                                                                //legacy desktop – rating mode enabled (anyone can follow-up lead)
                                                                                                                //TODO: only update followUpDate when updateLead is called (currently both ratingDate and followUpDate dates are updated)
                                                                                                                //TODO: if rating mode enabled update client follow-up with latest lead follow-up every time lead follow-up is updated
                                                                                                                (this.state.account.enterprise && this.state.account.enterprise.ratingModeLegacy && this.state.account.enterprise.ratingModeLegacy.value == 'enabled') &&
                                                                                                                <div className="h-24 py-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6 flex flex-col sm:flex-row items-start sm:items-center">
                                                                                                                    <dt className="text-sm font-medium text-gray-500 relative">
                                                                                                                        <div className="flex flex-row items-center">
                                                                                                                            Follow-up lead
                                                                                                                        </div>
                                                                                                                        {
                                                                                                                            !agent.followUpFromApi && agent.followUpAgent && agent.followUpAgent.id &&
                                                                                                                            <div className="text-purple-500 absolute rounded-md font-medium text-xs">
                                                                                                                                By: {agent.followUpAgent.name}
                                                                                                                                {
                                                                                                                                    agent.followUpDate &&
                                                                                                                                    <div className="text-xxxs" >{agent.followUpDate ? moment(agent.followUpDate).format("YYYY-MM-DD hh:mm a") : ""}</div>
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                        }
                                                                                                                        {
                                                                                                                            agent.followUpFromApi &&
                                                                                                                            <div className="text-purple-500 absolute rounded-md font-medium text-xs">
                                                                                                                                By: API user
                                                                                                                                {
                                                                                                                                    agent.followUpDate &&
                                                                                                                                    <div className="text-xxxs" >{agent.followUpDate ? moment(agent.followUpDate).format("YYYY-MM-DD hh:mm a") : ""}</div>
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                        }
                                                                                                                    </dt>
                                                                                                                    <dd className="text-sm text-gray-900 sm:col-span-2 sm:mt-0 w-full">
                                                                                                                        <SingleSelection
                                                                                                                            select={async (item) => {
                                                                                                                                agent.followUp = item;
                                                                                                                                await this.promisedSetState({
                                                                                                                                    users: this.state.users
                                                                                                                                });
                                                                                                                                this.functions.updateLead(agent, { type: 'followUp' });
                                                                                                                            }}
                                                                                                                            selected={agent.followUp ? agent.followUp : { id: 0, name: 'Select follow-up', value: null }}
                                                                                                                            options={[
                                                                                                                                { id: 5, name: 'Followed up', value: 'followed_up' },
                                                                                                                                { id: 1, name: ' Not followed up', value: 'not_followed_up' },
                                                                                                                                { id: 2, name: 'Pending follow-up', value: 'pending_follow_up' }
                                                                                                                            ]}
                                                                                                                        />
                                                                                                                    </dd>
                                                                                                                </div>
                                                                                                            }
                                                                                                            {
                                                                                                                //legacy desktop – rating mode disabled (anyone can follow-up client)
                                                                                                                //TODO: only update followUpDate when updateClient is called (currently both ratingDate and followUpDate dates are updated)
                                                                                                                !(this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value == 'enabled') &&
                                                                                                                !(this.state.account.enterprise && this.state.account.enterprise.ratingModeLegacy && this.state.account.enterprise.ratingModeLegacy.value == 'enabled') &&
                                                                                                                <div className="h-24 py-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6 flex flex-col sm:flex-row items-start sm:items-center">
                                                                                                                    <dt className="text-sm font-medium text-gray-500 relative">
                                                                                                                        <div className="flex flex-row items-center">
                                                                                                                            Follow-up client
                                                                                                                        </div>
                                                                                                                        {
                                                                                                                            agent.client && !agent.client.followUpFromApi && agent.client.followUpAgent && agent.client.followUpAgent.id &&
                                                                                                                            <div className="text-purple-500 absolute rounded-md font-medium text-xs">
                                                                                                                                By: {agent.client.followUpAgent.name}
                                                                                                                                {
                                                                                                                                    agent.client && agent.client.followUpDate &&
                                                                                                                                    <div className="text-xxxs" >{agent.client.followUpDate ? moment(agent.client.followUpDate).format("YYYY-MM-DD hh:mm a") : ""}</div>
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                        }
                                                                                                                        {
                                                                                                                            agent.client && agent.client.followUpFromApi &&
                                                                                                                            <div className="text-purple-500 absolute rounded-md font-medium text-xs">
                                                                                                                                By: API user
                                                                                                                                {
                                                                                                                                    agent.client && agent.client.followUpDate &&
                                                                                                                                    <div className="text-xxxs" >{agent.client.followUpDate ? moment(agent.client.followUpDate).format("YYYY-MM-DD hh:mm a") : ""}</div>
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                        }
                                                                                                                    </dt>
                                                                                                                    <dd className="text-sm text-gray-900 sm:col-span-2 sm:mt-0 w-full">
                                                                                                                        <SingleSelection
                                                                                                                            select={async (item) => {
                                                                                                                                agent.client.followUp = item;
                                                                                                                                await this.promisedSetState({
                                                                                                                                    users: this.state.users
                                                                                                                                });
                                                                                                                                this.functions.updateClient(agent.client, { type: 'followUp' });
                                                                                                                            }}
                                                                                                                            selected={agent.client.followUp ? agent.client.followUp : { id: 0, name: 'Select follow-up', value: null }}
                                                                                                                            options={[
                                                                                                                                { id: 5, name: 'Followed up', value: 'followed_up' },
                                                                                                                                { id: 1, name: ' Not followed up', value: 'not_followed_up' },
                                                                                                                                { id: 2, name: 'Pending follow-up', value: 'pending_follow_up' }
                                                                                                                            ]}
                                                                                                                        />
                                                                                                                    </dd>
                                                                                                                </div>
                                                                                                            }
                                                                                                            {
                                                                                                                //light desktop (anyone can follow-up lead)
                                                                                                                //TODO: only update followUpDate when updateLead is called (currently both ratingDate and followUpDate dates are updated)
                                                                                                                //TODO: update client follow-up with latest lead follow-up every time lead follow-up is updated
                                                                                                                (this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value == 'enabled') &&
                                                                                                                <div className="h-24 py-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6 flex flex-col sm:flex-row items-start sm:items-center">
                                                                                                                    <dt className="text-sm font-medium text-gray-500 relative">
                                                                                                                        <div className="flex flex-row items-center">
                                                                                                                            Follow-up lead
                                                                                                                        </div>
                                                                                                                        {
                                                                                                                            !agent.followUpFromApi && agent.followUpAgent && agent.followUpAgent.id &&
                                                                                                                            <div className="text-purple-500 absolute rounded-md font-medium text-xs">
                                                                                                                                By: {agent.followUpAgent.name}
                                                                                                                                {
                                                                                                                                    agent.followUpDate &&
                                                                                                                                    <div className="text-xxxs" >{agent.followUpDate ? moment(agent.followUpDate).format("YYYY-MM-DD hh:mm a") : ""}</div>
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                        }
                                                                                                                        {
                                                                                                                            agent.followUpFromApi &&
                                                                                                                            <div className="text-purple-500 absolute rounded-md font-medium text-xs">
                                                                                                                                By: API user
                                                                                                                                {
                                                                                                                                    agent.followUpDate &&
                                                                                                                                    <div className="text-xxxs" >{agent.followUpDate ? moment(agent.followUpDate).format("YYYY-MM-DD hh:mm a") : ""}</div>
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                        }
                                                                                                                    </dt>
                                                                                                                    <dd className="text-sm text-gray-900 sm:col-span-2 sm:mt-0 w-full">
                                                                                                                        <SingleSelection
                                                                                                                            select={async (item) => {
                                                                                                                                agent.followUp = item;
                                                                                                                                await this.promisedSetState({
                                                                                                                                    users: this.state.users
                                                                                                                                });
                                                                                                                                this.functions.updateLead(agent, { type: 'followUp' });
                                                                                                                            }}
                                                                                                                            selected={agent.followUp ? agent.followUp : { id: 0, name: 'Select follow-up', value: null }}
                                                                                                                            options={[
                                                                                                                                { id: 5, name: 'Followed up', value: 'followed_up' },
                                                                                                                                { id: 1, name: 'Not followed up', value: 'not_followed_up' },
                                                                                                                                { id: 2, name: 'Pending follow-up', value: 'pending_follow_up' }
                                                                                                                            ]}
                                                                                                                        />
                                                                                                                    </dd>
                                                                                                                </div>
                                                                                                            }
                                                                                                            <div className="min-h-24 py-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6 flex flex-col sm:flex-row items-start sm:items-center">
                                                                                                                <dt className="text-sm font-medium text-gray-500">Note</dt>
                                                                                                                <dd className="text-sm text-gray-900 sm:col-span-2 sm:mt-0 w-full">
                                                                                                                    <TextArea
                                                                                                                        dontResize={(this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value == 'enabled')}
                                                                                                                        rows={(this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value == 'enabled') ? 5 : 2}
                                                                                                                        onChange={async (value) => {
                                                                                                                            agent.note = value;
                                                                                                                            await this.promisedSetState({
                                                                                                                                users: this.state.users
                                                                                                                            });
                                                                                                                        }}
                                                                                                                        onBlur={() => {
                                                                                                                            this.functions.updateLead(agent, { type: 'note' });
                                                                                                                        }}
                                                                                                                        value={agent.note}
                                                                                                                        type={"text"}
                                                                                                                    />
                                                                                                                </dd>
                                                                                                            </div>
                                                                                                            <div className="h-21 py-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6 flex flex-col sm:flex-row items-start sm:items-center">
                                                                                                                <dt className="text-sm font-medium text-gray-500">Name</dt>
                                                                                                                {
                                                                                                                    (agent.client.company && agent.client.company !== "") &&
                                                                                                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{agent.client.company}</dd>
                                                                                                                }
                                                                                                                {
                                                                                                                    !(agent.client.company && agent.client.company !== "") &&
                                                                                                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{agent.client.name}</dd>
                                                                                                                }
                                                                                                            </div>
                                                                                                            <div className="h-21 py-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6 flex flex-col sm:flex-row items-start sm:items-center">
                                                                                                                <dt className="text-sm font-medium text-gray-500">Email</dt>
                                                                                                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{agent.client.email}</dd>
                                                                                                            </div>
                                                                                                            <div className="py-0 sm:h-21 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6 flex flex-col sm:flex-row items-start sm:items-center">
                                                                                                                <dt className="text-sm font-medium text-gray-500">Phone</dt>
                                                                                                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                                                                                                    <div className="relative inline-flex"> {agent.client.phone}
                                                                                                                        {
                                                                                                                            (agent.has_whatsapp || (agent.client && agent.client.whatsapp)) &&
                                                                                                                            <img style={{ right: "-17px", top: "4px" }} className="absolute w-3" src={require("../../assets/images/whatsapp_icon.svg")} />
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                </dd>
                                                                                                            </div>
                                                                                                            {
                                                                                                                (this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value == 'enabled') && this.renders.source(agent).split('\n').filter(line => line.trim() !== '').length > 0 &&
                                                                                                                <div>
                                                                                                                    {this.renders.source(agent).split('\n').map((row, index, arr) => {
                                                                                                                        const [label, value] = row.split('\t');
                                                                                                                        return (
                                                                                                                            <div
                                                                                                                                key={index}
                                                                                                                                className={"h-21 py-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6 flex flex-col sm:flex-row items-start sm:items-center" + (index !== arr.length - 1 ? " border-b border-gray-100" : "")}
                                                                                                                            >
                                                                                                                                <dt className="text-sm font-medium text-gray-500">{label}</dt>
                                                                                                                                <dd className="flex flex-row mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{value}
                                                                                                                                    {
                                                                                                                                        label === 'Channel' &&
                                                                                                                                        <div style={{ padding: "0.2rem", marginTop: "2px" }} className="flex items-center justify-center rounded-full bg-purple-500 border border-white h-4 w-4 ml-1">
                                                                                                                                            {
                                                                                                                                                (agent.channel === 'facebook' || agent.channel === 'meta') &&
                                                                                                                                                <img className="w-3" src={require("../../assets/images/AB_Social_meta.png")} />
                                                                                                                                            }
                                                                                                                                            {
                                                                                                                                                agent.channel === 'google' &&
                                                                                                                                                <img className="w-3" src={require("../../assets/images/AB_Social_google.png")} />
                                                                                                                                            }
                                                                                                                                            {
                                                                                                                                                agent.channel === 'tiktok' &&
                                                                                                                                                <img className="w-3" src={require("../../assets/images/AB_Social_tiktok.png")} />
                                                                                                                                            }
                                                                                                                                            {
                                                                                                                                                agent.channel === 'snapchat' &&
                                                                                                                                                <img className="w-3" src={require("../../assets/images/AB_Social_snapchat.png")} />
                                                                                                                                            }
                                                                                                                                            {
                                                                                                                                                agent.channel === 'proptree' &&
                                                                                                                                                <img className="w-3" src={require("../../assets/images/AB_Social_proptree.png")} />
                                                                                                                                            }
                                                                                                                                            {
                                                                                                                                                agent.channel === 'internal' &&
                                                                                                                                                <img className="w-3" src={require("../../assets/images/AB_Social_internal.png")} />
                                                                                                                                            }
                                                                                                                                            {
                                                                                                                                                agent.channel === '' &&
                                                                                                                                                <img className="w-3" src={require("../../assets/images/AB_Social_internal.png")} />
                                                                                                                                            }
                                                                                                                                        </div>
                                                                                                                                    }
                                                                                                                                </dd>
                                                                                                                            </div>
                                                                                                                        );
                                                                                                                    })}
                                                                                                                </div>
                                                                                                            }
                                                                                                            {
                                                                                                                (this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value == 'enabled') && (agent.trackingData && agent.trackingData.deviceLat) && (agent.trackingData && agent.trackingData.deviceLon) &&
                                                                                                                <div className={"flex flex-1 justify-center items-center"} style={{ minHeight: "288px" }} tabIndex={-1}>
                                                                                                                    {
                                                                                                                        <MyMapWrapper>
                                                                                                                            <MyMapComponentBranding
                                                                                                                                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyC7VGHdxqVaDndshl1c9AZCTvnpxScOooQ&v=3.57&libraries=geometry,drawing,places"
                                                                                                                                loadingElement={<div style={{ height: "288px", borderRadius: "0" }} tabIndex={-1} onFocus={(e) => e.target.style.outline = "none"} onBlur={(e) => e.target.style.outline = "none"} />}
                                                                                                                                containerElement={<div style={{ height: "288px", width: "100%", border: "0", borderRadius: "0" }} tabIndex={-1} onFocus={(e) => e.target.style.outline = "none"} onBlur={(e) => e.target.style.outline = "none"} />}
                                                                                                                                mapElement={<div style={{ height: "288px", borderRadius: "0" }} tabIndex={-1} onFocus={(e) => e.target.style.outline = "none"} onBlur={(e) => e.target.style.outline = "none"} />}
                                                                                                                                location={agent.client.name && agent.client.name !== "" ? agent.client.name : (agent.client.company && agent.client.company !== "" ? agent.client.company : "lead")}
                                                                                                                                lat={Number(agent.trackingData.deviceLat)}
                                                                                                                                lng={Number(agent.trackingData.deviceLon)}
                                                                                                                                branding_primary_encoded={"%238480e3"}
                                                                                                                                branding_primary={"#8480e3"}
                                                                                                                                dark_mode={false}
                                                                                                                                zoom={11}
                                                                                                                            />
                                                                                                                        </MyMapWrapper>
                                                                                                                    }
                                                                                                                </div>
                                                                                                            }
                                                                                                        </dl>
                                                                                                    </div>
                                                                                                </div>
                                                                                                {
                                                                                                    (this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value == 'enabled') &&
                                                                                                    <div className="col-span-12 sm:col-span-6 relative border-l">
                                                                                                        <nav style={{ marginTop: '31px' }} className="flex px-6 mt-4 mb-4" aria-label="Tabs">
                                                                                                            {(() => {
                                                                                                                const hasMetaAnswers = agent.answeredQuestions && 'facebook' in agent.answeredQuestions && Object.keys(agent.answeredQuestions.facebook).length > 0;
                                                                                                                const hasFormAnswers = agent.answeredQuestions && Object.keys(agent.answeredQuestions).some(key => key !== 'facebook' && Object.keys(agent.answeredQuestions[key]).length > 0);
                                                                                                                if (hasMetaAnswers && hasFormAnswers) {
                                                                                                                    return [
                                                                                                                        { name: "Meta answers", value: "facebook" },
                                                                                                                        { name: "Form answers", value: "form" }
                                                                                                                    ];
                                                                                                                } else if (hasMetaAnswers) {
                                                                                                                    return [{ name: "Meta answers", value: "facebook" }];
                                                                                                                } else if (hasFormAnswers) {
                                                                                                                    return [{ name: "Form answers", value: "form" }];
                                                                                                                } else {
                                                                                                                    return [];
                                                                                                                }
                                                                                                            })().map((tab) => (
                                                                                                                <div
                                                                                                                    key={tab.name}
                                                                                                                    className={classNames(
                                                                                                                        agent.tab && agent.tab.value == tab.value ? 'bg-indigo-100 text-indigo-700' : 'text-gray-500 hover:text-gray-700',
                                                                                                                        'rounded-md px-3 py-2 text-sm font-medium cursor-pointer mr-4'
                                                                                                                    )}
                                                                                                                    onClick={() => {
                                                                                                                        agent.tab = tab;
                                                                                                                        this.setState({
                                                                                                                            users: this.state.users
                                                                                                                        });
                                                                                                                    }}
                                                                                                                    aria-current={tab.current ? 'page' : undefined}
                                                                                                                >
                                                                                                                    {tab.name}
                                                                                                                </div>
                                                                                                            ))}
                                                                                                        </nav>
                                                                                                        <div className="h-full w-full p-6 absolute overflow-y-scroll">
                                                                                                            {
                                                                                                                this.renders.answers(agent).map((item, index, array) => {
                                                                                                                    const isLastItem = index === array.length - 1;
                                                                                                                    return (
                                                                                                                        <div className={isLastItem ? "mb-24 bg-blue-100 p-4 rounded-md text-blue-500 relative" : "mb-4 bg-blue-100 p-4 rounded-md text-blue-500 relative"} key={item.id}>
                                                                                                                            {
                                                                                                                                item.correct_option &&
                                                                                                                                <StarIcon style={{ right: "15px", bottom: "15px" }} className="w-5 absolute" />
                                                                                                                            }
                                                                                                                            <div className="text-xs flex font-medium text-gray-500">
                                                                                                                                <div>Question</div>
                                                                                                                                <div className="flex flex-1 justify-end">{moment(item.date).format("YYYY-MM-DD hh:mm a")}</div>
                                                                                                                            </div>
                                                                                                                            <div className="text-sm font-medium mb-2">{item.question}</div>
                                                                                                                            <div className="text-xs font-medium text-gray-500">Answer</div>
                                                                                                                            <div className="text-sm font-medium">{item.answer}</div>
                                                                                                                        </div>
                                                                                                                    );
                                                                                                                })
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                }
                                                                                                {
                                                                                                    !(this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value == 'enabled') &&
                                                                                                    <div className="col-span-12 sm:col-span-6 mb-4">
                                                                                                        <div className="h-full px-4 py-5 sm:p-0">
                                                                                                            <dl className="sm:divide-y h-full sm:divide-gray-100">
                                                                                                                <div className="py-0 sm:pt-4 h-full sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                                                                                    <Bar
                                                                                                                        options={{
                                                                                                                            maintainAspectRatio: false,
                                                                                                                            interaction: {
                                                                                                                                intersect: false,
                                                                                                                            },
                                                                                                                            legend: {
                                                                                                                                display: false
                                                                                                                            },
                                                                                                                            scales: {
                                                                                                                                x: {
                                                                                                                                    stacked: false,
                                                                                                                                },
                                                                                                                                y: {
                                                                                                                                    stacked: false,
                                                                                                                                },
                                                                                                                                xAxes: [{
                                                                                                                                    ticks: {
                                                                                                                                        fontSize: 8
                                                                                                                                    }
                                                                                                                                }],
                                                                                                                                yAxes: [{
                                                                                                                                    ticks: {
                                                                                                                                        fontSize: 8,
                                                                                                                                        beginAtZero: true,
                                                                                                                                        callback: function (value, index, values) {
                                                                                                                                            return nFormatter(parseInt(value));
                                                                                                                                            function nFormatter(num) {
                                                                                                                                                if (num >= 1000000000) {
                                                                                                                                                    num = (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
                                                                                                                                                }
                                                                                                                                                if (num >= 1000000) {
                                                                                                                                                    num = (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
                                                                                                                                                }
                                                                                                                                                if (num >= 1000) {
                                                                                                                                                    num = (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
                                                                                                                                                }
                                                                                                                                                return "AED" + " " + num;
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                }],
                                                                                                                            },
                                                                                                                        }}
                                                                                                                        data={this.functions.barChartPriceOverTime(agent)}
                                                                                                                    />
                                                                                                                </div>
                                                                                                            </dl>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                            {
                                                                                                !(this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value == 'enabled') &&
                                                                                                <div className="px-4 py-5 sm:px-6 border-b border-gray-100 border-t">
                                                                                                    <h2 className="text-sm font-medium leading-6 text-gray-900">Actions</h2>
                                                                                                    <p className="mt-1 max-w-2xl text-sm text-gray-500">Sorted by created date</p>
                                                                                                </div>
                                                                                            }
                                                                                            {
                                                                                                !(this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value == 'enabled') &&
                                                                                                Array.isArray(agent.list_actions) &&
                                                                                                <div className="flex flex-1 flex-col overflow-y-scroll">
                                                                                                    {
                                                                                                        agent.list_actions.map((item) => {
                                                                                                            return (
                                                                                                                <div className="w-full grid grid-cols-7 gap-4 border-b border-gray-100 py-4 px-6">
                                                                                                                    <div className="col-span-1">
                                                                                                                        <div className="text-sm font-medium text-gray-500">
                                                                                                                            Created
                                                                                                                        </div>
                                                                                                                        <div className="mt-1 text-sm text-gray-900">
                                                                                                                            {moment(item.createdAt).format("YYYY-MM-DD hh:mm A")}
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className="col-span-1">
                                                                                                                        <div className="text-sm font-medium text-gray-500">
                                                                                                                            Type
                                                                                                                        </div>
                                                                                                                        <div className={(item.type ? (item.type.name === "Double-qualified" ? "text-green-500" : "text-purple-500") : "text-gray-500") + " text-sm mt-1"}>
                                                                                                                            {item.type ? item.type.name : "-"}
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className="col-span-2">
                                                                                                                        <div className="text-sm font-medium text-gray-500">
                                                                                                                            Event
                                                                                                                        </div>
                                                                                                                        <div className="mt-1 text-sm text-gray-900">
                                                                                                                            {item.information !== "" ? item.information : "..."}
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className="col-span-1">
                                                                                                                        <div className="text-sm font-medium text-gray-500">
                                                                                                                            From
                                                                                                                        </div>
                                                                                                                        <div className="mt-1 text-sm text-gray-900">
                                                                                                                            {item.channel !== "" ? item.channel === "facebook" && (this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value == 'enabled') ? "meta" : item.channel : "Internal"}
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className="col-span-1">
                                                                                                                        <div className="text-sm font-medium text-gray-500">
                                                                                                                            Information
                                                                                                                        </div>
                                                                                                                        <div className="mt-1 text-sm text-gray-900">
                                                                                                                            {item.extraInformation !== "" ? item.extraInformation : "..."}
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className="col-span-1">
                                                                                                                        <div className="text-sm font-medium text-gray-500">
                                                                                                                            Other
                                                                                                                        </div>
                                                                                                                        <div className="mt-1 text-sm text-gray-900">
                                                                                                                            {item.otherInformation !== "" ? item.otherInformation : "..."}
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className="col-span-1">
                                                                                                                        <div className="text-sm font-medium text-gray-500">
                                                                                                                            Feed ID
                                                                                                                        </div>
                                                                                                                        <div className="mt-1 text-sm text-gray-900">
                                                                                                                            {item.feedId !== "" ? item.feedId : "..."}
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className="col-span-1">
                                                                                                                        <div className="text-sm font-medium text-gray-500">
                                                                                                                            Device OS
                                                                                                                        </div>
                                                                                                                        <div className="mt-1 text-sm text-gray-900">
                                                                                                                            {item.deviceOS !== "" ? item.deviceOS : "..."}
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className="col-span-1">
                                                                                                                        <div className="text-sm font-medium text-gray-500">
                                                                                                                            Device browser
                                                                                                                        </div>
                                                                                                                        <div className="mt-1 text-sm text-gray-900">
                                                                                                                            {item.deviceBrowser !== "" ? item.deviceBrowser : "..."}
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className="col-span-1">
                                                                                                                        <div className="text-sm font-medium text-gray-500">
                                                                                                                            Device IP
                                                                                                                        </div>
                                                                                                                        <div className="mt-1 text-sm text-gray-900">
                                                                                                                            {item.deviceIp !== "" ? item.deviceIp : "..."}
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className="col-span-1">
                                                                                                                        <div className="text-sm font-medium text-gray-500">
                                                                                                                            Device country
                                                                                                                        </div>
                                                                                                                        <div className="mt-1 text-sm text-gray-900">
                                                                                                                            {item.deviceCountry !== "" ? item.deviceCountry : "..."}
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className="col-span-1">
                                                                                                                        <div className="text-sm font-medium text-gray-500">
                                                                                                                            Device city
                                                                                                                        </div>
                                                                                                                        <div className="mt-1 text-sm text-gray-900">
                                                                                                                            {item.deviceCity !== "" ? item.deviceCity : "..."}
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className="col-span-7">
                                                                                                                        <div className="text-sm font-medium text-gray-500">
                                                                                                                            Click ID
                                                                                                                        </div>
                                                                                                                        <div className="mt-1 text-sm text-gray-900">
                                                                                                                            {item.clickId !== "" ? item.clickId : "..."}
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                </div>
                                                                                            }
                                                                                            {
                                                                                                !(this.state.account.enterprise && this.state.account.enterprise.legacy && this.state.account.enterprise.legacy.value == 'enabled') &&
                                                                                                <div className="border-t text-xs text-gray-400 font-medium w-full p-4 justify-center flex items-center border-gray-100">
                                                                                                    scroll
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                    </div>

                                                                                    {
                                                                                        agent.loading && agent.open &&
                                                                                        <div className="absolute top-0 bottom-0 left-0 right-0 z-10 flex justify-center items-center bg-white bg-opacity-50">
                                                                                            <div style={{ borderTopColor: "transparent" }}
                                                                                                class="w-8 h-8 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </tr>
                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            {
                                                this.state.loading_users &&
                                                <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-white bg-opacity-50">
                                                    <div style={{ borderTopColor: "transparent" }}
                                                        class="w-12 h-12 border-2 border-indigo-500 absolute border-solid rounded-full animate-spin"></div>
                                                </div>
                                            }
                                        </div>
                                    }
                                    {
                                        this.state.users.length > 0 &&
                                        <div className="rounded-b-lg">
                                            <nav
                                                className="py-4 flex items-center justify-between px-4"
                                                aria-label="Pagination"
                                            >
                                                {
                                                    <div className="hidden sm:block">
                                                        <p className="text-gray-700 text-sm">
                                                            Showing <span className="font-medium">{((this.state.page * this.state.page_count) - this.state.page_count) + 1}</span> to <span className="font-medium">{(this.state.page * this.state.page_count) > this.state[this.state.tab.value] ? this.state[this.state.tab.value] : (this.state.page * this.state.page_count)}</span> of{' '}
                                                            <span className="font-medium">{this.state[this.state.tab.value]}</span> results
                                                        </p>
                                                    </div>
                                                }
                                                <div className="flex-1 flex flex-row">
                                                    <div className="flex-1 sm:justify-end flex">
                                                        <Menu as="div" className="relative inline-block text-left mr-2">
                                                            <div>
                                                                <Menu.Button className={(this.state.sequence_loading ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " inline-flex relative justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                                    Page limit: {this.state.page_count}
                                                                    <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                                                                </Menu.Button>
                                                            </div>
                                                            <Transition
                                                                as={Fragment}
                                                                enter="transition ease-out duration-100"
                                                                enterFrom="transform opacity-0 scale-95"
                                                                enterTo="transform opacity-100 scale-100"
                                                                leave="transition ease-in duration-75"
                                                                leaveFrom="transform opacity-100 scale-100"
                                                                leaveTo="transform opacity-0 scale-95"
                                                            >
                                                                <Menu.Items className="border absolute z-50 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                    <div className="py-1">
                                                                        {
                                                                            [5, 10, 20, 30, 50, 100].map((item) => {
                                                                                return (
                                                                                    <Menu.Item>
                                                                                        {({ active }) => (
                                                                                            <div
                                                                                                onClick={() => {
                                                                                                    this.setState({
                                                                                                        page_count: item,
                                                                                                        page: 1
                                                                                                    }, () => {
                                                                                                        window.scrollTo({ top: 0, behavior: 'smooth' });
                                                                                                        this.functions.getLeads();
                                                                                                    })
                                                                                                }}
                                                                                                className={classNames(
                                                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                    'px-4 py-2 text-sm relative flex flex-row cursor-pointer',
                                                                                                    this.state.page_count == item ? "text-purple-500" : ""
                                                                                                )}
                                                                                            >
                                                                                                {item}
                                                                                            </div>
                                                                                        )}
                                                                                    </Menu.Item>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </Menu.Items>
                                                            </Transition>
                                                        </Menu>
                                                    </div>
                                                    <div
                                                        onClick={() => {
                                                            if (this.state.page !== 1) {
                                                                this.setState({
                                                                    select_all: false,
                                                                    page: this.state.page > 1 ? (this.state.page - 1) : 1
                                                                }, () => {
                                                                    window.scrollTo({ top: 0, behavior: 'smooth' });
                                                                    this.functions.getLeads();
                                                                });
                                                            }
                                                        }}
                                                        className={(this.state.page === 1 ? "cursor-not-allowed opacity-50" : "cursor-pointer hover:bg-gray-50") + " relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white "}
                                                    >
                                                        Previous
                                                    </div>
                                                    <div
                                                        onClick={() => {
                                                            if (((this.state.page) * this.state.page_count) < this.state[this.state.tab.value]) {
                                                                this.setState({
                                                                    select_all: false,
                                                                    page: this.state.page + 1
                                                                }, () => {
                                                                    window.scrollTo({ top: 0, behavior: 'smooth' });
                                                                    this.functions.getLeads();
                                                                });
                                                            }
                                                        }}
                                                        className={(((this.state.page) * this.state.page_count) >= this.state[this.state.tab.value] ? "cursor-not-allowed opacity-50" : "cursor-pointer hover:bg-gray-50") + " ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white "}
                                                    >
                                                        Next
                                                    </div>
                                                </div>
                                            </nav>
                                        </div>
                                    }
                                </section>
                            }
                        </div>
                    </div>
                }

                {
                    <div className="w-full h-10 hidden sm:block"></div>
                }

            </div >
        )
    }
}

export default ListLeadsd;
