class apiRegister {

    static url = {
        api: window.location.hostname === 'localhost' ? "http://localhost:5000" : "https://enterprise.adbooster.se",
        //proptree: window.location.hostname === 'localhost' ? "http://localhost:5003" : "https://api.proptr.ee"
        proptree:"https://api.proptr.ee"
    };

    static call(options, url) {
        return new Promise(function (resolve, reject) {
            fetch(url, options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                }
                return response.json().then(json => {
                    return Promise.reject({
                        status: response.status,
                        ok: false,
                        statusText: response.statusText,
                        body: json
                    });
                });
            })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch((error) => {
                    if (!error.body) {
                        reject({ body: { message: 'Something went wrong, Api register' } });
                    } else {
                        reject(error);
                    }
                });
        });
    }

    static options(token, type, data, multiform) {
        let options = {
            method: type,
            timeout: 1200000,
            headers: {
                'Content-Type': 'application/json'
            }
        };
        if (token) {
            options.headers['x-auth-token'] = token;
        }
        if (data) {
            options.body = JSON.stringify(data);
        }
        if (multiform) {
            options.body = data;
            delete options.headers['Content-Type'];
        }
        return options;
    }

}

export { apiRegister }