import React, { Component, Fragment } from 'react';
import { XCircleIcon, XIcon, ChevronDownIcon, ChevronUpIcon, ArrowLeftIcon, ArrowRightIcon, SearchIcon, PencilAltIcon, PaperAirplaneIcon, BookmarkIcon, UsersIcon, PlusIcon, MinusIcon, CheckCircleIcon, MenuAlt3Icon, MenuAlt1Icon, MenuAlt2Icon } from '@heroicons/react/outline'
import SignUpModal from '../components/signUpModal';
import Dropdown from '../components/dropdown';

class ProptreeSignUp extends Component {

    constructor(props) {
        super(props);
        this.state = {
            crm: {},
            faq: [
                { title: "How do I use the AI search functionality, and how many searches are included in each plan per month?", text: "Using Talentium's AI search functionality is incredibly intuitive. Just enter your search criteria, such as machine learning engineer with PyTorch experience in Stockholm, and let our advanced AI handle the rest.", open: false },
                { title: "Is my data safe with Talentium?", text: "Talentium taps into a vast network of over 4,000 databases to bring you the most accurate and comprehensive results possible. All of these powerful features are seamlessly integrated into the Talentium platform, giving you a competitive edge in your recruitment efforts", open: false },
                { title: "Can we import data from our existing recruitment platform into Talentium?", text: "Absolutely! You can easily migrate data from your current recruitment platform into Talentium. Our team is here to help make the transition smooth and seamless, so you can start enjoying the benefits of Talentium right away. If you have any questions or need assistance, we're ready to support you every step of the way!", open: false },
                { title: "How is Talentium different from other ATS platforms?", text: "Talentium truly sets itself apart from other ATS platforms with its unique combination of advanced features. Our cutting-edge AI search capabilities help you identify the perfect candidates quickly and efficiently. But that's not all—we offer a complete end-to-end solution for your entire recruitment process, from calendar management to outreach with customizable sequences and templates, and even seamless job pipeline creation and management. Talentium is designed to streamline your hiring process, saving you time and resources while maximizing your recruiting success!", open: false }
            ]
        }
    };

    componentWillMount() { }

    componentDidMount() { }

    functions = {};

    renders = {};

    calls = {};

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    };

    render() {

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        return (
            <div className="w-full">

                { /* TOP MENU */}
                <div className="sticky top-0 z-40 flex h-16 md:h-24 w-full justify-center border-b border-gray-200 backdrop-blur-md backdrop-filter transition-transform duration-200 ease-linear sm:fixed bg-white bg-opacity-50">
                    <div className="relative my-auto flex w-full max-w-160 items-center justify-between px-4 lg:px-6">
                        <div className="hidden md:block">
                            <img className="w-6 md:w-8" src={require("../assets/images/proptree_test_logo.svg")} />
                        </div>
                        <div class="flex md:hidden items-center gap-4">
                            <img class="w-6" src={require("../assets/images/proptree_test_logo.svg")} />
                            <p class="text-lg font-bold text-off_black">Talentium</p>
                        </div>
                        <div className="flex flex-1"></div>
                        <div className="hidden md:flex">
                            {
                                [
                                    { title: "Company" },
                                    { title: "Products" },
                                    { title: "Security" },
                                    { title: "Pricing" },
                                    { title: "Book a demo" }
                                ].map((item) => {
                                    return (
                                        <div className="text-base font-medium cursor-pointer text-gray-900 rounded-md px-4 py-2 mx-4 ">
                                            <div className="">{item.title}</div>
                                        </div>
                                    )
                                })
                            }
                            <div>
                                <button className="rounded-full border border-gray-500 text-gray-700 px-6 py-2 text-base font-medium">
                                    Log In
                                </button>
                            </div>
                        </div>
                        <div className="block md:hidden">
                            <MenuAlt3Icon className="w-7 cursor-pointer" />
                        </div>
                    </div>
                </div>

                <div className="mx-auto mt-8 md:mt-32 flex w-full max-w-160 flex-col p-5 gap-20 lg:gap-36">

                    {/* TEXT SECTION */}
                    <div className="flex justify-center px-4 sm:px-6 md:px-10 lg:px-20">
                        <div style={{ lineHeight: "1.1", opacity: 1 }} className="flex text-2xl font-semibold lg:text-5xl flex-wrap items-center justify-center gap-0.5 text-center">
                            The automated AI recruitment search engine that finds the your looking for in seconds. Built for recruiters.
                        </div>
                    </div>

                    {/* SIGN UP SECTION */}
                    <div className="mx-auto w-full max-w-120">
                        <div className="relative signup-gradient p-8 w-full rounded-3xl">
                            <div className="w-full mb-8">
                                <div className="mb-2 text-sm font-medium text-white">
                                    Your name
                                </div>
                                <input
                                    type="text"
                                    onChange={(event) => {
                                        this.setState({
                                            name: event.target.value
                                        })
                                    }}
                                    placeholder={"James Bond"}
                                    disabled={this.state.loading}
                                    value={this.state.name}
                                    required
                                    style={{ fontSize: "1.1rem" }}
                                    className={(this.state.error ? "border-red-300 focus:ring-red-500 focus:border-red-500" : "border-gray-300 focus:ring-indigo-500 focus:border-indigo-500") + " appearance-none block h-12 w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none"}
                                />
                            </div>
                            <div className="w-full mb-8">
                                <div className="mb-2 text-sm font-medium text-white">
                                    Email
                                </div>
                                <input
                                    type="email"
                                    onChange={(event) => {
                                        this.setState({
                                            email: event.target.value
                                        })
                                    }}
                                    placeholder={"email@domain.com"}
                                    disabled={this.state.loading}
                                    value={this.state.email}
                                    required
                                    style={{ fontSize: "1.1rem" }}
                                    className={(this.state.error ? "border-red-300 focus:ring-red-500 focus:border-red-500" : "border-gray-300 focus:ring-indigo-500 focus:border-indigo-500") + " appearance-none block h-12 w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none"}
                                />
                            </div>
                            <div className="w-full mb-8">
                                <div className="mb-2 text-sm font-medium text-white">
                                    Phone number
                                </div>
                                <input
                                    type="phone"
                                    onChange={(event) => {
                                        this.setState({
                                            phone: event.target.value
                                        })
                                    }}
                                    placeholder={"+971 1233 12333"}
                                    disabled={this.state.loading}
                                    value={this.state.phone}
                                    required
                                    style={{ fontSize: "1.1rem" }}
                                    className={(this.state.error ? "border-red-300 focus:ring-red-500 focus:border-red-500" : "border-gray-300 focus:ring-indigo-500 focus:border-indigo-500") + " appearance-none block h-12 w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none"}
                                />
                            </div>
                            <div className="w-full mb-8">
                                <div className="mb-2 text-sm font-medium text-white">
                                    Company name
                                </div>
                                <input
                                    type="text"
                                    onChange={(event) => {
                                        this.setState({
                                            company: event.target.value
                                        })
                                    }}
                                    placeholder={"Dubai Real Estate LLC"}
                                    disabled={this.state.loading}
                                    value={this.state.company}
                                    required
                                    style={{ fontSize: "1.1rem" }}
                                    className={(this.state.error ? "border-red-300 focus:ring-red-500 focus:border-red-500" : "border-gray-300 focus:ring-indigo-500 focus:border-indigo-500") + " appearance-none block h-12 w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none"}
                                />
                            </div>
                            <div className="w-full mb-8">
                                <div className="mb-2 text-sm font-medium text-white">
                                    CRM system
                                </div>
                                <Dropdown
                                    proptreeLogin={true}
                                    selected={this.state.crm}
                                    options={[
                                        { id: 1, name: "PropSpace", value: "propspace" },
                                        { id: 2, name: "Pixxi", value: "pixxi" },
                                        { id: 3, name: "Something else", value: "something_else" }
                                    ]}
                                    onChange={(item) => {
                                        this.setState({
                                            crm: item
                                        })
                                    }}
                                />
                            </div>
                            {
                                this.state.crm && this.state.crm.value == "something_else" &&
                                <div className="w-full mb-8">
                                    <div className="mb-2 text-sm font-medium text-white">
                                        CRM name
                                    </div>
                                    <input
                                        type="text"
                                        onChange={(event) => {
                                            this.setState({
                                                crm_name: event.target.value
                                            })
                                        }}
                                        placeholder={"CRM name"}
                                        disabled={this.state.loading}
                                        value={this.state.crm_name}
                                        required
                                        style={{ fontSize: "1.1rem" }}
                                        className={(this.state.error ? "border-red-300 focus:ring-red-500 focus:border-red-500" : "border-gray-300 focus:ring-indigo-500 focus:border-indigo-500") + " appearance-none block h-12 w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none"}
                                    />
                                </div>
                            }
                            <div className="w-full">
                                <button onClick={() => { this.props.history.push("/sign-up/proptree"); }} class="inline-flex items-center justify-center w-full text-white rounded-full bg-purple-500 hover:bg-purple-600 px-6 py-3 text-base font-medium" type="button">
                                    Book a demo
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* FAQ */}
                    <div className="">
                        <div className="flex w-full flex-col gap-16 text-center">
                            <div className="flex text-2xl font-semibold lg:text-5xl flex-wrap items-center justify-center gap-0.5 text-center">
                                Frequently asked questions
                            </div>
                        </div>
                        {
                            this.state.faq.map((item, index) => {
                                return (
                                    <div className={(index == 0 ? "mt-16 border-t" : "") + " flex flex-col border-b"}>
                                        <div className="w-full my-7">
                                            <div onClick={() => {
                                                item.open = !item.open;
                                                this.setState({
                                                    faq: this.state.faq
                                                });
                                            }} className="flex w-full items-center justify-center cursor-pointer">
                                                <div className="font-medium flex flex-1 text-gray-900">{item.title}</div>
                                                {
                                                    !item.open &&
                                                    <PlusIcon className="w-5" />
                                                }
                                                {
                                                    item.open &&
                                                    <MinusIcon className="w-5" />
                                                }
                                            </div>
                                            <div className={(item.open ? "pt-7" : "h-0") + " grid overflow-hidden h-fit gap-2.5 font-normal text-gray-800"}>
                                                <p>{item.text}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>

                </div>

                {/* FOOTER SECTION */}
                <div class="w-full mt-16 border-0 border-t border-gray-200">
                    <div class="mx-auto mt-auto flex w-full max-w-160 flex-col gap-12 p-5">
                        <div class="my-auto flex w-full flex-col items-center justify-between gap-8 lg:flex-row lg:justify-center">
                            <a class="m-auto flex gap-4 lg:m-0" href="/">
                                <div class="flex items-center gap-4">
                                    <img className="w-8" src={require("../assets/images/proptree_test_logo.svg")} />
                                    <p class="text-3xl font-bold text-off_black">Talentium</p>
                                </div>
                            </a>
                            <div class="flex gap-4 lg:justify-end flex-1 md:gap-6">
                                <a href="/legal">
                                    <p class="text-base font-normal leading-normal text-f-gray-950">Legal</p>
                                </a><a href="/opt-out">
                                    <p class="text-base font-normal leading-normal text-f-gray-950">Opt out</p>
                                </a><a href="/cookie-declaration">
                                    <p class="text-base font-normal leading-normal text-f-gray-950">Cookie Declaration</p>
                                </a>
                            </div>
                        </div>
                        <div class="md:mt-20 flex w-full justify-center gap-8 md:justify-between">
                            <p class="text-center text-xs font-normal text-f-gray-800">© 2023 Talentium. All rights reserved. Stockholm, SE 14:56</p>
                            <p class="hidden text-center text-xs font-normal text-f-gray-800 md:inline">info@talentium.io</p>
                        </div>
                    </div>
                </div>

            </div >
        )
    }
}

export default ProptreeSignUp;
