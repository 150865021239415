import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { XCircleIcon, XIcon } from '@heroicons/react/outline'
import { LinkIcon, PlusSmIcon, QuestionMarkCircleIcon } from '@heroicons/react/solid'
import SingleSelection from './singleSelection';
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import * as XLSX from 'xlsx';
import { sha256 } from 'js-sha256';

class CreateAudience extends Component {

    constructor(props) {
        super(props);
        this.state = {
            encrypt: true,
            open: false,
            audience: {},
            message: "",
            limit: 20
        }
    };

    componentDidMount() {
        this.setState({
            encrypt: this.props.encrypt
        })
    }

    componentWillReceiveProps(nextProps) {
        if (!this.state.open && nextProps.open) {
            this.state.audience = (nextProps.audience ? nextProps.audience : {
                defaultCountryCode: { id: 1, name: 'UAE (+971)', code: '971' }
            });
        }
        this.setState({
            audience: this.state.audience,
            encrypt: nextProps.encrypt,
            open: nextProps.open
        })
    }

    functions = {
        onSelectFile: (event) => {
            let self = this;
            let json = null;
            event.preventDefault();
            var files = event.target.files, f = files[0];
            var reader = new FileReader();
            reader.onload = function (e) {
                var data = e.target.result;
                let readedData = XLSX.read(data, { type: 'binary' });
                const wsname = readedData.SheetNames[0];
                const ws = readedData.Sheets[wsname];
                const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
                json = dataParse;
            };
            reader.onloadend = async function (e) {
                self.functions.parseExcel(json);
            };
            reader.readAsBinaryString(f);
        },
        parseExcel: async (json) => {
            let encrypt = this.state.encrypt;
            let self = this;
            this.state.audience.headers = JSON.parse(JSON.stringify(json))[0].map((item) => { return item.toLowerCase() });
            this.state.audience.headers = this.state.audience.headers.filter((item) => { return item === 'email' || item === 'phone' });
            await this.promisedSetState({
                audience: self.state.audience
            });
            this.state.audience.rows = JSON.parse(JSON.stringify(json)).filter((item, index) => {
                return index > 0
            }).map((item) => {
                let new_row = {};
                this.state.audience.headers.map((inner_item, index) => {
                    if (inner_item === "email" && item[index]) {
                        new_row.email = this.renders.formatEmail(item[index], encrypt);
                    } else if (inner_item === "phone" && item[index]) {
                        new_row.phone = this.renders.formatPhone(item[index], encrypt);
                    }
                });
                return new_row;
            });
            this.state.audience.rows = this.state.audience.rows.filter((row) => {
                return row.email || row.phone;
            });
            await this.promisedSetState({
                audience: this.state.audience
            });
        },
        create: async () => {
            this.state.audience.encrypted = this.state.encrypt;
            try {
                await this.promisedSetState({ loading: true, error: false });
                let response = await this.calls.create(this.state.audience);
                await this.promisedSetState({ loading: false });
                this.props.toggle();
                if (this.props.onAudience) {
                    this.props.onAudience(response.data);
                }
            } catch (error) {
                await this.promisedSetState({
                    error: error.body ? error.body.message : "Something went wrong",
                    loading: false
                });
            }
        },
        update: async () => {
            try {
                await this.promisedSetState({ loading: true });
                let response = await this.calls.update({ name: this.state.audience.name });
                await this.promisedSetState({ loading: false });
                this.props.toggle();
                if (this.props.onAudience) {
                    this.props.onAudience(response.data);
                }
            } catch (error) {
                await this.promisedSetState({
                    error: error.body ? error.body.message : "Something went wrong",
                    loading: false
                });
            }
        }
    }

    calls = {
        create: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/audience/createAudience";
            return apiRegister.call(options, url);
        },
        update: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/audience/updateAudience?id=" + this.state.audience.id;
            return apiRegister.call(options, url);
        }
    };

    renders = {
        formatEmail: (email, encrypt) => {
            try {
                email = email.trim();
                email = email.replace(/\s/g, '');
                email = email.toLowerCase();
            } catch (error) {
                return "error format";
            }
            if (encrypt) {
                email = sha256(email);
            }
            return email;
        },
        formatPhone: (phone, encrypt) => {
            let phone_code = this.state.audience.defaultCountryCode ? this.state.audience.defaultCountryCode.code : "";
            try {
                if (typeof phone === "number") {
                    phone = JSON.stringify(phone);
                }
                phone = phone.trim();
                phone = phone.replace(/\s/g, '');
                phone = phone.replace(/\D+/g, '');
                if (phone.startsWith(phone_code + '0')) {
                    let phone_code_length = phone_code.length;
                    phone = phone.substring(phone_code_length + 1);
                    phone = '+' + phone_code + phone;
                } else if (phone.startsWith(phone_code)) {
                    phone = '+' + phone;
                } else if (phone.startsWith('00' + phone_code)) {
                    phone = phone.substring(1);
                    phone = phone.substring(1);
                    phone = '+' + phone;
                } else if (phone.startsWith('0' + phone_code)) {
                    phone = phone.substring(1);
                    phone = '+' + phone;
                } else if (phone.startsWith('0')) {
                    phone = phone.substring(1);
                    phone = '+' + phone_code + phone;
                } else {
                    phone = '+' + phone_code + phone;
                }
            } catch (error) {
                console.log(error);
                return "error format";
            }
            if (encrypt) {
                phone = sha256(phone);
            }
            return phone;
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    };

    render() {
        return (
            <Transition.Root show={this.state.open} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 overflow-hidden z-60" onClose={() => {

                }}>
                    <div className="absolute inset-0 overflow-hidden">

                        <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-50" />

                        <div className="fixed inset-y-0 pl-16 max-w-full right-0 flex p-5">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <div className="w-screen max-w-4xl rounded-lg overflow-hidden">
                                    <div className="h-full flex flex-col bg-white shadow-xl">
                                        <div className="py-5 pb-2 bg-white px-6">
                                            <div className="flex items-center justify-between">
                                                <Dialog.Title className="text-xl font-bold text-gray-700">{this.state.audience.id ? "Update Audience" : "New Audience"}</Dialog.Title>
                                                <div className="ml-3 h-10 flex items-center">
                                                    <button
                                                        type="button"
                                                        className="rounded-full h-12 w-12 flex items-center justify-center text-red-500 bg-red-100 hover:text-red-600 focus:outline-none focus:ring-2 focus:ring-red"
                                                        onClick={() => {
                                                            this.props.toggle();
                                                        }}
                                                    >
                                                        <span className="sr-only">Close panel</span>
                                                        <XIcon className="h-6 w-6" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-1 h-0 overflow-y-auto pt-6">
                                            <div className="grid grid-cols-6 gap-4 px-6">
                                                <div className="col-span-3">
                                                    <div>
                                                        <label htmlFor="audience-name" className="block text-sm font-medium text-gray-900">
                                                            Name
                                                        </label>
                                                        <div className="mt-1">
                                                            <input
                                                                onChange={(event) => {
                                                                    this.state.audience.name = event.target.value
                                                                    this.setState({
                                                                        audience: this.state.audience
                                                                    })
                                                                }}
                                                                value={this.state.audience.name}
                                                                type="text"
                                                                name="audience-name"
                                                                id="audience-name"
                                                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-span-3">
                                                    <div>
                                                        <label htmlFor="audience-phone-code" className="block text-sm font-medium text-gray-900">
                                                            Default country code
                                                        </label>
                                                        <div className="mt-1">
                                                            <SingleSelection
                                                                select={(option) => {
                                                                    this.state.audience.phone_code = option;
                                                                    this.setState({
                                                                        audience: this.state.audience
                                                                    })
                                                                }}
                                                                disabled={this.state.audience.id}
                                                                locked={this.state.audience.id}
                                                                selected={this.state.audience.defaultCountryCode ? this.state.audience.defaultCountryCode : { id: 0, name: "Select code" }}
                                                                options={[
                                                                    { id: 1, name: "UAE (+971)", code: "971" },
                                                                    { id: 3, name: "Sweden (+46)", code: "46" },
                                                                    { id: 2, name: "UK (+44)", code: "44" }

                                                                ]}
                                                                name="audience-phone-code"
                                                                id="audience-phone-code"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                !this.state.audience.id &&
                                                <div className="flex-1 flex px-6 pt-6">
                                                    <div className={"w-full"}>
                                                        <div key={this.state.theInputKeyOne || ''} className="flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md relative bg-custom-input">
                                                            <input
                                                                onChange={(e) => this.functions.onSelectFile(e)}
                                                                type="file"
                                                                accept={".xlsx, .xls"}
                                                                className="absolute top-0 left-0 w-full h-full cursor-pointer opacity-0"
                                                            />
                                                            <div className="space-y-1 text-center">
                                                                <svg
                                                                    className="mx-auto h-12 w-12 text-gray-400"
                                                                    stroke="currentColor"
                                                                    fill="none"
                                                                    viewBox="0 0 48 48"
                                                                    aria-hidden="true"
                                                                >
                                                                    <path
                                                                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                                        strokeWidth={2}
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    />
                                                                </svg>
                                                                <div className="flex text-sm text-gray-600">
                                                                    <span className="relative cursor-pointer whitespace-no-wrap bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">Upload a file</span>
                                                                    <p className="pl-1">or drag and drop</p>
                                                                </div>
                                                                <p className="text-xs text-gray-500">XLSX or XLS</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                Array.isArray(this.state.audience.headers) && Array.isArray(this.state.audience.rows) &&
                                                <div id={"table"} className="px-6 pt-6">
                                                    <div className="overflow-scroll border rounded-md">
                                                        <div className="align-middle inline-block min-w-full">
                                                            <div className="">
                                                                <table className="min-w-full divide-y divide-gray-200">
                                                                    <thead className="bg-gray-50">
                                                                        <tr>
                                                                            {
                                                                                this.state.audience.headers.map((item) => {
                                                                                    return (
                                                                                        <th scope="col" className="px-4 py-4 text-left text-xxs font-semibold text-gray-900">
                                                                                            {item}
                                                                                        </th>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className="divide-y divide-gray-200 bg-white">
                                                                        {this.state.audience.rows.filter((item, index) => {
                                                                            return index < this.state.limit
                                                                        }).map((row, index) => {
                                                                            return (
                                                                                <tr key={index}>
                                                                                    {
                                                                                        this.state.audience.headers.map((header) => {
                                                                                            return (
                                                                                                <td className="whitespace-nowrap text-left px-4 py-4 text-xxs text-gray-500">{row[header]}</td>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </tr>
                                                                            )
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            }
                                            {
                                                Array.isArray(this.state.audience.rows) &&
                                                this.state.audience.rows.length > this.state.limit &&
                                                <div onClick={() => {
                                                    this.setState({
                                                        limit: this.state.limit + 20
                                                    });
                                                }} className="text-center pt-4 text-sm font-medium text-purple-500 cursor-pointer hover:text-purple-700">
                                                    Load more ({this.state.limit}/{this.state.audience.rows.length})
                                                </div>
                                            }
                                            {
                                                this.state.error &&
                                                <div className="px-6 pb-6">
                                                    <div className="rounded-md bg-red-100 bg-opacity-50 p-4">
                                                        <div className="flex">
                                                            <div className="flex-shrink-0">
                                                                <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                                                            </div>
                                                            <div className="ml-3">
                                                                <h3 className="text-sm font-medium text-red-800">There were some errors</h3>
                                                                <div className="mt-2 text-sm text-red-700">
                                                                    <ul role="list" className="list-disc pl-5 space-y-1">
                                                                        <li>{this.state.error}</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className="flex-shrink-0 px-6 py-4 flex justify-end">
                                            <button
                                                onClick={() => {
                                                    if (this.state.audience.id) {
                                                        this.functions.update();
                                                    } else {
                                                        this.functions.create();
                                                    }
                                                }}
                                                className="ml-4 inline-flex relative justify-center py-2 px-10 lg:px-20 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-purple-500 hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                                            >
                                                {
                                                    this.state.loading &&
                                                    <div className="w-full h-full absolute bg-purple-500 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                        <div style={{ borderTopColor: "transparent" }}
                                                            class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                    </div>
                                                }
                                                {
                                                    !this.state.audience.id &&
                                                    <span>Create</span>
                                                }
                                                {
                                                    this.state.audience.id &&
                                                    <span>Update</span>
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog >
            </Transition.Root >
        )
    }
}

export default CreateAudience;
