import React, { Component, Fragment } from 'react';
import { XCircleIcon, XIcon, ChevronDownIcon, ChevronUpIcon, ArrowLeftIcon, ArrowRightIcon, SearchIcon, PencilAltIcon, PaperAirplaneIcon, BookmarkIcon, UsersIcon, PlusIcon, MinusIcon, CheckCircleIcon, MenuAlt3Icon, MenuAlt1Icon, MenuAlt2Icon } from '@heroicons/react/outline'
import SignUpModal from '../components/signUpModal';

class ProptreeHome extends Component {

    constructor(props) {
        super(props);
        this.state = {
            faq: [
                { title: "How do I use the AI search functionality, and how many searches are included in each plan per month?", text: "Using Talentium's AI search functionality is incredibly intuitive. Just enter your search criteria, such as machine learning engineer with PyTorch experience in Stockholm, and let our advanced AI handle the rest.", open: false },
                { title: "Is my data safe with Talentium?", text: "Talentium taps into a vast network of over 4,000 databases to bring you the most accurate and comprehensive results possible. All of these powerful features are seamlessly integrated into the Talentium platform, giving you a competitive edge in your recruitment efforts", open: false },
                { title: "Can we import data from our existing recruitment platform into Talentium?", text: "Absolutely! You can easily migrate data from your current recruitment platform into Talentium. Our team is here to help make the transition smooth and seamless, so you can start enjoying the benefits of Talentium right away. If you have any questions or need assistance, we're ready to support you every step of the way!", open: false },
                { title: "How is Talentium different from other ATS platforms?", text: "Talentium truly sets itself apart from other ATS platforms with its unique combination of advanced features. Our cutting-edge AI search capabilities help you identify the perfect candidates quickly and efficiently. But that's not all—we offer a complete end-to-end solution for your entire recruitment process, from calendar management to outreach with customizable sequences and templates, and even seamless job pipeline creation and management. Talentium is designed to streamline your hiring process, saving you time and resources while maximizing your recruiting success!", open: false }
            ]
        }
    };

    componentWillMount() { }

    componentDidMount() { }

    functions = {};

    renders = {};

    calls = {};

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    };

    render() {

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        return (
            <div className="w-full">

                { /* TOP MENU */}
                <div className="sticky top-0 z-40 flex h-16 md:h-24 w-full justify-center border-b border-gray-200 backdrop-blur-md backdrop-filter transition-transform duration-200 ease-linear sm:fixed bg-white bg-opacity-50">
                    <div className="relative my-auto flex w-full max-w-160 items-center justify-between px-4 lg:px-6">
                        <div className="hidden md:block">
                            <img className="w-6 md:w-8" src={require("../assets/images/proptree_test_logo.svg")} />
                        </div>
                        <div class="flex md:hidden items-center gap-4">
                            <img class="w-6" src={require("../assets/images/proptree_test_logo.svg")} />
                            <p class="text-lg font-bold text-off_black">Talentium</p>
                        </div>
                        <div className="flex flex-1"></div>
                        <div className="hidden md:flex">
                            {
                                [
                                    { title: "Company" },
                                    { title: "Products" },
                                    { title: "Security" },
                                    { title: "Pricing" },
                                    { title: "Book a demo" }
                                ].map((item) => {
                                    return (
                                        <div className="text-base font-medium cursor-pointer text-gray-900 rounded-md px-4 py-2 mx-4 ">
                                            <div className="">{item.title}</div>
                                        </div>
                                    )
                                })
                            }
                            <div>
                                <button className="rounded-full border border-gray-500 text-gray-700 px-6 py-2 text-base font-medium">
                                    Log In
                                </button>
                            </div>
                        </div>
                        <div className="block md:hidden">
                            <MenuAlt3Icon className="w-7 cursor-pointer" />
                        </div>
                    </div>
                </div>

                <div className="mx-auto mt-8 md:mt-32 flex w-full max-w-160 flex-col p-5 gap-20 lg:gap-36">

                    {/* HERO SECTION */}
                    <div className="relative w-full overflow-hidden rounded-3xl bg-gray-100 h-115">

                    </div>

                    {/* TEXT SECTION */}
                    <div className="flex justify-center px-4 sm:px-6 md:px-10 lg:px-20">
                        <div style={{ lineHeight: "1.1", opacity: 1 }} className="flex text-2xl font-semibold lg:text-5xl flex-wrap items-center justify-center gap-0.5 text-center">
                            The automated AI recruitment search engine that finds the your looking for in seconds. Built for recruiters.
                        </div>
                    </div>

                    {/* EXPLAIN SECTIONS */}
                    <div className="flex flex-col gap-6">
                        {
                            [{}, {}, {}].map((item) => {
                                return (
                                    <div className="flex flex-col-reverse items-center justify-between gap-12 overflow-hidden rounded-3xl bg-gray-100 lg:flex-row">
                                        <div className="flex flex-shrink flex-col gap-4 self-start lg:gap-6 p-12">
                                            <div className="rounded-full hidden lg:block w-14 h-14 bg-purple-200"></div>
                                            <div className="text-2xl font-medium text-gray-900 lg:text-4xl">
                                                Find your next hire in seconds
                                            </div>
                                            <div className="text-base font-normal text-gray-600">
                                                Talentium analyzes millions of profiles from multiple open sources using our advanced search engine to find the best talent that matches your search criteria.
                                            </div>
                                            <div className="flex gap-2">
                                                <div>
                                                    <button onClick={() => { this.props.history.push("/signup/proptree"); }} class="inline-flex items-center justify-center rounded-full border border-purple-500 bg-purple-500 px-6 py-3 text-base font-medium text-white hover:bg-purple-600" type="button">
                                                        Get started
                                                    </button>
                                                </div>
                                                <div>
                                                    <button onClick={() => { this.props.history.push("/signup/proptree"); }} class="inline-flex items-center justify-center rounded-full border border-gray-500 text-gray-700 px-6 py-3 text-base font-medium" type="button">
                                                        Book a demo
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            false &&
                                            <div className="relative mx-auto flex-shrink-0 lg:ml-12">
                                                <img src={"https://talentium.io/assets/talentium_io_find_me_girl-Bz6tsZET.png"} className=" w-64" />
                                            </div>
                                        }
                                        <div className="h-full">
                                            <img src={"https://images.ctfassets.net/kftzwdyauwt9/7Dg0i0yinRGkIeqeVXig6i/20dc76142190c28809a5aa1b8ee7c8f6/4a.png?w=3840&q=80&fm=webp"} />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>

                    {/* EXPLAIN SECTIONS */}
                    <div className="">
                        <div className="flex w-full flex-col gap-16 text-center">
                            <div className="flex text-2xl font-semibold lg:text-5xl max-w-[72rem] flex-wrap items-center justify-center gap-0.5 text-center">
                                Here's what people are saying
                            </div>
                            <div className="flex flex-col md:flex-row justify-around gap-4 overflow-x-auto">
                                {
                                    [
                                        { text: "Maintaining a high-quality dialogue with relevant talent is crucial to our success, and Talentium has made it effortless. The platform not only streamlines communication but also helps us stay in control. On top of that, the ability to identify new, qualified talent within seconds is an incredible advantage." },
                                        { text: "When I saw Talentium's platform, I immediately understood that it was the tool of the future for HR and recruitment. With almost 15 years in the industry, I am convinced that the platform will save both time and money for everyone" },
                                        { text: "We are already (as of januari 10, 2024) benefiting from Talentium! The way of identifying candidates is amazing!" },
                                        { text: "Talentium is a game-changer for startups -quick hires, perfect fits, and scalable solutions." }
                                    ].map((item) => {
                                        return (
                                            <div className="flex flex-1 flex-col gap-8 rounded-3xl bg-gray-100 p-6 text-left">
                                                <div className="flex flex-col gap-4">
                                                    <img src={(require("../assets/images/proptree_quote.svg"))} className="h-8 w-8" />
                                                    <div className="text-base font-normal text-gray-600">
                                                        {item.text}
                                                    </div>
                                                </div>
                                                <div className="mt-auto flex flex-col gap-3">
                                                    <span className="relative flex shrink-0 overflow-hidden rounded-full h-12 w-12 border">
                                                        <img className="aspect-square h-full w-full" src={"https://talentium.io/assets/mathias-avatar-Ceu9cPUi.png"} />
                                                    </span>
                                                    <div>
                                                        <div className="font-medium text-f-gray-950">Mathias Wellström</div>
                                                        <div className="text-f-gray-800">Trib</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>

                    {/* PRICE */}
                    <div className="">
                        <div className="flex w-full flex-col gap-16 text-center">
                            <div className="flex text-2xl font-semibold lg:text-5xl flex-wrap items-center justify-center gap-0.5 text-center">
                                Plans {"&"} pricing
                            </div>
                        </div>
                        <div className="grid-cols-2 lg:grid-cols-10 grid gap-8 mt-16">
                            {
                                [
                                    { icon: SearchIcon, title: "People search", description: "Search and find talents you need in seconds." },
                                    { icon: PencilAltIcon, title: "AI notetaking", description: "Capture and organize talent discussions." },
                                    { icon: PaperAirplaneIcon, title: "Talent engagement", description: "We automate candidate engagement." },
                                    { icon: BookmarkIcon, title: "AI pipelines", description: "We automate job and pipeline creation." },
                                    { icon: UsersIcon, title: "Talent updates", description: "We automatically update your talent pool." },
                                ].map((item) => {
                                    return (
                                        <div class="flex flex-col text-center justify-center col-span-2 items-center gap-3">
                                            <item.icon className="w-7 mb-4" />
                                            <div class="text-base font-medium text-gray-950">{item.title}</div>
                                            <div class="text-base text-center font-normal text-black">{item.description}</div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="grid mt-16 w-full grid-cols-1 gap-5 lg:grid-cols-3">
                            {
                                [
                                    {
                                        title: "AI Search",
                                        price: "€189",
                                        description: "For teams who want to revolutionise their recruitment process",
                                        options: [
                                            { included: true, text: "AI powered search for recruitment" },
                                            { included: true, text: "Unlimited outreach" },
                                            { included: false, text: "Find direct contact info for hires" },
                                            { included: false, text: "Jobs and workflows created for you" },
                                            { included: false, text: "AI Sequence flow" },
                                            { included: false, text: "AI note-taking: records and summarizes" }
                                        ]
                                    },
                                    {
                                        title: "AI Search",
                                        price: "€189",
                                        description: "For teams who want to revolutionise their recruitment process",
                                        gradient: 'price-gradient-2',
                                        options: [
                                            { included: true, text: "AI powered search for recruitment" },
                                            { included: true, text: "Unlimited outreach" },
                                            { included: false, text: "Find direct contact info for hires" },
                                            { included: false, text: "Jobs and workflows created for you" },
                                            { included: false, text: "AI Sequence flow" },
                                            { included: false, text: "AI note-taking: records and summarizes" }
                                        ]
                                    },
                                    {
                                        title: "AI Search",
                                        price: "€189",
                                        gradient: "price-gradient-3",
                                        description: "For teams who want to revolutionise their recruitment process",
                                        options: [
                                            { included: true, text: "AI powered search for recruitment" },
                                            { included: true, text: "Unlimited outreach" },
                                            { included: false, text: "Find direct contact info for hires" },
                                            { included: false, text: "Jobs and workflows created for you" },
                                            { included: false, text: "AI Sequence flow" },
                                            { included: false, text: "AI note-taking: records and summarizes" }
                                        ]
                                    }
                                ].map((item) => {
                                    return (
                                        <div className={(item.gradient ? item.gradient : "") + " mx-auto mt-auto flex h-full w-full grid-cols-1 flex-col gap-8 rounded-lg border bg-gray-100 p-6 transition-all duration-200 ease-linear sm:grid sm:grid-cols-2 lg:grid-cols-1"}>
                                            <div className="grid h-fit sm:h-full lg:h-fit">
                                                <h3 className="w-fit text-3xl text-orange-500 text-left font-medium">
                                                    {item.title}
                                                </h3>
                                            </div>
                                            <div class="grid h-fit text-left">
                                                <p class={(item.gradient ? "text-white" : "text-gray-950") + " text-3xl font-medium"}>{item.price}</p>
                                                <p class={(item.gradient ? "text-gray-200" : "text-gray-700") + " text-base font-normal"}>/month</p>
                                            </div>
                                            <div class="flex w-full text-left">
                                                <p class={(item.gradient ? "text-white" : "text-gray-950") + " text-sm font-normal"}>{item.description}</p>
                                            </div>
                                            <div className="w-full">
                                                <button class="inline-flex items-center w-full justify-center rounded-full border border-purple-500 bg-purple-500 px-6 py-3 text-base font-medium text-white hover:bg-purple-600" type="button">
                                                    Select
                                                </button>
                                            </div>
                                            <div className="grid gap-4">
                                                {
                                                    Array.isArray(item.options) &&
                                                    item.options.map((option) => {
                                                        return (
                                                            <div class="flex gap-2.5">
                                                                <p class={(item.gradient ? "text-white" : "text-gray-950") + " flex flex-1 justify-start text-left text-sm font-medium"}>{option.text}</p>
                                                                {
                                                                    option.included &&
                                                                    <CheckCircleIcon className={(item.gradient ? "text-green-400" : "text-green-700") + " w-5"} />
                                                                }
                                                                {
                                                                    !option.included &&
                                                                    <XCircleIcon className="w-5 text-gray-400" />
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>


                    {/* FAQ */}
                    <div className="">
                        <div className="flex w-full flex-col gap-16 text-center">
                            <div className="flex text-2xl font-semibold lg:text-5xl flex-wrap items-center justify-center gap-0.5 text-center">
                                Frequently asked questions
                            </div>
                        </div>
                        {
                            this.state.faq.map((item, index) => {
                                return (
                                    <div className={(index == 0 ? "mt-16 border-t" : "") + " flex flex-col border-b"}>
                                        <div className="w-full my-7">
                                            <div onClick={() => {
                                                item.open = !item.open;
                                                this.setState({
                                                    faq: this.state.faq
                                                });
                                            }} className="flex w-full items-center justify-center cursor-pointer">
                                                <div className="font-medium flex flex-1 text-gray-900">{item.title}</div>
                                                {
                                                    !item.open &&
                                                    <PlusIcon className="w-5" />
                                                }
                                                {
                                                    item.open &&
                                                    <MinusIcon className="w-5" />
                                                }
                                            </div>
                                            <div className={(item.open ? "pt-7" : "h-0") + " grid overflow-hidden h-fit gap-2.5 font-normal text-gray-800"}>
                                                <p>{item.text}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>

                    {/* SIGN UP SECTION */}
                    <div className="flex">
                        <div className="relative flex w-full overflow-hidden rounded-3xl">
                            <img loading="lazy" src={"https://talentium.io/assets/radial-blue-background-B-N59hWv.png"} alt="" className="absolute h-full w-full object-cover" />
                            <div className="z-10 m-auto flex w-full flex-col gap-2 px-10 py-14 text-center">
                                <div class="m-auto w-fit bg-gradient-to-r text-white bg-clip-text text-base font-medium leading-normal text-transparent">Get started</div>
                                <div class="mx-auto text-center font-medium leading-tight text-white lg:text-5xl">Let's change your recruitment process</div>
                                <div className="mx-auto mt-6">
                                    <button class="inline-flex items-center justify-center rounded-full border border-gray-500 text-white px-6 py-3 text-base font-medium" type="button">
                                        Sign Up now
                                    </button>
                                </div>
                                <p className="mx-auto font-normal text-gray-400">Complete your sign-up in under 2 minutes</p>
                            </div>
                        </div>
                    </div>

                </div>

                {/* FOOTER SECTION */}
                <div class="w-full mt-16 border-0 border-t border-gray-200">
                    <div class="mx-auto mt-auto flex w-full max-w-160 flex-col gap-12 p-5">
                        <div class="my-auto flex w-full flex-col items-center justify-between gap-8 lg:flex-row lg:justify-center">
                            <a class="m-auto flex gap-4 lg:m-0" href="/">
                                <div class="flex items-center gap-4">
                                    <img className="w-8" src={require("../assets/images/proptree_test_logo.svg")} />
                                    <p class="text-3xl font-bold text-off_black">Talentium</p>
                                </div>
                            </a>
                            <div class="flex gap-4 lg:justify-end flex-1 md:gap-6">
                                <a href="/legal">
                                    <p class="text-base font-normal leading-normal text-f-gray-950">Legal</p>
                                </a><a href="/opt-out">
                                    <p class="text-base font-normal leading-normal text-f-gray-950">Opt out</p>
                                </a><a href="/cookie-declaration">
                                    <p class="text-base font-normal leading-normal text-f-gray-950">Cookie Declaration</p>
                                </a>
                            </div>
                        </div>
                        <div class="md:mt-20 flex w-full justify-center gap-8 md:justify-between">
                            <p class="text-center text-xs font-normal text-f-gray-800">© 2023 Talentium. All rights reserved. Stockholm, SE 14:56</p>
                            <p class="hidden text-center text-xs font-normal text-f-gray-800 md:inline">info@talentium.io</p>
                        </div>
                    </div>
                </div>

            </div >
        )
    }
}

export default ProptreeHome;
