import React, { Component, Fragment } from 'react';
import { PlusIcon, SortAscendingIcon, SortDescendingIcon } from '@heroicons/react/solid'
import { Dialog, Transition, Menu } from '@headlessui/react'
import { UsersIcon, PhoneIcon, ChevronDownIcon, PlusCircleIcon, ChatIcon, ExternalLinkIcon, UserAddIcon, MailIcon } from '@heroicons/react/outline'
import { apiRegister } from "../../services/apiRegister";
import { tokenRegister } from '../../services/tokenRegister';
import { userRegister } from '../../services/userRegister';
import CreateUser from '../../components/createUser';
import WarningModal from '../../components/warningModal';
import { versionRegister } from '../../services/versionRegister';
import SingleSelection from '../../components/singleSelection';
import Dropdown from '../../components/dropdown';
import CreateAgent from '../../components/createAgent';
import { PencilAltIcon } from "@heroicons/react/outline/esm/index";
import SelectionModal from '../../components/selectionModal';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

class ListAgents extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            open: false,
            tab: { id: 1, name: "Active", value: "active", count: 0 },
            proptree: { id: 1, name: "All proptree", value: 'all_types', count: 0 },
            users: [],
            user: {},
            sort: { id: 1, name: "Name", value: 'name' },
            order: { id: 2, name: "Ascending", value: 'ascending' },
            page: 1,
            page_count: 10,
            total: 0
        }
    };

    componentDidMount() {
        this.functions.getUser();
    }

    functions = {
        getUser: async () => {
            if (userRegister.get()) {
                await this.promisedSetState({
                    user: userRegister.get() ? userRegister.get() : {}
                })
            }
            this.functions.getUsers();
        },
        getUsers: async () => {
            try {
                await this.promisedSetState({ loading_users: true });
                let response = await this.calls.getUsers();
                await this.promisedSetState({
                    users: response.data,
                    total: response.meta[this.state.tab.value] ? response.meta[this.state.tab.value] : 0,
                    active: response.meta.active ? response.meta.active : 0,
                    disabled: response.meta.disabled ? response.meta.disabled : 0,
                    loading_users: false,
                    loading: false
                });
                this.functions.sideScroll();
            } catch (error) {
                this.setState({
                    error: true,
                    message: error.body ? error.body.message : "Something went wrong",
                    loading_users: false,
                    loading: false
                })
            }
        },
        createProptreeAgent: async (agent) => {
            await this.promisedSetState({
                remove: null,
                users: this.state.users.map((item) => {
                    if (item.id == agent.id) {
                        item.loading_proptree = true;
                    }
                    return item;
                })
            });
            try {
                let response = await this.calls.createProptreeAgent({ id: agent.id });
                this.state.users = this.state.users.map((item) => {
                    if (item.id == agent.id) {
                        item.proptree = response.data;
                    }
                    return item;
                });
                await this.promisedSetState({
                    users: this.state.users
                });
            } catch (error) {
                await this.promisedSetState({
                    error: true,
                    message: error.body ? error.body.message : "Something went wrong"
                })
            }
            await this.promisedSetState({
                users: this.state.users.map((item) => {
                    if (item.id == agent.id) {
                        item.loading_proptree = false;
                    }
                    return item;
                })
            });
        },
        sendProptreeWelcomeTemplate: async (agent) => {
            await this.promisedSetState({
                template_modal: null,
                users: this.state.users.map((item) => {
                    if (item.id == agent.id) {
                        item.loading_proptree = true;
                    }
                    return item;
                })
            });
            try {
                let response = await this.calls.sendProptreeWelcomeTemplate({ id: agent.proptree.id });
                this.state.users = this.state.users.map((item) => {
                    if (item.id == agent.id) {
                        item.proptreeWelcomeWaID = response.data.proptreeWelcomeWaID;
                        item.proptreeWelcomeWaStatus = response.data.proptreeWelcomeWaStatus;
                    }
                    return item;
                });
                await this.promisedSetState({
                    users: this.state.users
                });
            } catch (error) {
                await this.promisedSetState({
                    error: true,
                    message: error.body ? error.body.message : "Something went wrong"
                })
            }
            await this.promisedSetState({
                users: this.state.users.map((item) => {
                    if (item.id == agent.id) {
                        item.loading_proptree = false;
                    }
                    return item;
                })
            });
        },
        sideScroll: () => {
            try {
                const slider = document.querySelector('#table_container');
                let isDown = false;
                let startX;
                let scrollLeft;
                slider.addEventListener('mousedown', (e) => {
                    isDown = true;
                    slider.classList.add('active');
                    startX = e.pageX - slider.offsetLeft;
                    scrollLeft = slider.scrollLeft;
                });
                slider.addEventListener('mouseleave', () => {
                    isDown = false;
                    slider.classList.remove('active');
                });
                slider.addEventListener('mouseup', () => {
                    isDown = false;
                    slider.classList.remove('active');
                });
                slider.addEventListener('mousemove', (e) => {
                    if (!isDown) return;
                    e.preventDefault();
                    const x = e.pageX - slider.offsetLeft;
                    const walk = (x - startX) * 1;
                    slider.scrollLeft = scrollLeft - walk;
                });
            } catch (error) { }
        }
    };

    calls = {
        getUsers: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', data);
            let url = "";
            if (this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value == "enabled") {
                url = apiRegister.url.api + "/user/listAgentsLight?page=" + this.state.page + "&limit=" + this.state.page_count + "&orderBy=" + this.state.order.value + "&sortBy=" + this.state.sort.value + "&status=" + this.state.tab.id + "&proptree=" + this.state.proptree.value;
            } else {
                url = apiRegister.url.api + "/user/agents?page=" + this.state.page + "&limit=" + this.state.page_count + "&orderBy=" + this.state.order.value + "&sortBy=" + this.state.sort.value + "&status=" + this.state.tab.id;
            }
            return apiRegister.call(options, url);
        },
        createProptreeAgent: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/proptree/createProptreeAgent";
            return apiRegister.call(options, url);
        },
        sendProptreeWelcomeTemplate: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/proptree/sendProptreeWelcomeTemplate/" + data.id;
            return apiRegister.call(options, url);
        }
    };

    renders = {
        whatsappSendAndRecieved: (agent) => {
            //let send = Array.isArray(agent.newLeadsWhatsApp) ? agent.newLeadsWhatsApp.filter((item) => { return item.date !== "Invalid date" }).length : 0;
            let read = Array.isArray(agent.newLeadsWhatsApp) ? agent.newLeadsWhatsApp.filter((item) => { return item.date !== "Invalid date" && item.status == "read" }).length : 0;
            //return send + " / " + read;
            return read;
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    };

    render() {
        return (
            <div className="grid grid-cols-12 sm:p-0 p-6 pt-0 h-full min-h-full bg-gray-50">

                {/*LOADING*/}
                {
                    this.state.loading &&
                    <div className="col-span-12 relative min-h-screen flex justify-center items-start">
                        <div className="w-full h-full flex justify-center items-center flex-col pb-32">
                            <div style={{ borderTopColor: "transparent" }}
                                class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                            <div className="font-semibold text-sm mt-4">Loading agents ...</div>
                        </div>
                    </div>
                }

                {/*ERROR WARNING*/}
                <WarningModal
                    open={this.state.error ? true : false}
                    title={"Error"}
                    description={typeof this.state.message == "string" ? this.state.message : "Something went wrong"}
                    cancelButtonText={"ok"}
                    noSubmitButton={true}
                    onClose={() => {
                        this.setState({
                            error: null
                        })
                    }}
                />

                {/*WELCOME WARNING*/}
                <SelectionModal
                    proptree={true}
                    open={this.state.template_modal ? true : false}
                    onAccept={() => {
                        this.functions.sendProptreeWelcomeTemplate(this.state.template_modal);
                    }}
                    onClose={() => {
                        this.setState({
                            template_modal: false
                        })
                    }}
                />

                {/*REMOVE MODAL*/}
                <WarningModal
                    open={this.state.remove ? true : false}
                    title={"Delete User"}
                    description={"Are you sure you want to delete this user? This action cannot be undone"}
                    cancelButtonText={"cancel"}
                    submitButtonText={"remove"}
                    disableSubmitButton={this.state.remove && this.state.remove_name != this.state.remove.name}
                    showInput={true}
                    copyInput={this.state.remove ? this.state.remove.name : ""}
                    inputTitle={'Type "Name" to remove'}
                    inputError={(this.state.remove && this.state.remove_name && this.state.remove_name !== "" && this.state.remove_name != this.state.remove.name) ? "Names not matching" : false}
                    onClose={(value) => {
                        this.setState({ remove: null })
                    }}
                    onSubmit={(value) => {
                        if (this.state.remove && this.state.remove_name == this.state.remove.name) {
                            this.functions.removeUser();
                        }
                    }}
                    onInput={(value) => {
                        this.setState({ remove_name: value })
                    }}
                />

                {/*CREATE USER*/}
                <CreateAgent
                    view={"agent_list"}
                    user={this.state.selected}
                    open={this.state.open}
                    toggle={() => {
                        this.setState({
                            open: !this.state.open
                        })
                    }}
                    onUser={async (user) => {
                        await this.promisedSetState({
                            limit: 10,
                            page: 1
                        });
                        this.functions.getUsers();
                    }}
                    onUpdate={async (user) => {
                        this.functions.getUsers();
                    }}
                />

                {/*OPTION*/}
                {
                    !this.state.loading &&
                    <div className={(this.state.user.enterprise && this.state.user.enterprise.managerUIproptree && this.state.user.enterprise.managerUIproptree.value === 'enabled') ? "col-span-6 md:col-span-2 mb-4 md:mb-6 pr-2 md:pr-2" : "col-span-6 md:col-span-2 mb-4 md:mb-6"}>
                        <div className="bg-white border rounded-md">
                            <Dropdown
                                selected={this.state.tab}
                                options={[
                                    { id: 1, name: "Active", value: 'active', count: this.state.active },
                                    { id: 2, name: "Disabled", value: 'disabled', count: this.state.disabled }
                                ]}
                                searchInput={false}
                                onChange={async (option) => {
                                    await this.promisedSetState({
                                        tab: option,
                                        page: 1
                                    });
                                    this.functions.getUsers();
                                }}
                            />
                        </div>
                    </div>
                }

                {/*OPTION*/}
                {
                    !this.state.loading &&
                    (this.state.user.enterprise && this.state.user.enterprise.managerUIproptree && this.state.user.enterprise.managerUIproptree.value === 'enabled') &&
                    <div className="col-span-6 md:col-span-2 mb-4 md:mb-6 pl-2">
                        <div className="bg-white border rounded-md">
                            <Dropdown
                                selected={this.state.proptree}
                                options={[
                                    { id: 1, name: "All proptree", value: 'all_types', count: this.state.all_types },
                                    { id: 2, name: "Created", value: 'has_proptree', count: this.state.has_proptree },
                                    { id: 3, name: "Not created", value: 'no_proptree', count: this.state.no_proptree }
                                ]}
                                searchInput={false}
                                onChange={async (option) => {
                                    await this.promisedSetState({
                                        proptree: option,
                                        page: 1
                                    });
                                    this.functions.getUsers();
                                }}
                            />
                        </div>
                    </div>
                }

                {
                    (this.state.user.enterprise && this.state.user.enterprise.managerUIproptree && this.state.user.enterprise.managerUIproptree.value === 'enabled') &&
                    <div className="col-span-0 hidden md:block md:col-span-4"></div>
                }
                {
                    !(this.state.user.enterprise && this.state.user.enterprise.managerUIproptree && this.state.user.enterprise.managerUIproptree.value === 'enabled') &&
                    <div className="col-span-0 hidden md:block md:col-span-6"></div>
                }

                {/*SORT*/}
                {
                    !this.state.loading &&
                    <div className="col-span-6 md:col-span-2 mb-4 md:mb-6 pr-2 md:pr-2">
                        <div className="bg-white border rounded-md">
                            <Dropdown
                                selected={this.state.sort}
                                options={[
                                    { id: 1, name: "Name", value: 'name', adsOnly: true },
                                    { id: 5, name: "Unread", value: 'totalUnreadMessages', adsOnly: false },
                                    { id: 6, name: "Unranked", value: 'unrankedLeads', adsOnly: false },
                                    { id: 2, name: "Listings", value: 'totalListings', adsOnly: false },
                                    { id: 3, name: "Leads", value: 'totalLeads', adsOnly: false },
                                    { id: 4, name: "Actions", value: 'totalActions', adsOnly: false }
                                ].filter((item) => {
                                    if (this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled") {
                                        if (item.value === 'totalActions' || item.value === 'totalUnreadMessages' || item.value === 'unrankedLeads') {
                                            return false;
                                        } else {
                                            return true;
                                        }
                                    } else {
                                        return true;
                                    }
                                }).filter((item) => {
                                    if ((this.state.user.enterprise && this.state.user.enterprise.managerUIleads && this.state.user.enterprise.managerUIleads.value === 'disabled')) {
                                        if (item.value === 'totalLeads' || item.value === 'totalActions' || item.value === 'totalUnreadMessages' || item.value === 'unrankedLeads') {
                                            return false;
                                        } else {
                                            return true;
                                        }
                                    } else {
                                        return true;
                                    }
                                })}
                                searchInput={false}
                                onChange={async (option) => {
                                    await this.promisedSetState({
                                        sort: option,
                                        page: 1
                                    });
                                    this.functions.getUsers();
                                }}
                            />
                        </div>
                    </div>
                }

                {/*ORDER*/}
                {
                    !this.state.loading &&
                    <div className="col-span-6 md:col-span-2 mb-4 md:mb-6 pl-2">
                        <div className="bg-white border rounded-md">
                            <Dropdown
                                selected={this.state.order}
                                options={[
                                    { id: 1, name: "Descending", value: 'descending' },
                                    { id: 2, name: "Ascending", value: 'ascending' }
                                ]}
                                searchInput={false}
                                onChange={async (option) => {
                                    await this.promisedSetState({
                                        order: option,
                                        page: 1
                                    });
                                    this.functions.getUsers();
                                }}
                            />
                        </div>
                    </div>
                }

                {/*AGENTS*/}
                {
                    !this.state.loading &&
                    <div className="col-span-12">
                        <div className="">
                            {
                                <section className="bg-white shadow-lg border rounded-lg">
                                    <div className="flex p-4 items-center lg:px-6 border-b border-gray-100">
                                        <div className="font-sm font-semibold flex-1 flex">
                                            {this.state.tab.name}
                                        </div>
                                        {
                                            this.state.user.enterprise && this.state.user.enterprise.managerUIcreateUsers && this.state.user.enterprise.managerUIcreateUsers.value === 'enabled' &&
                                            this.state.user.access && this.state.user.access.id === 1 &&
                                            <div onClick={() => {
                                                this.setState({
                                                    open: true,
                                                    selected: null
                                                })
                                            }} className="text-sm font-medium cursor-pointer h-10  px-4  ml-4 rounded-md bg-purple-100 border-purple-500 items-center flex flex-row text-purple-500 hover:text-purple-700 ">
                                                <PlusCircleIcon className="w-5 h-5 mr-2" />
                                                Create
                                            </div>
                                        }
                                    </div>
                                    {
                                        this.state.users.length < 1 &&
                                        <div className="h-64 flex items-center justify-center border-b border-gray-100 relative">
                                            {
                                                !this.state.loading_users &&
                                                <div className="font-medium text-sm">No <span className="text-purple-500">{this.state.tab.name}</span> available</div>
                                            }
                                            {
                                                this.state.loading_users &&
                                                <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-white bg-opacity-50">
                                                    <div style={{ borderTopColor: "transparent" }}
                                                        class="w-12 h-12 border-2 border-indigo-500 absolute border-solid rounded-full animate-spin"></div>
                                                </div>
                                            }
                                        </div>
                                    }
                                    {
                                        this.state.users.length > 0 &&
                                        <div id={"table_container"} className="relative overflow-x-scroll min-h-110">
                                            <table className="min-w-full">
                                                <thead className="">
                                                    <tr>

                                                    </tr>
                                                </thead>
                                                <tbody className="">
                                                    {
                                                        this.state.users.map((agent, index) => {
                                                            return (
                                                                <tr key={agent.email} className="border-b border-gray-100">
                                                                    <td className="text-left pl-6 whitespace-no-wrap">
                                                                        <div className="flex items-center justify-start">
                                                                            <Menu as="div" className="relative inline-block mr-2 text-left">
                                                                                <div>
                                                                                    <Menu.Button className="inline-flex relative hover:bg-gray-50 items-center justify-center rounded-md bg-white border w-10 h-10 text-sm font-medium text-gray-900">
                                                                                        {
                                                                                            !(agent.loading || agent.loading_proptree) &&
                                                                                            <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                                                                                        }
                                                                                        {
                                                                                            (agent.loading || agent.loading_proptree) &&
                                                                                            <div className="top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-red-50 bg-opacity-50">
                                                                                                <div style={{ borderTopColor: "transparent" }}
                                                                                                    class={(agent.loading_proptree ? "border-purple-500" : "border-red-500") + " w-4 h-4 border-2  absolute border-solid rounded-full animate-spin"}></div>
                                                                                            </div>
                                                                                        }
                                                                                    </Menu.Button>
                                                                                </div>
                                                                                <Transition
                                                                                    as={Fragment}
                                                                                    enter="transition ease-out duration-100"
                                                                                    enterFrom="transform opacity-0 scale-95"
                                                                                    enterTo="transform opacity-100 scale-100"
                                                                                    leave="transition ease-in duration-75"
                                                                                    leaveFrom="transform opacity-100 scale-100"
                                                                                    leaveTo="transform opacity-0 scale-95"
                                                                                >
                                                                                    <Menu.Items className="absolute border left-0 z-10 mt-2 origin-top-right rounded-md bg-white shadow-lg">
                                                                                        <div className="py-1">
                                                                                            {
                                                                                                [
                                                                                                    { name: "Show agent leads", value: "show_leads", icon: ExternalLinkIcon },
                                                                                                    { name: "Edit agent", value: "edit", icon: PencilAltIcon },
                                                                                                    { name: "Create proptree", value: "create_proptree", icon: UserAddIcon },
                                                                                                    { name: "Open proptree", value: "open_proptree", icon: ExternalLinkIcon },
                                                                                                    { name: "Send welcome template", value: "welcome_template", icon: MailIcon }
                                                                                                ].filter((item) => {
                                                                                                    if (item.value == "create_proptree") {
                                                                                                        return !(agent.proptree && agent.proptree.id) && (this.state.user.enterprise && this.state.user.enterprise.managerUIproptree && this.state.user.enterprise.managerUIproptree.value === 'enabled');
                                                                                                    } if (item.value == "open_proptree") {
                                                                                                        return agent.proptree && agent.proptree.id && (this.state.user.enterprise && this.state.user.enterprise.managerUIproptree && this.state.user.enterprise.managerUIproptree.value === 'enabled');
                                                                                                    } if (item.value == "welcome_template") {
                                                                                                        return !agent.welcome_status && agent.proptree && agent.proptree.id && (this.state.user.enterprise && this.state.user.enterprise.managerUIproptree && this.state.user.enterprise.managerUIproptree.value === 'enabled');
                                                                                                    } else if (item.value == "edit") {
                                                                                                        return this.state.user.enterprise.dev && (this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled")
                                                                                                    } else {
                                                                                                        return true;
                                                                                                    }
                                                                                                }).map((item) => {
                                                                                                    return (
                                                                                                        <Menu.Item>
                                                                                                            {({ active }) => (
                                                                                                                <div
                                                                                                                    onClick={() => {
                                                                                                                        if (item.value == "show_leads") {
                                                                                                                            this.props.history.push("/manager/leads?agent=" + agent.id);
                                                                                                                        } else if (item.value == "edit") {
                                                                                                                            this.setState({
                                                                                                                                open: true,
                                                                                                                                selected: agent
                                                                                                                            });
                                                                                                                        } else if (item.value == "create_proptree") {
                                                                                                                            if (!agent.loading_proptree) {
                                                                                                                                this.functions.createProptreeAgent(agent);
                                                                                                                            }
                                                                                                                        } else if (item.value == "open_proptree") {
                                                                                                                            if (window.location.origin.indexOf('localhost') !== -1) {
                                                                                                                                window.open("http://localhost:5001/" + agent.proptree.shortId + "/proptree", "_blank");
                                                                                                                            } else {
                                                                                                                                window.open("https://proptr.ee/" + agent.proptree.shortId + "/", "_blank");
                                                                                                                            }
                                                                                                                        } else if (item.value == "welcome_template") {
                                                                                                                            this.setState({
                                                                                                                                template_modal: agent
                                                                                                                            })
                                                                                                                        }
                                                                                                                    }}
                                                                                                                    className={classNames(
                                                                                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                                        'flex items-center px-4 py-2 font-medium text-sm cursor-pointer',
                                                                                                                        (item.value == "disable" || item.value == "remove") ? "text-red-500 bg-red-100" : ""
                                                                                                                    )}
                                                                                                                >
                                                                                                                    <div className="flex flex-1 mr-3">{item.name}</div>
                                                                                                                    <item.icon className="w-5" />
                                                                                                                </div>
                                                                                                            )}
                                                                                                        </Menu.Item>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </div>
                                                                                    </Menu.Items>
                                                                                </Transition>
                                                                            </Menu>
                                                                        </div>
                                                                    </td>
                                                                    {
                                                                        (this.state.user.enterprise && this.state.user.enterprise.managerUIproptree && this.state.user.enterprise.managerUIproptree.value === 'enabled') &&
                                                                        <td className="text-center px-6 py-4">
                                                                            {
                                                                                agent.proptree && agent.proptree.id &&
                                                                                <div className="text-gray-900 p-1 px-2 text-xs rounded-md bg-gray-100 whitespace-no-wrap">
                                                                                    {agent.proptree.shortId}
                                                                                </div>
                                                                            }
                                                                            {
                                                                                !(agent.proptree && agent.proptree.id) &&
                                                                                <div className="text-red-500 p-1 px-2 text-xs rounded-md bg-red-100 whitespace-no-wrap">
                                                                                    {"-"}
                                                                                </div>
                                                                            }
                                                                            <div className="text-sm whitespace-no-wrap text-gray-500 mb-1">{"Proptree"}</div>
                                                                        </td>
                                                                    }
                                                                    {
                                                                        (this.state.user.enterprise && this.state.user.enterprise.managerUIproptree && this.state.user.enterprise.managerUIproptree.value === 'enabled') &&
                                                                        <td className="text-center px-6 py-4">
                                                                            <div className={"text-sm capitalize"}>
                                                                                {agent.proptreeWelcomeWaStatus ? agent.proptreeWelcomeWaStatus : "-"}
                                                                            </div>
                                                                            <div className="text-sm whitespace-no-wrap text-gray-500">{"Welcome template"}</div>
                                                                        </td>
                                                                    }
                                                                    <td className="px-6 py-4 text-left">
                                                                        <div className="flex flex-row">
                                                                            {
                                                                                !agent.image &&
                                                                                <span className="inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-100">
                                                                                    <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                                                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                                                                    </svg>
                                                                                </span>
                                                                            }
                                                                            {
                                                                                agent.image &&
                                                                                <div className="flex-shrink-0 h-10 w-10">
                                                                                    <img className="h-10 w-10 rounded-full" src={agent.image} alt="" />
                                                                                </div>
                                                                            }
                                                                            <div className="ml-4 whitespace-no-wrap">
                                                                                <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.name}</div>
                                                                                <div className="text-sm text-gray-500 whitespace-no-wrap">{agent.email}</div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td className="text-center px-6 py-4">
                                                                        <div className={"text-sm"}>
                                                                            {agent.phone ? agent.phone : "..."}
                                                                        </div>
                                                                        <div className="text-sm text-gray-500">{agent.whatsapp && agent.whatsapp.id == 1 ? "WhatsApp" : "Phone"}</div>
                                                                    </td>
                                                                    {
                                                                        this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled" &&
                                                                        !(this.state.user.enterprise && this.state.user.enterprise.managerUIleads && this.state.user.enterprise.managerUIleads.value === 'disabled') &&
                                                                        this.state.user.enterprise && this.state.user.enterprise.brokersWhatsapp && this.state.user.enterprise.brokersWhatsapp.value &&
                                                                        <td className="text-center px-6 py-4" style={{ minWidth: "12rem" }}>
                                                                            <div className={"text-sm"}>
                                                                                {this.renders.whatsappSendAndRecieved(agent)}
                                                                            </div>
                                                                            <div className="text-sm text-gray-500">Unread leads</div>
                                                                        </td>
                                                                    }
                                                                    <td className="text-center px-6 py-4">
                                                                        <div className={"text-sm"}>
                                                                            {agent.access && agent.access.id === 2 ? "Agent" : ""}
                                                                        </div>
                                                                        <div className="text-sm text-gray-500">Access</div>
                                                                    </td>
                                                                    <td className="text-center px-6 py-4">
                                                                        <div className={(agent.status && agent.status.id == 1 ? " text-green-500" : " text-red-500") + " text-sm"}>
                                                                            {agent.status ? agent.status.name : ""}
                                                                        </div>
                                                                        <div className="text-sm text-gray-500">Status</div>
                                                                    </td>
                                                                    <td className="text-center px-6 py-4">
                                                                        <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.license}</div>
                                                                        <div className="text-sm text-gray-500">Permit</div>
                                                                    </td>
                                                                    {
                                                                        !(this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled") &&
                                                                        !(this.state.user.enterprise && this.state.user.enterprise.managerUIleads && this.state.user.enterprise.managerUIleads.value === 'disabled') &&
                                                                        this.state.user.enterprise && this.state.user.enterprise.brokersWhatsapp && this.state.user.enterprise.brokersWhatsapp.value &&
                                                                        <td className="text-center px-6 py-4 pt-8" style={{ minWidth: "12rem" }}>
                                                                            <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.totalUnreadMessages ? agent.totalUnreadMessages : 0}</div>
                                                                            <div className="text-sm text-gray-500">Unread leads</div>
                                                                            <div className="text-xxs text-gray-500">(read receipts must be on)</div>
                                                                        </td>
                                                                    }
                                                                    <td className="text-center px-6 py-4">
                                                                        <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.totalListings ? agent.totalListings : 0}</div>
                                                                        <div className="text-sm text-gray-500">Listings</div>
                                                                    </td>
                                                                    {
                                                                        //(this.state.user.enterprise && this.state.user.enterprise.managerUIproptree && this.state.user.enterprise.managerUIproptree.value === 'disabled') &&
                                                                        !(this.state.user.enterprise && this.state.user.enterprise.managerUIleads && this.state.user.enterprise.managerUIleads.value === 'disabled') &&
                                                                        <td className="text-center px-6 py-4">
                                                                            <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.totalLeads ? agent.totalLeads : 0}</div>
                                                                            <div className="text-sm text-gray-500">Leads</div>
                                                                        </td>
                                                                    }
                                                                    {
                                                                        !(this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled") &&
                                                                        !(this.state.user.enterprise && this.state.user.enterprise.managerUIleads && this.state.user.enterprise.managerUIleads.value === 'disabled') &&
                                                                        <td className="text-center px-6 py-4">
                                                                            <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.totalActions ? agent.totalActions : 0}</div>
                                                                            <div className="text-sm text-gray-500">Actions</div>
                                                                        </td>
                                                                    }
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            {
                                                this.state.loading_users &&
                                                <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-white bg-opacity-50">
                                                    <div style={{ borderTopColor: "transparent" }}
                                                        class="w-12 h-12 border-2 border-indigo-500 absolute border-solid rounded-full animate-spin"></div>
                                                </div>
                                            }
                                        </div>
                                    }
                                    {
                                        this.state.users.length > 0 &&
                                        <div className="rounded-b-lg">
                                            <nav
                                                className="py-4 flex items-center justify-between px-6"
                                                aria-label="Pagination"
                                            >
                                                <div className="hidden sm:block">
                                                    <p className="text-sm text-gray-700">
                                                        Showing <span className="font-medium">{((this.state.page * this.state.page_count) - this.state.page_count) + 1}</span> to <span className="font-medium">{(this.state.page * this.state.page_count) > this.state.total ? this.state.total : (this.state.page * this.state.page_count)}</span> of{' '}
                                                        <span className="font-medium">{this.state.total}</span> results
                                                    </p>
                                                </div>
                                                <div className="flex-1 flex flex-row">
                                                    <div className="flex-1 sm:justify-end flex">
                                                        <Menu as="div" className="relative inline-block text-left mr-2">
                                                            <div>
                                                                <Menu.Button className={(this.state.sequence_loading ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " inline-flex relative justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                                    Page limit: {this.state.page_count}
                                                                    <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                                                                </Menu.Button>
                                                            </div>
                                                            <Transition
                                                                as={Fragment}
                                                                enter="transition ease-out duration-100"
                                                                enterFrom="transform opacity-0 scale-95"
                                                                enterTo="transform opacity-100 scale-100"
                                                                leave="transition ease-in duration-75"
                                                                leaveFrom="transform opacity-100 scale-100"
                                                                leaveTo="transform opacity-0 scale-95"
                                                            >
                                                                <Menu.Items className="border absolute z-50 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                    <div className="py-1">
                                                                        {
                                                                            [1, 10, 20, 30, 50, 100].map((item) => {
                                                                                return (
                                                                                    <Menu.Item>
                                                                                        {({ active }) => (
                                                                                            <div
                                                                                                onClick={() => {
                                                                                                    this.setState({
                                                                                                        page_count: item,
                                                                                                        page: 1
                                                                                                    }, () => {
                                                                                                        this.functions.getUsers();
                                                                                                    })
                                                                                                }}
                                                                                                className={classNames(
                                                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                    'px-4 py-2 text-sm relative flex flex-row cursor-pointer',
                                                                                                    this.state.page_count == item ? "text-purple-500" : ""
                                                                                                )}
                                                                                            >
                                                                                                {item}
                                                                                            </div>
                                                                                        )}
                                                                                    </Menu.Item>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </Menu.Items>
                                                            </Transition>
                                                        </Menu>
                                                    </div>
                                                    <div
                                                        onClick={() => {
                                                            if (this.state.page !== 1) {
                                                                this.setState({
                                                                    select_all: false,
                                                                    page: this.state.page > 1 ? (this.state.page - 1) : 1
                                                                }, () => {
                                                                    this.functions.getUsers();
                                                                });
                                                            }
                                                        }}
                                                        className={(this.state.page === 1 ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                                    >
                                                        Previous
                                                    </div>
                                                    <div
                                                        onClick={() => {
                                                            if (((this.state.page) * this.state.page_count) < this.state.total) {
                                                                this.setState({
                                                                    select_all: false,
                                                                    page: this.state.page + 1
                                                                }, () => {
                                                                    this.functions.getUsers();
                                                                });
                                                            }
                                                        }}
                                                        className={(((this.state.page) * this.state.page_count) > this.state.total ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                                    >
                                                        Next
                                                    </div>
                                                </div>
                                            </nav>
                                        </div>
                                    }
                                </section>
                            }
                        </div>
                    </div>
                }

                {
                    <div className="w-full h-10 hidden sm:block"></div>
                }

            </div>
        )
    }
}

export default ListAgents;
