import React, { Component, Fragment } from 'react';
import { BrowserRouter, Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { BellIcon, ChevronDownIcon, MenuIcon, XIcon } from '@heroicons/react/outline'
import Dashboard from './dashboard';
import ListUsers from './listUsers';
import ListAgents from './listAgents';
import ListEnterprise from './listEnterprise';
import Enterprise from './enterprise';
import Settings from './settings';
import Profile from './profile';
import Finance from './finance';
import Reports from './reports';
import ListListings from './listListings';
import ListAds from './listAds';
import Report from './report';
import ListLegcyFeeds from './listFeeds';
import ListLightFeeds from './agent/listFeeds';
import { userRegister } from '../services/userRegister';
import { tokenRegister } from '../services/tokenRegister';
import { versionRegister } from '../services/versionRegister';
import PreviewFeed from './previewFeed';
import ListClients from './listClients';
import ListAudience from './listAudience';
import ListLeads from './listLeads';
import ListActions from './listActions';
import { apiRegister } from '../services/apiRegister';
import ListLogs from './listLogs';
import ListWhatsappMessages from './agent/listWhatsappMessages';
import ListProptrees from './agent/listProptrees';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

class LoggedInAdmin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            navigation: [],
            userNavigation: [
                { name: 'Your Profile', href: 'profile' },
                { name: 'Sign out', href: 'logout' },
            ],
            version: {}
        }
    };

    componentWillMount() {
        this.functions.getUser();
        this.functions.getVersion();
    }

    componentDidMount() {

    }

    functions = {
        logout: async () => {
            await this.calls.logoutUser();
            userRegister.remove();
            tokenRegister.remove();
            this.props.history.push('/login');
        },
        profile: () => {
            this.props.history.push('/dashboard/profile');
        },
        getVersion: () => {
            let version = versionRegister.get();
            this.setState({
                version: version
            })
        },
        getUser: () => {
            let user = userRegister.get();
            if (user && user.level) {
                let agent = [];
                let admin = [
                    { name: 'Dashboard', href: '/dashboard' },
                    { name: 'Legacy feeds', href: '/dashboard/feeds' },
                    { name: 'Light feeds', href: '/dashboard/light-feeds' },
                    { name: 'Listings', href: '/dashboard/listings' },
                    { name: 'Enterprises', href: '/dashboard/enterprise' },
                    { name: 'Agents', href: '/dashboard/agents' },
                    { name: 'Users', href: '/dashboard/users' },
                    { name: 'Audience', href: '/dashboard/audience' },
                    { name: 'Clients', href: '/dashboard/clients' },
                    { name: 'Actions', href: '/dashboard/actions' },
                    { name: 'Leads', href: '/dashboard/leads' },
                    { name: 'Logs', href: '/dashboard/logs' },
                    { name: 'Messages', href: '/dashboard/messages' },
                    { name: 'Proptree', href: '/dashboard/proptree' }
                ];
                this.setState({
                    user: user,
                    navigation: user.level.id == 1 ? admin : agent
                })
            }
        },
        setVersion: async (version) => {
            if (version.id !== this.state.version.id) {
                versionRegister.set(version);
                await this.promisedSetState({
                    version: version
                });
                window.location.replace(window.location.protocol + "//" + window.location.host + "/dashboard");
            }
        }
    };

    calls = {
        logoutUser: () => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', {});
            let url = apiRegister.url.api + "/user/logout";
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <>
                <div style={{ minHeight: "100vh" }} className="min-h-full bg-gray-50">
                    <Disclosure as="nav" className="bg-gradient-to-r from-gray-900 to-gray-700">
                        {({ open }) => (
                            <>
                                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                                    <div className="flex items-center justify-between h-16">
                                        <div className="flex items-center">
                                            <div className="flex-shrink-0">
                                                <img
                                                    className="h-5"
                                                    src={require("../assets/images/adbooster_white.png")}
                                                    alt="Workflow"
                                                />
                                            </div>
                                            <div className="hidden lg:block">
                                                <div className="ml-10 flex items-baseline space-x-2">
                                                    {this.state.navigation.map((item) => (
                                                        <div
                                                            key={item.name}
                                                            onClick={() => {
                                                                this.props.history.push(item.href);
                                                            }}
                                                            className={classNames(
                                                                item.href == this.props.history.location.pathname
                                                                    ? 'bg-indigo-500 text-white'
                                                                    : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                                                'px-2 py-2 rounded-md text-xs font-medium cursor-pointer'
                                                            )}
                                                            aria-current={item.href == this.props.history.location.pathname ? 'page' : undefined}
                                                        >
                                                            {item.name}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="hidden lg:block">
                                            <div className="ml-4 flex items-center lg:ml-6">

                                                {/* Profile dropdown */}
                                                <Menu as="div" className="mr-4 flex relative">
                                                    {
                                                        false &&
                                                        <div>
                                                            <button
                                                                type="button" className="ml-auto mt-1 mr-3 bg-gray-800 flex-shrink-0 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                                                                <span className="sr-only">View notifications</span>
                                                                <BellIcon className="h-6 w-6" aria-hidden="true" />
                                                            </button>
                                                        </div>
                                                    }
                                                    <div>
                                                        <Menu.Button className="max-w-xs flex items-center focus:outline-none">
                                                            <span className="sr-only">Open user menu</span>
                                                            {
                                                                this.state.user.image &&
                                                                <img className="h-9 min-w-9 rounded-md border" src={this.state.user.image} alt="" />
                                                            }
                                                            {
                                                                !this.state.user.image &&
                                                                <span className="inline-block h-9 w-9 rounded-full overflow-hidden bg-gray-100">
                                                                    <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                                                    </svg>
                                                                </span>
                                                            }
                                                        </Menu.Button>
                                                    </div>
                                                    <Transition
                                                        as={Fragment}
                                                        enter="transition ease-out duration-100"
                                                        enterFrom="transform opacity-0 scale-95"
                                                        enterTo="transform opacity-100 scale-100"
                                                        leave="transition ease-in duration-75"
                                                        leaveFrom="transform opacity-100 scale-100"
                                                        leaveTo="transform opacity-0 scale-95"
                                                    >
                                                        <Menu.Items className="origin-top-right absolute right-0 z-10 mt-12 w-52 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                            <div className="px-4 py-3">
                                                                <p className="text-sm text-gray-600">Signed in as</p>
                                                                <p className="text-sm font-medium text-indigo-500 truncate">{this.state.user.email}</p>
                                                            </div>
                                                            <div className="w-full border-b"></div>
                                                            {this.state.userNavigation.map((item) => (
                                                                <Menu.Item key={item.name}>
                                                                    {({ active }) => (
                                                                        <div onClick={() => {
                                                                            if (item.href === "logout") {
                                                                                this.functions.logout();
                                                                            } else if (item.href === "profile") {
                                                                                this.functions.profile();
                                                                            }
                                                                        }}
                                                                            className={classNames(
                                                                                active ? 'bg-gray-100 ' : '',
                                                                                'block px-4 py-2 text-sm text-gray-600 cursor-pointer focus:outline-none'
                                                                            )}
                                                                        >
                                                                            {item.name}
                                                                        </div>
                                                                    )}
                                                                </Menu.Item>
                                                            ))}
                                                        </Menu.Items>
                                                    </Transition>
                                                </Menu>

                                                <span className="relative inline-flex shadow-sm rounded-md">
                                                    <button
                                                        type="button" className="relative inline-flex h-9 items-center px-4 py-1 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none">
                                                        {this.state.version.name}
                                                    </button>
                                                    <Menu as="span" className="-ml-px relative block">
                                                        <Menu.Button className="relative inline-flex h-9 items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none">
                                                            <span className="sr-only">Open options</span>
                                                            <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                                                        </Menu.Button>
                                                        <Transition
                                                            as={Fragment}
                                                            enter="transition ease-out duration-100"
                                                            enterFrom="transform opacity-0 scale-95"
                                                            enterTo="transform opacity-100 scale-100"
                                                            leave="transition ease-in duration-75"
                                                            leaveFrom="transform opacity-100 scale-100"
                                                            leaveTo="transform opacity-0 scale-95"
                                                        >
                                                            <Menu.Items className="origin-top-right absolute z-10 right-0 mt-3 mr-0 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                <div className="py-1">
                                                                    {[{ name: "Live", value: 'live', id: 1 }, { name: "Dev", value: 'dev', id: 2 }].map((item) => (
                                                                        <Menu.Item key={item.name}>
                                                                            {({ active }) => (
                                                                                <div
                                                                                    onClick={() => {
                                                                                        this.functions.setVersion(item);
                                                                                    }}
                                                                                    className={classNames(
                                                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                        'block px-4 py-2 text-sm cursor-pointer focus:outline-none'
                                                                                    )}
                                                                                >
                                                                                    {item.name}
                                                                                </div>
                                                                            )}
                                                                        </Menu.Item>
                                                                    ))}
                                                                </div>
                                                            </Menu.Items>
                                                        </Transition>
                                                    </Menu>
                                                </span>

                                            </div>
                                        </div>
                                        <div className="-mr-2 flex lg:hidden">
                                            {/* Mobile menu button */}
                                            <span className="relative z-0 mr-2 inline-flex shadow-sm rounded-md">
                                                <button
                                                    type="button" className="relative inline-flex h-10 items-center px-4 py-1 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none">
                                                    {this.state.version.name}
                                                </button>
                                                <Menu as="span" className="-ml-px relative block">
                                                    <Menu.Button className="relative inline-flex h-10 items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none">
                                                        <span className="sr-only">Open options</span>
                                                        <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                                                    </Menu.Button>
                                                    <Transition
                                                        as={Fragment}
                                                        enter="transition ease-out duration-100"
                                                        enterFrom="transform opacity-0 scale-95"
                                                        enterTo="transform opacity-100 scale-100"
                                                        leave="transition ease-in duration-75"
                                                        leaveFrom="transform opacity-100 scale-100"
                                                        leaveTo="transform opacity-0 scale-95"
                                                    >
                                                        <Menu.Items className="origin-top-right absolute right-0 mt-3 mr-0 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                            <div className="py-1">
                                                                {[{ name: "Live", value: 'live', id: 1 }, { name: "Dev", value: 'dev', id: 2 }].map((item) => (
                                                                    <Menu.Item key={item.name}>
                                                                        {({ active }) => (
                                                                            <div
                                                                                onClick={() => {
                                                                                    this.functions.setVersion(item);
                                                                                }}
                                                                                className={classNames(
                                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                    'block px-4 py-2 text-sm cursor-pointer focus:outline-none'
                                                                                )}
                                                                            >
                                                                                {item.name}
                                                                            </div>
                                                                        )}
                                                                    </Menu.Item>
                                                                ))}
                                                            </div>
                                                        </Menu.Items>
                                                    </Transition>
                                                </Menu>
                                            </span>
                                            <Disclosure.Button className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                                                <span className="sr-only">Open main menu</span>
                                                {open ? (
                                                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                                                ) : (
                                                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                                                )}
                                            </Disclosure.Button>
                                        </div>
                                    </div>
                                </div>

                                <Disclosure.Panel className="lg:hidden">
                                    <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                                        {this.state.navigation.map((item) => (
                                            <Disclosure.Button
                                                key={item.name}
                                                as="a"
                                                href={item.href}
                                                className={classNames(
                                                    item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                                    'block px-3 py-2 rounded-md text-base font-medium'
                                                )}
                                                aria-current={item.current ? 'page' : undefined}
                                            >
                                                {item.name}
                                            </Disclosure.Button>
                                        ))}
                                    </div>
                                    <div className="pt-4 pb-3 border-t border-gray-700">
                                        <div className="flex items-center px-5">
                                            <div className="flex-shrink-0">
                                                {
                                                    this.state.user.image &&
                                                    <img className="h-10 w-10 rounded-full" src={this.state.user.image} alt="" />
                                                }
                                                {
                                                    !this.state.user.image &&
                                                    <svg className="h-10 w-10 rounded-full bg-white text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                                    </svg>
                                                }
                                            </div>
                                            <div className="ml-3">
                                                <div className="text-base font-medium leading-none text-white">{this.state.user.name}</div>
                                                <div className="text-sm font-medium leading-none text-gray-400">{this.state.user.email}</div>
                                            </div>
                                            <button
                                                type="button"
                                                className="ml-auto bg-gray-800 flex-shrink-0 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                                            >
                                                <span className="sr-only">View notifications</span>
                                                <BellIcon className="h-6 w-6" aria-hidden="true" />
                                            </button>
                                        </div>
                                        <div className="mt-3 px-2 space-y-1">
                                            {this.state.userNavigation.map((item) => (
                                                <div
                                                    onClick={() => {
                                                        if (item.href === "logout") {
                                                            this.functions.logout();
                                                        } else if (item.href === "profile") {
                                                            this.functions.profile();
                                                        }
                                                    }}
                                                    key={item.name}
                                                    className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
                                                >
                                                    {item.name}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </Disclosure.Panel>
                            </>
                        )}
                    </Disclosure>
                    <main>
                        <Switch>
                            <Route exact path={"/dashboard"}>
                                <Dashboard history={this.props.history} />
                            </Route>
                            <Route exact path={"/dashboard/settings"}>
                                <Settings history={this.props.history} />
                            </Route>
                            <Route exact path={"/dashboard/profile"}>
                                <Profile history={this.props.history} />
                            </Route>
                            <Route exact path={"/dashboard/users"}>
                                <ListUsers history={this.props.history} />
                            </Route>
                            <Route exact path={"/dashboard/clients"}>
                                <ListClients history={this.props.history} />
                            </Route>
                            <Route exact path={"/dashboard/actions"}>
                                <ListActions history={this.props.history} />
                            </Route>
                            <Route exact path={"/dashboard/agents"}>
                                <ListAgents history={this.props.history} />
                            </Route>
                            <Route exact path={"/dashboard/audience"}>
                                <ListAudience history={this.props.history} />
                            </Route>
                            <Route exact path={"/dashboard/leads"}>
                                <ListLeads history={this.props.history} />
                            </Route>
                            <Route exact path={"/dashboard/logs"}>
                                <ListLogs history={this.props.history} />
                            </Route>
                            <Route exact path={"/dashboard/lead-logs"}>
                                <ListLogs history={this.props.history} />
                            </Route>
                            <Route exact path={"/dashboard/listings"}>
                                <ListListings history={this.props.history} />
                            </Route>
                            <Route exact path={"/dashboard/messages"}>
                                <ListWhatsappMessages admin={true} history={this.props.history} />
                            </Route>
                            <Route exact path={"/dashboard/feeds"}>
                                <ListLegcyFeeds history={this.props.history} />
                            </Route>
                            <Route exact path={"/dashboard/light-feeds"}>
                                <ListLightFeeds admin={true} history={this.props.history} />
                            </Route>
                            <Route exact path={"/dashboard/feeds/:id"}>
                                <PreviewFeed history={this.props.history} />
                            </Route>
                            <Route exact path={"/dashboard/enterprise"}>
                                <ListEnterprise history={this.props.history} />
                            </Route>
                            <Route exact path={"/dashboard/enterprise/:id"}>
                                <Enterprise history={this.props.history} />
                            </Route>
                            <Route exact path={"/dashboard/proptree"}>
                                <ListProptrees admin={true} history={this.props.history} />
                            </Route>
                        </Switch>
                    </main>
                </div>
            </>
        )
    }
}

export default LoggedInAdmin;
