import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon, XIcon } from '@heroicons/react/outline'
import Form from '../pages/public/form';

class ListingModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
    };

    componentDidMount() {
        this.setState({
            open: this.props.open
        });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            open: nextProps.open
        })
    }

    render() {
        return (
            <Transition.Root show={this.state.open} as={Fragment}>
                <Dialog as="div" className="fixed z-60 inset-0 overflow-y-auto" onClose={() => {
                    if (this.props.onClose) {
                        //this.props.onClose();
                    }
                }}>
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className="inline-block relative align-top sm:py-10 transform transition-all md:w-140 w-full">
                                {
                                    this.state.showClose &&
                                    <div className="flex items-center justify-center">
                                        <div style={{
                                            zIndex: "999",
                                            top: "13px",
                                            right: "-5px"
                                        }} onClick={() => {
                                            if (this.props.onClose) {
                                                this.setState({
                                                    showClose: false
                                                });
                                                this.props.onClose();
                                            }
                                        }} className="relative cursor-pointer rounded-full h-12 w-12 flex items-center justify-center text-red-500 bg-red-100 hover:text-red-600 focus:outline-none focus:ring-2 focus:ring-red">
                                            <XIcon className="w-6" />
                                        </div>
                                    </div>
                                }
                                <div className="bg-white rounded-lg relative shadow-xl overflow-hidden">
                                    {
                                        this.state.open &&
                                        <Form
                                            proptree={true}
                                            formId={this.props.formId}
                                            feedId={this.props.feedId}
                                            listingId={this.props.listingId}
                                            proptreeId={this.props.proptreeId}
                                            onLoaded={() => {
                                                this.setState({
                                                    showClose: true
                                                })
                                            }}
                                        />
                                    }
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        )
    }
}

export default ListingModal;
