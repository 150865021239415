import React, { Component } from 'react';
import SingleSelection from '../components/singleSelection';
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';
import { userRegister } from '../services/userRegister';

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            phone: "",
            option: 'agent',
            whatsAppCountryCode: { id: 1, name: '+971', value: "971" },
            loading: false
        }
    };

    functions = {
        loginUser: () => {
            this.setState({
                loading: true
            }, async () => {
                try {
                    let response = await this.calls.loginUser({
                        email: this.state.email,
                        password: this.state.password
                    });
                    userRegister.set(response.data);
                    if (response.data && response.data.level && response.data.level.id === 1) {
                        this.props.history.push("/verification");
                    } else {
                        if (response.data.access.id === 2) {
                            this.props.history.push("/agent/listings");
                        } else {
                            if (!(response.data.enterprise && response.data.enterprise.managerUIdashboard && response.data.enterprise.managerUIdashboard.value === "disabled")) {
                                this.props.history.push("/manager");
                            } else {
                                this.props.history.push("/manager/listings");
                            }
                        }
                    }
                } catch (error) {
                    this.setState({
                        message: error.body ? error.body.message : "",
                        error: true,
                        loading: false
                    })
                }
            })
        },
        loginWithWhatsApp: async () => {
            await this.promisedSetState({
                loading: true
            });
            try {
                let response = await this.calls.loginWithWhatsApp({
                    whatsAppCountryCode: this.state.whatsAppCountryCode,
                    phone: this.state.phone
                });
                this.props.history.push("/verify/agent");
            } catch (error) {
                this.promisedSetState({
                    message: error.body ? error.body.message : "",
                    error: true,
                    loading: false
                })
            }
        }
    };

    calls = {
        loginUser: (data) => {
            let options = apiRegister.options(null, 'POST', data);
            let url = apiRegister.url.api + "/user/login";
            return apiRegister.call(options, url);
        },
        loginWithWhatsApp: (data) => {
            let options = apiRegister.options(null, 'POST', data);
            let url = apiRegister.url.api + "/user/loginWithWhatsApp";
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    };

    render() {

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        return (
            <>
                <div style={{ height: "100vh" }} className="min-h-full flex flex-col justify-center py-12 px-6">
                    <div className="sm:mx-auto sm:w-full sm:max-w-md items-center">
                        <div className="w-full flex justify-center items-center">
                            <img
                                className="m-6 h-20 w-px-200"
                                src={require("../assets/images/platonic_diamond_1.png")}
                                alt="Workflow"
                            />
                        </div>
                        <div className="w-full flex justify-center items-center">
                            <img
                                className="h-6 w-px-100"
                                src={require("../assets/images/adbooster_indigo.png")}
                                alt="Workflow"
                            />
                        </div>
                        <h2 className="mt-6 text-center text-2xl font-extrabold text-gray-900">Login</h2>
                    </div>
                    <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-md">
                        <div className="w-full block mb-4 px-2">
                            <div className="border-b border-gray-200">
                                <nav aria-label="Tabs" className="flex">
                                    {[{ name: 'Agent', value: 'agent' }, { name: "Manager", value: 'manager' }].map((tab) => (
                                        <div
                                            key={tab.name}
                                            onClick={() => {
                                                this.setState({
                                                    option: tab.value
                                                })
                                            }}
                                            aria-current={tab.value == this.state.option ? 'page' : undefined}
                                            className={classNames(
                                                tab.value == this.state.option
                                                    ? 'border-indigo-500 text-indigo-600'
                                                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                                'flex-1 flex items-center cursor-pointer justify-center border-b-2 px-1 py-4 text-center text-sm font-medium',
                                            )}
                                        >
                                            {tab.name}
                                        </div>
                                    ))}
                                </nav>
                            </div>
                        </div>
                        {
                            this.state.option == 'agent' &&
                            <div className="bg-white border py-8 px-4 shadow rounded-lg sm:px-10">
                                <div className="grid grid-cols-12 gap-2">
                                    <div className="col-span-4">
                                        <label htmlFor="feed-type" className="block text-sm font-medium text-gray-900">
                                            Country code
                                        </label>
                                        <div className="mt-1">
                                            <SingleSelection
                                                select={async (option) => {
                                                    await this.promisedSetState({
                                                        whatsAppCountryCode: option
                                                    });
                                                }}
                                                selected={this.state.whatsAppCountryCode ? this.state.whatsAppCountryCode : { id: 0, name: 'Click to Select' }}
                                                options={[
                                                    { id: 1, name: '+971', value: "971" },
                                                    { id: 3, name: '+46', value: "46" },
                                                    { id: 2, name: '+44', value: "44" },
                                                ]}
                                                name="feed-type"
                                                id="feed-type"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-span-8">
                                        <label htmlFor="email" className={(this.state.error ? "text-red-700" : "text-gray-700") + " block text-sm font-medium "}>
                                            WhatsApp
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                id="phone"
                                                name="phone"
                                                type="number"
                                                onChange={(event) => {
                                                    this.setState({
                                                        phone: event.target.value
                                                    })
                                                }}
                                                disabled={this.state.loading}
                                                value={this.state.phone}
                                                autoComplete="phone"
                                                required
                                                className={(this.state.error ? "border-red-300 focus:ring-red-500 focus:border-red-500" : "border-gray-300 focus:ring-indigo-500 focus:border-indigo-500") + " appearance-none block w-full px-3 py-2 border  rounded-md shadow-sm placeholder-gray-400 focus:outline-none  sm:text-sm"}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-span-12 mt-4">
                                        <button
                                            onClick={() => {
                                                if (!this.state.loading) {
                                                    this.functions.loginWithWhatsApp();
                                                }
                                            }}
                                            className="w-full flex overflow-hidden relative justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-500 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        >
                                            {
                                                this.state.loading &&
                                                <div className="w-full h-full absolute bg-indigo-600 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                    <div style={{ borderTopColor: "transparent" }}
                                                        class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                </div>
                                            }
                                            Login
                                        </button>
                                    </div>
                                    {
                                        this.state.error &&
                                        <div className="flex col-span-12 items-center justify-center mt-3 font-medium text-sm text-red-600 hover:text-red-500">
                                            {this.state.message}
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                        {
                            this.state.option == 'manager' &&
                            <div className="bg-white border py-8 px-4 shadow rounded-lg sm:px-10">
                                <div className="space-y-6">
                                    <div>
                                        <label htmlFor="email" className={(this.state.error ? "text-red-700" : "text-gray-700") + " block text-sm font-medium "}>
                                            email
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                id="email"
                                                name="email"
                                                type="email"
                                                onChange={(event) => {
                                                    this.setState({
                                                        email: event.target.value
                                                    })
                                                }}
                                                disabled={this.state.loading}
                                                value={this.state.email}
                                                autoComplete="email"
                                                required
                                                className={(this.state.error ? "border-red-300 focus:ring-red-500 focus:border-red-500" : "border-gray-300 focus:ring-indigo-500 focus:border-indigo-500") + " appearance-none block w-full px-3 py-2 border  rounded-md shadow-sm placeholder-gray-400 focus:outline-none  sm:text-sm"}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <label htmlFor="password" className={(this.state.error ? "text-red-700" : "text-gray-700") + " block text-sm font-medium "}>
                                            password
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                id="password"
                                                name="password"
                                                type="password"
                                                onChange={(event) => {
                                                    this.setState({
                                                        password: event.target.value
                                                    })
                                                }}
                                                disabled={this.state.loading}
                                                value={this.state.password}
                                                autoComplete="password"
                                                required
                                                className={(this.state.error ? "border-red-300 focus:ring-red-500 focus:border-red-500" : "border-gray-300 focus:ring-indigo-500 focus:border-indigo-500") + " appearance-none block w-full px-3 py-2 border  rounded-md shadow-sm placeholder-gray-400 focus:outline-none  sm:text-sm"}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between">
                                        <div className="text-sm">
                                            <a href="/forgot-password" className="font-medium text-indigo-600 hover:text-indigo-500">
                                                forgot password?
                                            </a>
                                        </div>
                                    </div>
                                    <div>
                                        <button
                                            onClick={() => {
                                                if (!this.state.loading) {
                                                    this.functions.loginUser();
                                                }
                                            }}
                                            className="w-full flex overflow-hidden relative justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-500 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        >
                                            {
                                                this.state.loading &&
                                                <div className="w-full h-full absolute bg-indigo-600 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                    <div style={{ borderTopColor: "transparent" }}
                                                        class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                </div>
                                            }
                                            Login
                                        </button>
                                    </div>
                                    {
                                        this.state.error &&
                                        <div className="flex items-center justify-center mt-3 font-medium text-sm text-red-600 hover:text-red-500">
                                            {this.state.message}
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                        <div className="w-full flex flex-row font-medium text-sm text-gray-600 justify-center items-center mt-4">
                            <a target="_blank" className="hover:text-gray-800" href="https://adbooster.ai/privacy-policy">Privacy Policy</a>
                            <div className="ml-2 mr-2 text-xxs">|</div>
                            <a target="_blank" className="hover:text-gray-800" href="https://adbooster.ai/terms">Terms</a>
                            <div className="ml-2 mr-2 text-xxs">|</div>
                            <a target="_blank" className="hover:text-gray-800" href="https://adbooster.ai/use-policy">Use Policy</a>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    /*
    render() {
        return (
            <>
                <div style={{ height: "100vh" }} className="flex">
                    <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:w-1/3 lg:flex-none lg:px-20 xl:px-24">
                        <div className="mx-auto w-full max-w-sm lg:w-96">
                            <div>
                                {
                                    <img
                                        className="h-6 w-auto"
                                        src={"https://api.primehit.com/v1/ae/static/logo/kb0os5ytf7hrjcpw.png"}
                                        alt="Workflow"
                                    />
                                }
                                <h2 className="mt-6 text-3xl font-extrabold text-gray-900">Welcome back</h2>
                                <p className="mt-1 text-sm text-gray-600">
                                    Please login to your account.
                                </p>
                            </div>
                            <div className="mt-8">
                                <div className="mt-6">
                                    <div className="space-y-6">
                                        <div>
                                            <label htmlFor="email" className={(this.state.error ? "text-red-500" : "text-gray-700") + " block text-sm font-medium "}>
                                                Email address
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    onChange={(event) => {
                                                        this.setState({
                                                            email: event.target.value
                                                        })
                                                    }}
                                                    value={this.state.email}
                                                    id="email"
                                                    name="email"
                                                    type="email"
                                                    autoComplete="email"
                                                    required
                                                    className={(this.state.error ? "border-red-500" : "") + " appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"}
                                                />
                                            </div>
                                        </div>

                                        <div className="space-y-1">
                                            <label htmlFor="password" className={(this.state.error ? "text-red-500" : "text-gray-700") + " block text-sm font-medium "}>
                                                Password
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    onChange={(event) => {
                                                        this.setState({
                                                            password: event.target.value
                                                        })
                                                    }}
                                                    value={this.state.password}
                                                    id="password"
                                                    name="password"
                                                    type="password"
                                                    autoComplete="current-password"
                                                    required
                                                    className={(this.state.error ? "border-red-500" : "") + " appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"}
                                                />
                                            </div>
                                        </div>

                                        <div className="flex items-center justify-between">
                                            <div className="text-sm">
                                                <a href="/forgot-password" className="font-medium text-indigo-600 hover:text-indigo-500">
                                                    Forgot your password?
                                                </a>
                                            </div>
                                        </div>

                                        <div>
                                            <button
                                                onClick={() => {
                                                    this.functions.loginUser();
                                                }}
                                                className="w-full relative flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-500 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            >
                                                {
                                                    this.state.loading &&
                                                    <div className="w-full h-full absolute bg-indigo-600 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                        <div style={{ borderTopColor: "transparent" }}
                                                            class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                    </div>
                                                }
                                                <div>Sign in</div>
                                            </button>
                                        </div>
                                        {
                                            this.state.error && this.state.message &&
                                            <div style={{marginTop: "10px"}} className="font-medium text-sm text-red-500 text-center">
                                                {this.state.message}
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="hidden lg:block relative w-0 flex-1 bg-center" style={{ backgroundImage: 'linear-gradient(180deg,#d6f1f5,#aee3ec)' }}>

                    </div>
                </div>
            </>
        )
    }
    */
}

export default Login;
